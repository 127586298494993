import React, { useEffect, useState } from "react"
import request from "../utils/requests"
import { Box, FormControl, Select, MenuItem, Button, InputLabel, Typography } from "@mui/material"
import { flattenObject } from "../utils/functions";
import ZeroIops from "../components/ZeroIops";
import IdealLoadBalancers from "../components/IdealLoadBalancers";
import S3 from "../components/S3";
import OldGenInstance from "../components/OldGenInstance";
import SpotAdoption from "../components/SpotAdoption";
import EBSCoverage from "../components/EBSCoverage";
import ComputeRemediation from "../components/ComputeRemediation";
import { showLoading, hideLoading } from '../store/actions/loader';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const Reports = () => {
    const dispatch = useDispatch();
    const [reportType, setReportType] = useState('')
    const [zeroIOPSList, setZeroIOPSList] = useState([])
    const [loadBalancerList, setLoadBalancerList] = useState([])
    const [s3List, setS3List] = useState([])
    const [oldGenInstanceList, setOldGenInstanceList] = useState([])
    const [eBSCoverageList, setEBSCoverageList] = useState([])
    const [spotAdoptionList, setSpotAdoptionList] = useState([])
    const [computeRemediationList, setComputeRemediationList] = useState([])

    const handleChange = (e) => {
        const value = e.target.value;
        setReportType(value)
        getList(value)
    }

    const getList = (name) => {
        dispatch(showLoading())
        switch (name) {
            case 'Zero IOPS Volume Report':
                request.post('/zero_iops/create/').then(response => {
                request.get('/zero_iops/list/').then(res => {
                    const flattenArray = res.data.map(val => { return flattenObject(val) })
                    setZeroIOPSList(flattenArray)
                    dispatch(hideLoading())
                }).catch((error) => {
                    dispatch(hideLoading())
                    toast.error(error.message);
                })
                    }).catch((error) => {
                        dispatch(hideLoading())
                        toast.error(error.message);
                    })
                break;
            case 'Ideal Load Bancers Report':
                // request.post('/load_balancer/create/').then(response => {
                request.get('/load_balancer/list/').then(res => {
                    const flattenArray = res.data.map(val => { return flattenObject(val) })
                    setLoadBalancerList(flattenArray)
                    dispatch(hideLoading())
                }).catch((error) => {
                    dispatch(hideLoading())
                    toast.error(error.message);
                })
                // }).catch((error) => {
                //     dispatch(hideLoading())
                //     toast.error(error.message);
                // })
                break;
            case 'S3 Report':
                // request.post('/s3_report/create/').then(response => {
                request.get('/s3_report/list/').then(res => {
                    const flattenArray = res.data.map(val => { return flattenObject(val) })
                    setS3List(flattenArray)
                    dispatch(hideLoading())
                }).catch((error) => {
                    dispatch(hideLoading())
                    toast.error(error.message);
                })
                // }).catch((error) => {
                //     dispatch(hideLoading())
                //     toast.error(error.message);
                // })
                break;
            case 'Old Gen Instance':
                // request.post('/old_gen_report/create/').then(response => {
                request.get('/old_gen_report/list/').then(res => {
                    const flattenArray = res.data.map(val => { return flattenObject(val) })
                    setOldGenInstanceList(flattenArray)
                    dispatch(hideLoading())
                }).catch((error) => {
                    dispatch(hideLoading())
                    toast.error(error.message);
                })
                // }).catch((error) => {
                //     dispatch(hideLoading())
                //     toast.error(error.message);
                // })
                break;
            case 'EBS (gp3 and io2) Coverage Report':
                // request.post('/ebs_coverage_report/create/').then(response => {
                request.get('/ebs_coverage_report/list/').then(res => {
                    const flattenArray = res.data.map(val => { return flattenObject(val) })
                    setEBSCoverageList(flattenArray)
                    dispatch(hideLoading())
                }).catch((error) => {
                    dispatch(hideLoading())
                    toast.error(error.message);
                })
                // }).catch((error) => {
                //     dispatch(hideLoading())
                //     toast.error(error.message);
                // })
                break;
            case 'Spot Adoption':
                // request.post('/spot_adoption_report/create/').then(response => {
                request.get('/spot_adoption_report/list/').then(res => {
                    const flattenArray = res.data.map(val => { return flattenObject(val) })
                    setSpotAdoptionList(flattenArray)
                    dispatch(hideLoading())
                }).catch((error) => {
                    dispatch(hideLoading())
                    toast.error(error.message);
                })
                // }).catch((error) => {
                //     dispatch(hideLoading())
                //     toast.error(error.message);
                // })
                break;
            case 'Compute Remediation':
                // request.post('/compute_remediation/create/').then(response => {
                request.get('/compute_remediation/list/').then(res => {
                    const flattenArray = res.data.map(val => { return flattenObject(val) })
                    setComputeRemediationList(flattenArray)
                    dispatch(hideLoading())
                }).catch((error) => {
                    dispatch(hideLoading())
                    toast.error(error.message);
                })
                // }).catch((error) => {
                //     dispatch(hideLoading())
                //     toast.error(error.message);
                // })
                break;
            default:
                break;
        }
    }


    return (
        <>
            <Box sx={{ padding: 2, }} >
                <Box sx={{ display: 'flex', fontWeight: 'bold' }}>
                    <Typography variant="h3" >Reports</Typography>
                    <FormControl size='small' sx={{ width: '50%', marginLeft: 2 }}>
                        <InputLabel id="demo-simple-select-label">Please select the report type</InputLabel>
                        <Select
                            value={reportType}
                            name='report_type'
                            onChange={handleChange}
                            sx={{ color: '#1672EC' }}
                            label="Please select the report type"
                        >

                            <MenuItem value={'Zero IOPS Volume Report'}>Zero IOPS Volume Report</MenuItem>
                            <MenuItem value={'Ideal Load Bancers Report'}>Ideal Load Bancers Report</MenuItem>
                            <MenuItem value={'S3 Report'}>S3 Report</MenuItem>
                            <MenuItem value={'Old Gen Instance'}>Old Gen Instance</MenuItem>
                            <MenuItem value={'EBS (gp3 and io2) Coverage Report'}>EBS (gp3 and io2) Coverage Report</MenuItem>
                            <MenuItem value={'Spot Adoption'}>Spot Adoption</MenuItem>
                            <MenuItem value={'Compute Remediation'}>Compute Remediation</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ height: 'calc(100vh - 150px )', overflowY: 'scroll' }}>
                    {reportType === 'Zero IOPS Volume Report' && <ZeroIops list={zeroIOPSList} updateList={getList} />}
                    {reportType === 'Ideal Load Bancers Report' && <IdealLoadBalancers list={loadBalancerList} updateList={getList} />}
                    {reportType === 'S3 Report' && <S3 list={s3List} updateList={getList} />}
                    {reportType === 'Old Gen Instance' && <OldGenInstance list={oldGenInstanceList} updateList={getList} />}
                    {reportType === 'EBS (gp3 and io2) Coverage Report' && <EBSCoverage list={eBSCoverageList} updateList={getList} />}
                    {reportType === 'Spot Adoption' && <SpotAdoption list={spotAdoptionList} updateList={getList} />}
                    {reportType === 'Compute Remediation' && <ComputeRemediation list={computeRemediationList} />}
                </Box>
            </Box>
        </>
    )
}





export default Reports
export const headings = {
            "Low_iops_volume"                           : "Delete low iops volume",
            "Unattach_volume"                           : "Delete unattach volume",
            "Snapshot"                                  : "Old snapshot",
            "Conversion_in_gp3"                         : "Conversion in gp3",
            "Ami"                                       : "Old ami",
            "Instance_type_conversion"                  : "Resize instances",
            "Resize_instance_in_graviton"               : "Resize instance in graviton",
            "Not_in_use_instances"                      : "Delete instances",
            "Schedule_instances"                        : "Schedule instances",
            "Not_in_use_eip"                            : "Delete elastic ip",
            "EC_redis_right_size_conversion"            : "Elastic cache redis right size conversion",
            "EC_redis_type_conversion_in_same_family"   : "Elastic cache redis type conversion in same family",
            "Ec_redis_type_conversion"                  : "Elastic cache redis type conversion",
            "Ec_memcache_type_conversion"               : "Elastic cache memcache type conversion",
            "Resize_EC_redis_in_graviton"               : "Resize elastic cache redis in graviton",
            "EC_memcache_right_size_conversion"         : "Elastic cache memcache right size conversion",
            "EC_memcache_type_conversion_in_same_family": "Elastic cache memcache type conversion in same family",
            "Resize_EC_memcache_in_graviton"            : "Resize elastic cache memcache in graviton",
            "Rds_type_conversion"                       : "Rds type conversion",
            "Rds_right_size_conversion"                 : "Rds right size conversion",
            "Rds_type_conversion_in_same_family"        : "Rds type conversion in same family",
            "Resize_rds_in_graviton"                    : "Resize rds in graviton",
            "Zero_db_connections"                       : "Zero db connections",
            "Not_in_use_loadbalancer"                   : "Delete loadbalancer",
            "Amazon_cloudFront"                         : "Amazon cloudfront",
            "Amazon_simple_storage_service"             : "Amazon S3",
            "Aws_data_transfer"                         : "Aws data transfer",
            "Not_in_use_msk"                            : "Delete msk",
            "Msk_Instance_type_conversion"              : "Msk type conversion",
            "Status_failed"                             : "Failed instances",
            "Resize_auto_scaling"                       : "Resize auto scaling",
            "Resize_autoscaling_in_graviton"            : "Resize autoscaling in graviton"
        }

      

export const tableColumns = {
    "Instance_type_conversion"                          : ['tag_value' ,'instance_creation_date', 'current_instance_type', 'cpu_percentage','current_cost', 'saving_cost','state',  'recommend_cost','region', 'Modify'],
    "Resize_instance_in_graviton"                       : ['tag_value' ,'instance_creation_date', 'current_instance_type', 'cpu_percentage','current_cost', 'saving_cost','state',  'recommend_cost','region', 'Modify'],
    "Not_in_use_instances"                              : ['tag_value', 'instance_creation_date', 'current_instance_type',  'cpu_percentage','saving_cost','state','region',],
    "Schedule_instances"                                : ['tag_value', 'current_instance_type', 'current_cost', 'saving_cost','region' ,],
    "Status_failed"                                     : ['tag_value', 'current_instance_type', 'current_cost', 'saving_cost', 'region',],
    "Snapshot"                                          : ['snapshot_id', 'volume_id', 'saving_cost','region','disk_size' ],
    "Ami"                                               : ['ami_id', 'volume_id', 'saving_cost', 'region','disk_size'],
    "Low_iops_volume"                                   : ['volume_id', 'current_volume_type', 'saving_cost','region', 'disk_size'],
    "Unattach_volume"                                   : ['volume_id', 'current_volume_type', 'saving_cost', 'region', 'disk_size'],
    "Conversion_in_gp3"                                 : ['volume_id', 'current_volume_type', 'recommend_volume_type', 'current_cost','saving_cost','region', 'disk_size'],
    "Not_in_use_eip"                                    : ['public_ip', 'saving_cost', 'region'],
    "Ec_redis_type_conversion"                          : ['cluster_name', 'engine', 'instance_type','current_cost',  'saving_cost','recommend_cost',  'region'],
    "EC_redis_right_size_conversion"                    : ['cluster_name', 'engine', 'instance_type','current_cost',  'saving_cost','recommend_cost',  'region'],
    "EC_redis_type_conversion_in_same_family"           : ['cluster_name', 'engine', 'instance_type','current_cost',  'saving_cost','recommend_cost',  'region'],
    "Resize_EC_redis_in_graviton"                       : ['cluster_name', 'engine', 'instance_type','current_cost',  'saving_cost','recommend_cost',  'region'],
    "Ec_memcache_type_conversion"                       : ['cluster_name', 'engine', 'instance_type','current_cost',  'saving_cost','recommend_cost',  'region'],
    "EC_memcache_right_size_conversion"                 : ['cluster_name', 'engine', 'instance_type','current_cost',  'saving_cost','recommend_cost',  'region'],
    "EC_memcache_type_conversion_in_same_family"        : ['cluster_name', 'engine', 'instance_type','current_cost',  'saving_cost','recommend_cost',  'region'],
    "Resize_EC_memcache_in_graviton"                    : ['cluster_name', 'engine', 'instance_type','current_cost',  'saving_cost','recommend_cost',  'region'],
    "Rds_type_conversion"                               : ['db_instance_identifier', 'instance_type', 'recommend_instance_type', 'saving_cost', 'region'],
    "Rds_type_conversion_in_same_family"                 : ['db_instance_identifier', 'instance_type', 'recommend_instance_type', 'saving_cost', 'region'],
    "Resize_rds_in_graviton"                            : ['db_instance_identifier', 'instance_type', 'recommend_instance_type', 'saving_cost', 'region'],
    "Rds_type_conversion_in_graviton"                   : ['db_instance_identifier', 'instance_type', 'recommend_instance_type', 'saving_cost', 'region'],
    "Rds_right_size_conversion"                         : ['db_instance_identifier', 'instance_type', 'recommend_instance_type', 'saving_cost', 'region'],
    "Not_in_use_loadbalancer"                           : ['loadbalancer_name', 'lb_type', 'saving_cost', 'region'],
    "Amazon_cloudFront"                                 : ['key', 'saving_cost', 'total_cost'],
    "Amazon_simple_storage_service"                     : ['key', 'saving_cost', 'total_cost'],
    "Aws_data_ransfer"                                 : ['key', 'saving_cost', 'total_cost'],
    "Not_in_use_msk"                                    : ['cluster_name','instance_type', 'saving_cost', 'region', 'broker_nodes'],
    "Msk_instance_type_conversion"                      : ['cluster_name','current_instance_type','current_cost', 'recommend_cost',  'saving_cost', 'region', 'broker_nodes'],
    "Zero_db_connections"                               : ['db_instance_identifier', 'instance_type', 'saving_cost', 'region'],
    "Resize_auto_scaling"                               : ['tag_value', 'current_instance_type', 'current_cost', 'saving_cost','region'],
    "Resize_autoscaling_in_graviton"                    : ['tag_value', 'current_instance_type', 'current_cost', 'saving_cost','region'],

}

import React, {useState} from "react";
import styles from "./Pricing.module.scss";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Footer from "../Footer";
import NavigationBar from "../NavigationBar";

const Pricing = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };

    const faqData = [
      {
        question: 'How do I subscribe to a plan?',
        answer: 'To subscribe, simply choose the plan that best fits your needs on our pricing page and click the "Join Now" or "Try Premium" button. Follow the prompts to create your account and complete the subscription process.'
      },
      {
        question: 'What payment methods do you accept?',
        answer: 'We accept a variety of payment methods including credit cards, debit cards, and GooglePay. For enterprise clients, we can also arrange for invoicing and other payment terms.'
      },
      {
        question: 'How quickly can I see cost savings after subscribing?',
        answer: 'Many clients start seeing cost savings within the first month of using our services. Our tools and strategies are designed to provide quick wins while also implementing long-term cost-saving measures.'
      },
      {
        question: 'What kind of support do you offer?',
        answer: 'We offer a range of support options depending on your subscription plan. This includes email support for Starter plans, priority email and chat support for Premium plans, and dedicated account managers with 24/7 support for Enterprise clients.'
      },
      {
        question: 'Can I change my plan later?',
        answer: 'Yes, you can upgrade or downgrade your plan at any time. Simply go to your account settings and choose the new plan that best meets your needs. Our support team is also available to assist you with any changes.'
      }
    ];
    
  return (
    <div className={styles.mainbody}>
        <NavigationBar/>

        <div>
          <p className={styles.heading}>See Our Pricing</p>
          <p className={styles.primaryText}>
            You have Free Unlimited Updates and Premium Support on each package.
          </p>
        </div>

      <div className={styles.thirdSection}>
        <div className={styles.pricingCard}>
          <p className={styles.plans}>FREE PLAN</p>
          <p className={styles.price}>$0</p>
       <div className={styles.plansdata}>
        <CheckCircleIcon className={styles.icons}/>
       <p className={styles.values}>AWS cost optimization tools
</p>
       </div>
       <div className={styles.plansdata} >
       <CheckCircleIcon className={styles.icons}/>
       <p className={styles.values}> Basic cost analysis
</p>
       </div>
       <div className={styles.plansdata} >
       <CheckCircleIcon className={styles.icons}/>
       <p className={styles.values}> Email support
</p>
      </div>
      <div className={styles.plansdata} >
       <CancelIcon className={styles.icon}/>
       <p className={styles.values}> Access to cost-saving resources
</p>
      </div>
      <div className={styles.plansdata} >
       <CancelIcon className={styles.icon}/>
       <p className={styles.values}> Detailed usage reports
</p>
      </div>
      <div className={styles.plansdata} >
       <CancelIcon className={styles.icon}/>
       <p className={styles.values}>Complete documentation</p>    
      </div>
       <button className={styles.priceButton}>Join Now</button>
        </div>
        <div className={styles.pricingCard}>
          <p className={styles.plans}>PREMIUM</p>
          <p className={styles.price}>$89</p>
          <div className={styles.plansdata}>
        <CheckCircleIcon className={styles.icons}/>
       <p className={styles.values}>Priority email and chat support
</p>
       </div>
       <div className={styles.plansdata} >
       <CheckCircleIcon className={styles.icons}/>
       <p className={styles.values}> Access to advanced cost-saving resources
</p>
       </div>
       <div className={styles.plansdata} >
       <CheckCircleIcon className={styles.icons}/>
       <p className={styles.values}> Custom cost analysis
</p>
      </div>
      <div className={styles.plansdata} >
       <CancelIcon className={styles.icon}/>
       <p className={styles.values}> Sketch Files</p>
      </div>
      <div className={styles.plansdata} >
       <CancelIcon className={styles.icon}/>
       <p className={styles.values}> Monthly cost optimization reports
</p>
      </div>
      <div className={styles.plansdata} >
       <CancelIcon className={styles.icon}/>
       <p className={styles.values}>Advanced AWS cost-cutting strategies
</p>    
      </div>
          <button className={styles.priceButton}>Try premium</button>
        </div>
        {/* <div className={styles.pricingCard}>
          <p className={styles.plans}>ENTERPRISE</p>
          <p className={styles.price}>$99</p>
          <div className={styles.plansdata}>
        <CheckCircleIcon className={styles.icons}/>
       <p className={styles.values}> Customized cost optimization solutions
</p>
       </div>
       <div className={styles.plansdata} >
       <CheckCircleIcon className={styles.icons}/>
       <p className={styles.values}> 24/7 support

</p>
       </div>
       <div className={styles.plansdata} >
       <CheckCircleIcon className={styles.icons}/>
       <p className={styles.values}> Customizable documentation and training
</p>
      </div>
      <div className={styles.plansdata} >
       <CancelIcon className={styles.icon}/>
       <p className={styles.values}>Comprehensive AWS cost management
</p>
      </div>
      <div className={styles.plansdata} >
       <CancelIcon className={styles.icon}/>
       <p className={styles.values}>Real-time cost monitoring and alerts
</p>
      </div>
      <div className={styles.plansdata} >
       <CancelIcon className={styles.icon}/>
       <p className={styles.values}>Quarterly strategy sessions with AWS experts
</p>    
      </div>  
              <button className={styles.priceButton}>Join Now</button>
        </div> */}
      </div>

      <div className={styles.collaborationsection}>
        <p className={styles.collabheading}>Our collaboration</p>
             <div className={styles.logosimage}>
             <img src='https://static.wixstatic.com/media/4ecd90_1d4a12ca67ae46e99b25e04104b45bf3~mv2.png/v1/fill/w_155,h_103,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/tira-bw.png'/>
             
             <img src='https://static.wixstatic.com/media/4ecd90_1e3b88858b7945569afbbfe07be54cbf~mv2.png/v1/fill/w_155,h_103,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/clovia-BW_edited.png'/>

             <img src='https://static.wixstatic.com/media/4ecd90_14ce036c295c49f79e4bf8ed94e4379b~mv2.png/v1/fill/w_176,h_110,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/redcliffelabs-BW.png' />

             <img src='https://static.wixstatic.com/media/4ecd90_44fc10d4cba647de9b1b9455ca04703c~mv2.png/v1/fill/w_155,h_103,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/fynd-BW.png' />
             
             <img src='https://static.wixstatic.com/media/4ecd90_172f2735de654512a2d23bdbefefc62b~mv2.png/v1/fill/w_155,h_103,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Aaj_tak-BW.png' />

             <img src='https://static.wixstatic.com/media/4ecd90_2ee8586664fc4e27bd129bc44303fbff~mv2.png/v1/fill/w_166,h_110,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/BeatO-BW.png'/>

             <img src='https://static.wixstatic.com/media/4ecd90_2eba7e05c9c047dbb1fd824f739e6114~mv2.png/v1/fill/w_155,h_110,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ace-cloud-hosting-BW.png'/>

             <img src='https://static.wixstatic.com/media/4ecd90_ae1b66a304f0499ca39977ac8578a059~mv2.png/v1/fill/w_166,h_110,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Shiprocket-BW.png'/>
             </div>
      </div>

      <div className={styles.faq}>
        <p className={styles.faqheading}>Frequently Asked Questions</p>
        <p className={styles.faq2heading}>
        Get answers to common questions about our AWS cost management services and find out how you can optimize your cloud expenses.
        </p>

        <div className={styles.questionsection}>
      
        {faqData.map((item, index) => (
          <div className={styles.question} key={index}>
              <div className={styles.questionIcon} onClick={() => toggleAccordion(index)}>
              <p className={styles.questionText} >
              {item.question}
              </p>
              <span className={styles.dropdownIcon}>
                {activeIndex === index ? '▲' : '▼'}
              </span>
                </div>
          
           
           
            {activeIndex === index && (
              <div className={styles.answer}>
                <p>{item.answer}</p> 
              </div>
            )}
             <hr />
          </div>
        ))}
        </div>
      </div>
      
      <div>
        <Footer/>
      </div>
    </div>
  );
};

export default Pricing;

// import React, { useEffect, useState } from "react";
// import Chart from "react-apexcharts";
// import request from "../../utils/requests";
// import { toast } from "react-toastify";
// import CircularProgress from "@mui/material/CircularProgress";

// const StatusGraph = () => {
//   const [chartData, setChartData] = useState(null);

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         const res = await request.get(`/prowler/findings_by_status/`);
//         const data = res.data;
//         setChartData(data);
//       } catch (error) {
//         toast.error("Error fetching data");
//         console.error("Error fetching data:", error);
//       }
//     }

//     fetchData();
//   }, []);

//   // Render loading spinner if data is still loading
//   if (!chartData) {
//     return <CircularProgress />;
//   }

//   // Extract data for chart
//   const chartOptions = {
//     labels: Object.keys(chartData),
//     series: Object.values(chartData).map(item => item.count)
//   };

//   return (
//     <div className="pie-chart" style={{  width:"50%"}}>
//     <Chart
//         options={{
//           chart: {
//             type: 'pie',
//             stroke: {
//                      show: false, // Hide the stroke around pie sections
//                      },
//             toolbar: {
//               show: true
//             }
//           },
//           labels: chartOptions.labels,
   //legend: {
    //             labels: {
    //                 colors: '#fff', // Set legend label color to white
    //             },
    //         },
    
//         }}
//         series={chartOptions.series}
//         type="pie"
//         // width="100%"
//       />
//     </div>
//   );
// };

// export default StatusGraph;

import React, { useEffect, useState } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import request from "../../utils/requests";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";

const StatusGraph = () => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await request.get(`/prowler/findings_by_status/`);
        const data = res.data;
        setChartData(data);
      } catch (error) {
        toast.error("Error fetching data");
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  // Render loading spinner if data is still loading
  if (!chartData) {
    return <CircularProgress />;
  }

  // Prepare data for chart
  const chartOptions = {
    chart: {
      type: 'pie',
      backgroundColor: {
        linearGradient: [0, 0, 0, 400],
        stops: [
          [0, 'rgba(6, 11, 40, 0.94)'],
          [1, 'rgba(10, 14, 35, 0.49)']
        ]
      },
      style: {
        borderRadius: '17px',
        color: 'rgba(0, 0, 0, 0.87)'
      },
      marginTop: 70, // Adjust the top margin of the chart to create space between chart and title
      options3d: {
        enabled: true,
        alpha: 45
      }
    },
    title: {
      text: 'Pass/Fail Distribution',
      style: {
        color: '#fff',
        fontFamily: "Plus Jakarta Sans",

      
      },
      x: 0, // Adjust the horizontal position
      y: 40,
    },
    plotOptions: {
      pie: {
        innerSize: 100,
        depth: 45
      },
      series: {
        borderWidth: 0, // Hide the border of the series
      },
    },
    series: [{
      name: 'Count',
      data: Object.entries(chartData).map(([status, data]) => ({ name: status, y: data.count }))
    }]
  };

  return (
    // <div style={{  width:"50%" , margin:" 0px 30px"}}>
     <div>  

     <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        height={"500px"}
      />
    </div>
  );
};

export default StatusGraph;

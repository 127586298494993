import React, {useEffect,useState}from 'react';
import styles from "./Accounts.module.scss";
import MenuIcon from '@mui/icons-material/Menu';
// import {Chip } from "@mui/material";
// import { Box, Pagination } from "@mui/material"
// import { showLoading, hideLoading } from '../../store/actions/loader';
// import { useDispatch } from 'react-redux';
// import { toast } from 'react-toastify';
import request from "../../utils/requests"


const Accounts = () => {
  // const dispatch = useDispatch();
  const [details, setDetails] = useState('');
  // const [totalPages, setTotalPages] = useState(0);
  // const [page_size, setPageSize] = useState(10);
  const fetchData = async () => {
    try {
        const response = await request.get(`/onboarding/connection_lists/`);
        // console.log("res",response);
        setDetails(response.data);
  
    } catch (error) {
        console.error('Error fetching accountid:', error);
        // throw error;
    }
  
  };
  
  useEffect(() => {
  
    fetchData();
  }, []);

//   const onPageChange = (page) => {
//     dispatch(showLoading())
//     request.get(`/onboarding/connection_lists/`, { page: page, page_size: page_size }).then(res => {
//         if (res.data.values && res.data.values.length > 0) {
//             let arr = res.data.columns.map(val => {
//                 return {
//                     headerName: val.replace(/_/g, " "),
//                     field: val
//                 }
//             })
//             // setColumns(arr)
//             setDetails(res.data.values)
//             setTotalPages(res.data.total.total_count)
//         }
//         dispatch(hideLoading())
//     }).catch((error) => {
//         dispatch(hideLoading())
//         toast.error(error.message);
//     })
// }


  return (
    <div className={styles.accountsTable}>
      <table cellPadding="10px"  className={styles.table}>
        <thead className={styles.heading}>

            <td>Accounts Id</td>
            <td>Arn Number</td>
            <td>S3 bucket</td>
            <td>Prefix</td>
            <td>Currency_code</td>
            {/* <td>Last sync</td> */}
        </thead>
       
            {/* <td>
            <Chip className={styles.chip} label="Connected" />

            </td> */}
           
             <tbody className={styles.tbody}>
          {details?.length && details?.map((data, index) => (
            <tr
              key={index}>
              <td>{data.account_id}</td>
              <td>{data.arn}</td>
              <td>{data.s3_bucket}</td>
              <td>{data.prefix}</td>
              <td>{data.currency_code}</td>
            {/* <td>
    <div className={`${data.last_run_status === 'Connected' ? styles.connected : data.last_run_status === 'Failed' ? styles.failed : styles.notConnected}`}>
  {data.last_run_status===null ? "Null" : data.last_run_status}
</div>

</td> */}

            </tr>
          ))}
        </tbody>
      </table>
      {/* <Box className="d-flex mt-2">
         <Pagination count={Math.ceil(totalPages / page_size)} variant="outlined" shape="rounded" sx={{ marginLeft: 'auto' }} onChange={(e, page) => onPageChange(page)} />
                        </Box> */}
    </div>
  )
}

export default Accounts;

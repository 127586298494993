import React , {useEffect,useState} from 'react'
import request from "../../utils/requests"
import ResourceTrackerCard from './ResourceTrackerCard'
import ResourceTrackerTable from './ResourceTrackerTable'
import style from "./ResourceTracker.module.scss"

const ResourceTracker = () => {

    const [savingCost, setSavingCost] = useState("");

   
    const formatNumberWithCommas = (number) => {
      if (number === undefined || number === null) {
        return "";
      }
      const fixedNumber = parseFloat(number).toFixed(0);
      const parts = fixedNumber.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    };


    useEffect(() => {
        const resourceTracker = async () => {
          try {
            const response = await request.get(`resource_tracker/cost_details/`);
            const data = response.data;
            setSavingCost(data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        resourceTracker();
      }, []);
    
  return (
    <div>
        <div className={style.resourceMainBody}>
        <ResourceTrackerCard 
        title="Previous cost"
        cost={`$ ${formatNumberWithCommas(savingCost.previous_cost)}`}

        />
        <ResourceTrackerCard
          title="Current cost"
          cost={`$ ${formatNumberWithCommas(savingCost.current_cost)}`}
          />
        <ResourceTrackerCard
          title="Cost Difference"
          cost={`$ ${formatNumberWithCommas(savingCost.state)}`}
          />
        </div>
       

        <ResourceTrackerTable/>
    </div>
  )
}

export default ResourceTracker


import React, { useEffect, useState } from "react";
import style from "./Jira.module.scss";
import { toast } from 'react-toastify';
import request from "../../utils/requests";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({

    jira_domain: yup.string().required("Domain name is required"),
    project_key: yup.string().required("Project key is required"),
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
    frequency: yup.string().required("Frequency is required"),
    auto_follow_up: yup.string().required("Auto follow up is required")


});

const Jira = () => {
    const [formData, setFormData] = useState({
        jira_domain: "",
        project_key: "",
        username: "",
        password: "",
        frequency:"",
        auto_follow_up:""
    });
    const [jiraData, setJiraData] = useState([]);  

    const [isSubmitted, setIsSubmitted] = useState(false);
    const { handleSubmit,setValue, register, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });


    
    const handleFormData = async (formData) => {
        try {
            console.log("Form Data:", formData);

            const postData = {
               
                jira_domain: formData.jira_domain,
                project_key: formData.project_key,
                username: formData.username,
                password: formData.password,
                frequency:formData.frequency,
                auto_follow_up:formData.auto_follow_up
            };


            const postResponse = await request.post('/users/jira_integration/', postData);
            if (postResponse.status === 201 || postResponse.status === 200) {
                const responseData = postResponse.data[0]; // Access the first element of the array
                toast.success(responseData.message); // Access the message property of the object
                setIsSubmitted(true);
                // setSubmitting(false);

            }
        } catch (error) {
            toast.error("An internal server error occurred");
        }
    };

    const handleData = async (data) => {
        try {
            console.log("Form Data:", data);
            await handleFormData(data);
                if (!isSubmitted) {
                    setIsSubmitted(true);
                }
            
        
        } catch (error) {
            console.error('An internal error occurred:', error);
            toast.error("An internal error occurred");
        }
    };



    useEffect(() => {
        async function fetchData() {
            try {
                const res = await request.get(`/users/integration_list/`);
                const responseData = res.data;
                console.log("res", responseData);

                // Filter to only include Jira data
                const filteredJiraData = responseData.filter(entry => entry.type === "Jira");

                setJiraData(filteredJiraData);  // <-- Set jiraData state

                if (filteredJiraData.length > 0) {
                    const jiraEntry = filteredJiraData[0];  // Assuming you want the first Jira entry
                    const { project_key, username, password, jira_domain, frequency ,  auto_follow_up } = jiraEntry;
                    setFormData({
                        project_key,
                        username,
                        password,
                        jira_domain,
                        frequency,
                        auto_follow_up
                    });
                }
            } catch (error) {
                toast.error("An error occurred while fetching data");
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
       
        setValue("jira_domain", formData.jira_domain);
        setValue("project_key", formData.project_key);
        setValue("username", formData.username);
        setValue("password", formData.password);
        setValue("frequency" , formData.frequency);
        setValue("auto_follow_up" , formData.auto_follow_up)
    }, [formData, setValue]);


    return (
        <>
        <div className={style.form}>
            <form onSubmit={handleSubmit(handleData)}>
            <div className={style.firstBox}>
            <div className={style.name} >
              <label htmlFor="jira_domain" className={style.label}>Domain 
              </label>
               <input
                 className={`${style.multipleInput} ${errors.jira_domain && style.errorInput}`}
                            type="text"
                    name="jira_domain"
                    placeholder='Enter domain'
                    {...register("jira_domain", { required: "Domain is required" })}
                />
                {errors.jira_domain && <p className={style.error}>{errors.jira_domain.message}</p>}
               </div>
               <div className={style.name}>
               <label htmlFor="username" className={style.label}> Jira UserName 
               </label>
                <input
                        className={`${style.multipleInput} ${errors.username && style.errorInput}`}
                            type="text"
                    name="username"
                    placeholder='Enter username'
                    {...register("username", { required: "UserName is required" })}
                />
                 {errors.username && <p className={style.error}>{errors.username.message}</p>}

               </div>
             
                  </div>
                <div className={style.firstBox}>
                    <div className={style.name}>
                    <label htmlFor="password" className={style.label}>Jira Password 
                    </label>
                <input
                            className={`${style.multipleInput} ${errors.password && style.errorInput}`}
                            type="text"
                    name="password"
                    placeholder='Enter jira password'
                    {...register("password", { required: "JiraPassword is required" })}
                />
                  {errors.password && <p className={style.error}>{errors.password.message}</p>}

                    </div>
                    <div className={style.name}>

                  <label htmlFor="project_key" className={style.label}>Project </label>
                <input
                            className={`${style.multipleInput} ${errors.project_key && style.errorInput}`}
                            type="text"
                    name="project_key"
                    placeholder='Enter your project'
                    {...register("project_key", { required: "Project is required" })}
                />
              {errors.project_key && <p className={style.error}>{errors.project_key.message}</p>}

                </div>
                </div>

                <div className={style.firstBox}>
                <div className={style.name}>
                <label htmlFor="frequency" className={style.label}>Frequency</label>
                <select
                    name="frequency"
                    {...register("frequency", { required: "Frequency is required" })}
                    className={`${style.multipleInput} ${errors.frequency && style.errorInput}`}
                    >
                    <option className={style.option} value="">Select Frequency</option>
                    <option className={style.option} value="7">Every week</option>
                    <option className={style.option} value="15">Every 15 days </option>
                    <option className={style.option} value="30">Every month</option>
                </select>
                {errors.frequency && <p className={style.error}>{errors.frequency.message}</p>}
                </div>
                <div className={style.name}>
                <label htmlFor="autofollowup" className={style.label}>Auto Follow Up</label>

<select
name="auto_follow_up"
{...register("auto_follow_up", { required: "Auto follow up is required" })}

className={`${style.multipleInput} ${errors.auto_follow_up && style.errorInput}`}
>
<option className={style.option} value="">Select Frequency</option>
<option className={style.option} value="7">Weekely</option>
<option className={style.option} value="15">Every 15 days</option>
<option className={style.option} value="30">Every month</option>
</select>
{errors.auto_follow_up && <p className={style.error}>{errors.auto_follow_up.message}</p>}
                </div>
                </div> 
                {!isSubmitted && (
                    <button type="submit" className={style.button}>
                        Submit
                    </button>
                )}
            </form>
        </div>

       
      </>
    );
}

export default Jira;




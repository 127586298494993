import { Box, TextField, Button, Card, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { useState, useRef, useEffect } from "react"
import request from '../utils/requests';
import moment from 'moment';
import { aws_regions } from "../utils/file";
import CircularProgress from '@mui/material/CircularProgress';

const ErrorLogs = () => {

    const [loader, setLoader] = useState(false)
    const [query, setQuery] = useState('')
    const [list, setList] = useState([])
    const [albLogs, setAlbLogs] = useState([{ name: '4xx', type: '4xx' }, { name: '5xx', type: '5xx' }, { name: 'Response Time', type: 'target_response_time' }])
    const divRef = useRef(null);
    const [divHeight, setDivHeight] = useState(0);
    const [dateFilters, setDateFilters] = useState({
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD')
    })
    const [queryParam, setQueryParam] = useState({ region: '', s3_bucket: '', type: '', fromDate: '', toDate: '' })
    const date_input_style = {
        padding: '15px',
        borderRadius: '3px',
        border: '1px solid'
    }

    const buttonStyle = {
        backgroundColor: "white",
        color: "black",
        marginLeft: '10px',
        marginRight: '10px',
        '&:hover': {
            backgroundColor: "white",
            color: "black",
            textTransform: "none"

        }
    }

    const columns = [
        { field: 'request_url', headerName: 'Request URL', width: 800, sortable: false, },
        { field: 'elb_status_code', headerName: 'Status Code', width: 150, sortable: false, },
        { field: 'request_count', headerName: 'Request Count', width: 150, sortable: false, },
        { field: 'target_processing_time', headerName: 'Time', width: 150, sortable: false, },
    ]

    const runQuery = () => {
        setLoader(true)
        const payload = {
            ...queryParam,
            to_date: dateFilters.toDate,
            from_date: dateFilters.fromDate,
        }
        request.post(`/alb_logs/list/`, payload).then(res => {
            setLoader(false)
            setList(res.data)
        })
    }

    useEffect(() => {
        const updateDimensions = () => {
            if (divRef.current) {
                const height = divRef.current.clientHeight;
                setDivHeight(height);
            }
        };

        window.addEventListener('resize', updateDimensions);
        updateDimensions(); // Call it initially

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, [])

    const handleChange = (events) => {
        setQueryParam({ ...queryParam, [events.target.name]: events.target.value })

    }

    return (
        <>

            {
                
                <Box className="p-3">
                    <Card ref={divRef} className="p-2">
                        {
                            /* <TextField fullWidth placeholder='Enter query here' value={query}
                                onChange={(e) => setQuery(e.target.value)} multiline
                                rows={5} className="mb-3" />
                            <Box className="d-flex justify-content-end">
                                <Button variant="outlined" color="error" onClick={() => setQuery('')}>Clear</Button>
                                <Button variant="contained" style={buttonStyle} onClick={runQuery}>Run</Button>
                            </Box> */
                        }
                        <Box className="d-flex flex-wrap justify-content-between">
                            <TextField label="S3 bucket" variant="outlined" className="w-60" onChange={handleChange} name="s3_bucket" />
                            <FormControl className="w-30">
                                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={queryParam.region}
                                    label="Region"
                                    onChange={handleChange}
                                    name='region'
                                >
                                    {aws_regions.map((region, index) =>
                                        <MenuItem value={region.region} key={index}>{region.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <Box sx={{ marginTop: '10px' }} className="d-flex w-100 justify-content-between" >
                                <input className="w-30" type='date' max={new Date().toISOString().split('T')[0]} style={date_input_style} name='fromDate' value={dateFilters.fromDate} onChange={e => { setDateFilters(values => ({ ...values, fromDate: e.target.value })) }} />
                                <input className="w-30" type='date' max={new Date().toISOString().split('T')[0]} style={date_input_style} name='toDate' value={dateFilters.toDate} onChange={e => setDateFilters(values => ({ ...values, toDate: e.target.value }))} />
                                {
                                    queryParam.region && queryParam.s3_bucket && dateFilters.fromDate && dateFilters.toDate &&

                                    <FormControl className="w-30" >
                                        <InputLabel id="demo-simple-select-label">Alb Log Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={queryParam.type}
                                            label="Alb Log Type"
                                            onChange={handleChange}
                                            name='type'
                                        >
                                            {albLogs.map((types, index) =>
                                                <MenuItem value={types.type} key={index}>{types.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                } {

                                }
                            </Box>
                            <Button variant="contained" color="secondary" fullWidth className="mt-3" onClick={runQuery}

                                disabled={!queryParam.type || !queryParam.region || !queryParam.s3_bucket}
                            >Search</Button>

                        </Box>


                    </Card>
                    {
                        loader &&
                        <div className="fixed-center">
                            <CircularProgress color="inherit" />
                        </div>
                    }

                    {
                        list && list.length > 0 && !loader &&
                        <>
                            <Card sx={{ maxHeight: `calc(100vh - ( 115px + ${divHeight}px ))`, overflow: 'scroll', marginTop: 2 }} >
                                <div className="universal-table">
                                    <div className="universal-table-header">
                                        {columns.map((column, index) =>
                                            <div key={index} className="universal-table-header-items" style={{ width: `${column.width}px` }} title={column.headerName}>
                                                {column.headerName}
                                            </div>

                                        )}
                                    </div>

                                    {list.map((item, index) =>

                                        <div className="universal-table-row" key={index}>
                                            {columns.map((column, ind) =>
                                                <div key={ind} className="universal-table-row-items" style={{ width: `${column.width}px` }} title={item[column['field']]}>
                                                    {
                                                        column['field'] === 'created_at' ||
                                                            column['field'] === 'updated_at' ||
                                                            column['field'].includes('date') ? moment(item[column['field']]).format('DD-MM-YYYY') : item[column['field']] ? item[column['field']] : '-'
                                                    }
                                                </div>

                                            )}
                                        </div>

                                    )}
                                </div>
                            </Card>
                            {/* <Box className="d-flex mt-2">
                        <Pagination count={Math.ceil(totalPages / page_size)} variant="outlined" shape="rounded" sx={{ marginLeft: 'auto' }} onChange={(e, page) => onPageChange(page)} />
                    </Box> */}
                        </>
                    }
                </Box>
            }
        </>


    )
}

export default ErrorLogs
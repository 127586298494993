import React, { useEffect, useState, useRef } from "react";
import request from "../utils/requests";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Card } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import styles from "../pages/login.module.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NavigationBar from "./NavigationBar";



export default function VerifyOtp({setTabs}) {
  const navigate = useNavigate();
  let { token } = useParams();
  // const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otp, setOtp] = useState("");


  useEffect(() => {
    if (token) localStorage.setItem("login_token", token);
    const otp = new URL(window.location.href).searchParams.get("otp");
    if (otp) setOtp(otp.split(""));
  }, []);

  // function handleChange(event, index) {
  //   const newOtp = [...otp];
  //   newOtp[index] = event.target.value.substring(0, 1);
  //   setOtp(newOtp);
  //   if (event.target.value == "") {
  //     if (event.target.previousSibling) {
  //       event.target.previousSibling.focus();
  //     }
  //   } else {
  //     if (event.target.nextSibling) {
  //       event.target.nextSibling.focus();
  //     }
  //   }
  // }
  function handleChange(e) {
    setOtp(e.target.value);
  }
  
  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData('text').slice(0,6).split('');
    if(pastedText.length == 6){
      setOtp(pastedText);
    } 
  };

  function checkOtpInput() {
    for (let i = 0; i < otp.length; i++) {
      if (!otp[i]) {
        return false;
      }
    }
    return true;
  }

  function backToLogin() {
    setOtp(["", "", "", "", "", ""]);
    localStorage.removeItem("login_token");
    navigate("/login");
  }

  function verifyOtp() {
     request
      // .post("/users/verify_otp/", { otp: otp.join("") })
      .post("/users/verify_otp/", { otp: otp })

      .then((res) => {
        localStorage.removeItem("login_token");
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem('current_user', res.data.user_id);
        if(res.data.type==="invitee"){
          navigate("/invites") 
          setTabs(1)
        }else{
          res.data.onboarded ? navigate("/summaryDashboard") : navigate("/onboarding");
        }
      })
      .catch((error) => {
        // toast.error(error.response.data.detail );
        if(error.response.status == 403){
          // setOtp(["", "", "", "", "", ""])
          setOtp("")
          toast.error(error.response.data.detail,{className:"toast-message"})
        }
        else if(error.response.status == 401){
          toast.error(error.response.data.detail,{className:"toast-message"})

        }
      });
  }

  
  function resendOtp() {
    request.post("/users/resend_otp/").then((res) => {
      toast.success(res.data.message,{className:"toast-message"});
    })
      .catch((error) => {
        toast.error(error.response.message,{className:"toast-message"});
      });
  }

  return (
   

    <div className={styles.login}>
   <NavigationBar/>
    <div className={styles.main}>
      <div className={styles.left}>
      <img 
          className={styles.logo_home} 
          src="https://d2qtwwg2436q9r.cloudfront.net/va2pt-logo.png" 
          onClick={() => { navigate("/") }} 
        />
      </div>

      <div className={styles.right}>
        <div className={styles.formOuter}>
        <div className={styles.form}>
         <div className={styles.outerText}>
      <div className={styles.upperText}>
           <h4 className={styles.bptext}>
             <ArrowBackIcon onClick={backToLogin}  />{" "}
            Sign in
               </h4>
           <p className={styles.bptext}>Enter your confirmation code</p>
              <p className={styles.bptext}>
                Enter the 6-digit authentication code generated sended by email:
              </p>
           </div>
      </div>
  
         <div> 
            <input className={styles.inputv}
        type="number"
        placeholder="Enter Otp..."
        name="name"
        maxLength="6"
        value={otp}
        onChange={(event) => handleChange(event)}
        // onFocus={(event) => event.target.select()}
        onPaste={handlePaste}
        />
        </div>
           
            <div className={styles.outerButton}> 
               <button className={styles.button}
             onClick={verifyOtp}
              disabled={!checkOtpInput()}
            >
              Verify Otp
            </button>
            </div>
            <div className={styles.continue}>
            <span className={styles.ctext}> Or </span>

            </div>
            <div className={styles.outerButton}>
            <button className={styles.resendbutton} 
             onClick={resendOtp}
  >
              Resend Otp
            </button>
            </div>
            
        </div>
        </div>
    </div>
    </div>
    </div>

  );
}

import React, { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';
import request from '../utils/requests';
import { makeRationalData, getAthenaQueryResponse } from '../utils/functions'
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import moment from "moment";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { hideLoading, showLoading } from "../store/actions/loader";
import { useDispatch } from 'react-redux';
import { Box, IconButton, Modal, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';



export default function ApexBarChart({ enabled, setEnabled , granularity, ...props  }) {

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true)
  const [showText, setShowText] = useState(true)
  const [text, setText] = useState({})
  const [dates, setDates] = useState([])
  const [series, setSeries] = useState([])
  const [categories, setCategories] = useState([])
  const [error, setError] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  
  const MAX_MESSAGE_LENGTH = 100; 
  const [showFullErrorMessage, setShowFullErrorMessage] = useState(false);

  const toggleFullErrorMessage = () => {
    setShowFullErrorMessage(!showFullErrorMessage);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Escape') {
      setShowErrorModal(false)
    }
};
  const style_modal = {
    position: 'absolute',
    top: '10%',
    left: '70%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column'
};

  useEffect(() => {
    async function fetchData() {
      try {
        dispatch(showLoading())
        let query = '';
        if (typeof props.query === 'number') {
          query = (await request.get(`/athena/cur/query/${props.query}`)).data.sql_template;
        } else {
          query = props.query;
        }

       const res = await getAthenaQueryResponse(query, props.filter, granularity,enabled);
        // if (res) {
        //   dispatch(hideLoading())
        // }

        if (res.status === 201) {
          dispatch(hideLoading())
          setLoader(false);
          setShowText(true);
          setText('Currently unable to show graph data.');
          toast.error('Currently unable to show graph data.');
        } else {
          const data = makeRationalData(res.data.ResultSet.Rows)
           if (data && data.length > 0) {
            // unique dates for setting y-axis
            let uniqDates = [...new Set(data.map(element => element.date))];
            console.log(data,"data")
            console.log(data.map(element=>element.date,"date"))
            let xaxis = uniqDates.map(val => { return new Date(moment(val, 'DD-MM-YYYY').format('YYYY-MM-DD')).getTime() })
          //   let xaxis = uniqDates.map(val => { 
          //     return new Date(moment(val, 'DD-MM-YYYY').format('YYYY-MM-DD HH:00:00')).getTime() 
          // })
            console.log(uniqDates,"uniqdates")
            setCategories(xaxis)
            setDates(uniqDates)
            dispatch(hideLoading())

            let map = {}
            for (let i = 0; i < data.length; i++) {
              if (map[data[i].name] === undefined) {
                map[data[i].name] = data[i].cost
              } else {
                map[data[i].name] = map[data[i].name] + data[i].cost
              }
            }
            let topServices = Object.keys(map).sort((a, b) => map[b] - map[a]).slice(0, 4);
            let processedData = topServices.map(value => { return { name: value, data: [] } })
            processedData.push({ name: 'Others', data: [] })
            let result = uniqDates.map(date => {
              return data.filter(element => element.date === date);
            });
           
            result = result.forEach(arr => {
              let cost = 0;
              arr.forEach((val) => {
                let index = topServices.findIndex(service => service === val.name)
                if (index > -1) {
                  processedData[index].data.push(val.cost)
                } else {
                  cost = cost + val.cost
                }
              })
              processedData[processedData.findIndex(service => service.name === 'Others')].data.push((cost))
            })
            setSeries(processedData)
            setLoader(false)
            setShowText(false)
            dispatch(hideLoading())

          }
          else {
            dispatch(hideLoading())
            setLoader(false);
            setShowText(true);
            setText({
              Message: "No Data Found",
              RequestMethod: "Post",
              StatusCode: "200 OK",
              RequestURL: `/panel/list/dashboard/${props.query}`,
            });
          }
        }
       
      } catch (error) {
        if (error?.response?.status === 500) {
          const errorMessage = error.response.data.detail;
          setErrorMessage(errorMessage);
          setError(true);
          setShowErrorModal(true); // Show modal for error

        }
      }
    }
    fetchData();
  }, [props.query, props.filter, granularity,enabled]);

  let options = {
    chart: {
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1
          }
        }
      }
    },
    dataLabels: {
      enabled: false, // This is the option to disable data labels
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return  `${localStorage.getItem('currencyCode')} ${value}`; // Format y-axis labels as needed
        },
      },
    },
    xaxis: {
      type: 'datetime',
      tickPlacement: 'on',
      categories: categories,
    },
    legend: {
      position: 'bottom',
      offsetY: 0
    },
    
  }
  function resetToDefault() {
    setShowErrorModal(false)
   
}

  return (

    <>
     
    {loader}{showText}
      {
        loader &&
        <div className="fixed-loader">
          <CircularProgress color="inherit" />
        </div>
      }

      {
        !loader && Object.keys(showText).length > 1 &&

        <div className="fixed-message">
          <p><span className="span">Message: </span>{text.Message}</p>
          <p><span className="span">Request Method: </span>{text.RequestMethod}</p>
          <p><span className="span">Status Code: </span>{text.StatusCode}</p>
          <p> <span className="span">Request URL: </span>{text.RequestURL}</p>

        </div>
      }
      < Modal
        open={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style_modal} onKeyDown={handleKeyPress} tabIndex={0} >
          <div>
            <IconButton onClick={resetToDefault} sx={{ position: 'absolute', top: 25, right: 30 }} >
              < CloseIcon />
            </IconButton>
            {showFullErrorMessage ? errorMessage : `${errorMessage.substring(0, MAX_MESSAGE_LENGTH)}...`}
            {errorMessage.length > MAX_MESSAGE_LENGTH && (
              <ArrowDropDownIcon onClick={toggleFullErrorMessage} />
            )}
          </div>
        </Box>
      </Modal>


      {
        !showText && !loader &&
        <ReactApexChart options={options} series={series} type="bar" height={350} />

      }
    </>

  );

};


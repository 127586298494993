import React , {useEffect,useState} from 'react';
import style from "./Jira.module.scss";
import { toast } from 'react-toastify';
import request from "../../utils/requests";



const Jiralogs = () => {

    const[jiraLogs,setjiraLogs] = useState([])
    const[details,setDetails]=useState([])
    const [selectedFrequency, setSelectedFrequency] = useState({});

    const [formData, setFormData] = useState({
        ticket_key: "",
        frequency:"",
    });

    const jiralogsdata = async () => {
        try {
            const response = await request.get(`/users/get_jira_logs/`);
            setjiraLogs(response.data);
      
        } catch (error) {
            // console.error('Error fetching accountid:', error);
        }
      
      };
      const handleFrequencyChange = (ticket_key, frequency) => {
        setSelectedFrequency((prev) => ({
          ...prev,
          [ticket_key]: frequency,
        }));
        autofollow(ticket_key, frequency); 
      };
    

    const autofollow = async (ticket_key, frequency) => {
        try {
    
            const postData = {
                project_key: ticket_key,  
                auto_follow_up_request: frequency 
            };
    
            const postResponse = await request.post('users/update_auto_follow_up/', postData);
            setDetails(postResponse.data);
            toast.success("Auto follow-up updated successfully");
        } catch (error) {
            toast.error("An internal server error occurred");
        }
    };
    

      useEffect(()=>{
        jiralogsdata()
      },[])
  return (
    <div>
         <div className={`${style.accountsTable} ${style.jiralogs} `}>
    <table cellPadding="10px"  className={style.table}>
      <thead className={style.heading}>
        <th>Creation Data</th>
         <th>Description</th>
         <th>Ticket key</th>
          <th>Account Id</th>
          <th>Issue Type</th>
          <th>Summary</th>
          <th>Auto follow up</th>
      </thead>
     
     
         
           <tbody className={style.tbody}>
        {jiraLogs?.length && jiraLogs?.map((data, index) => (
          <tr
            key={index}>
                <td>{data.updated_at}</td>
             <td>{data.description}</td>
            <td>{data.ticket_key}</td>
            <td>{data.account_id}</td>
            <td>{data.issue_type}</td>
            {/* <td>{data.summary}</td> */}
            <td dangerouslySetInnerHTML={{ __html: data.summary }}></td>
            <td>
            <div className={style.freqname}>
               
                         <select
                        name="frequency"
                        className={style.autoInput}
                        value={selectedFrequency[data.ticket_key] || [data.auto_follow_up]} 
                        onChange={(e) => handleFrequencyChange(data.ticket_key, e.target.value)}
                      >
<option className={style.option} value="">Select Frequency</option>
    <option className={style.option} value="3">Every 3 days</option>
    <option className={style.option} value="7">Every week</option>
    <option className={style.option} value="15">Every 15 days</option>
                </select>

                </div>
                
            </td>

        
          </tr>
        ))}
    
      </tbody>
    </table>
    
  </div>    
    </div>
  )
}

export default Jiralogs



import request from "../utils/requests"
import { useEffect } from 'react';


const Alerts = () => {

    const scopes = 'incoming-webhook,chat:write,channels:history,channels:read,channels:write.invites'
    const width = 800
    const height = 800
    const left = (window.innerWidth / 2 - width / 2)
    const top = (window.innerHeight / 2 - width / 2)

    useEffect(() => {
        if (localStorage.getItem('slack_access_token')) {

        } else {
            const code = new URL(window.location.href).searchParams.get('code');
            if (code !== null) handlePostMessage(code);
        }
    }, []);

    const handlePostMessage = (code) => {
        request.get(`/users/slack_login/?code=${code}`).then((res) => {
            localStorage.setItem('slack_access_token', res.data.access_token);
            window.close()
        }).catch((error) => {
        })

    }



    const handleClick = () => {
        const authorizeUrl = `https://slack.com/oauth/v2/authorize?response_type=code&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=${scopes}&redirect_uri=${process.env.REACT_APP_URL_SLACK}`;
        return window.open(authorizeUrl, '_blank')
    }


    return (
        <>
            <a style={{ cursor: 'pointer', position: 'absolute', transform: 'translate(50%, -50%)', left: '50%', top: '50%' }}>
                <img
                    className='react-slack-login-img'
                    onClick={handleClick}
                    alt='Sign in with Slack'
                    height='40'
                    width='172'
                    src='https://platform.slack-edge.com/img/sign_in_with_slack.png'
                    srcSet='https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x'
                />
            </a>
        </>
    )
}

export default Alerts

import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import request from "../utils/requests";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import style from "../pages/Integration/Card.module.scss";

const ApexBarChart = ({ onGraphClick }) => {
  const [graphData, setGraphData] = useState([]);
  const [showGraph, setShowGraph] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await request.get(`aws_recommendation/cost_savings/`);
        const formattedData = res.data.map((item) => ({
          x: item.name,
          y: parseFloat(item.total_cost.toFixed(2)),
          type: item.type, // Assuming item has a 'type' property
        }));
        setGraphData(formattedData);
        setShowGraph(true);
      } catch (error) {
        toast.error("Error fetching data");
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const options = {

    chart: {
      events: {
        click: function (event, chartContext, config) {
          if (config.dataPointIndex > -1) {
            const clickedData = graphData[config.dataPointIndex];
            if (clickedData?.type) { // Ensure 'type' is available
              onGraphClick(clickedData.type);
            }
          }
        },
      },
      type: "bar",
      height: 350,

    },
    grid: {
      borderColor: "#535A6C",
      strokeDashArray: 4,
    
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true

      },

    },
      
    dataLabels: {
      enabled: true,
     formatter: (val) => `$ ${val}`, // Add $ sign to data labels

    },
    xaxis: {
      categories: graphData?.map((item) => item.x), // Guard against graphData being undefined
      title: {
        text: "Services", // Add title to x-axis
        style: {
          color: "#fff",
        },
      },
      labels: {
        show: false, // Hide the legend
        style: {
          colors: "#fff",
        },
        rotate: 0, // Set rotation angle to 0 for horizontal alignment
      },
    },
    yaxis: {
      title: {
        text: "USD", // Add title to y-axis
        style: {
          color: "#fff",
        },
      },
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    legend: {
      labels: {
        colors: '#fff', // Set legend label color to white
      },
    },
  colors: [
    '#9C27B0', '#E91E63', '#F44336', '#2196F3', '#FF9800', 
    '#4CAF50', '#795548', '#607D8B', '#9E9E9E', '#FFC107',
    '#00BCD4', '#8BC34A', '#FFEB3B', '#3F51B5', '#FF5722',
    '#03A9F4', '#CDDC39', '#673AB7', '#FF4081', '#F50057',
    '#76FF03', '#2979FF', '#00E5FF', '#D500F9', '#6200EA',
    '#C51162', '#FF1744', '#FF9100', '#8D6E63', '#455A64'
  ]
  
  };
  
 

  return (
    <>
      {!showGraph && (
        <div className="fixed-center">
          <CircularProgress color="inherit" />
        </div>
      )}
      {showGraph && (
        <div className="p-3 pie-chart" style={{ height: "500px" ,   margin: "0px 30px 50px 60px" }}>
          <h3 className={style.graphHeading}>Recommendations</h3>
          <Chart options={options} series={[{ data: graphData }]} type="bar" height="80%"  />
        </div>
      )}
    </>
  );
};

export default ApexBarChart;
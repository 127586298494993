import React from 'react'
import styles from "./ResourceTracker.module.scss"

const ResourceTrackerCard = (props) => {
  return (
<div className={styles.card}>
       
           <p className={styles.title}>{props.title}</p>
            <p className={styles.cost}>{props.cost}</p>
           </div>  )
}

export default ResourceTrackerCard



import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import request from "../../utils/requests";
import style from "../Security/Security.module.scss";

const Services = () => {

  const [details, setDetails] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState('');

  const fetchData = async () => {
    try {
      const response = await request.get(`/prowler/failed_findings_by_service/`);
      setDetails(response.data);
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching accountid:', error);
      toast.error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Function to handle service selection
  const handleServiceSelect = (event) => {
    setSelectedService(event.target.value);
  };
 

  // Filter data based on selected service
  const filteredDetails = details.filter(data => selectedService === '' || data.service_name === selectedService);

  return (
    <div className={style.SeverityTable}>

     <p className={style.tableHeading}>Finding by services</p>
      <table cellPadding="10px" className={style.table}>
        <thead className={style.heading}>
          <tr>
            <th>
          
              <select
        className={style.Input}
        value={selectedService}
        onChange={handleServiceSelect}
      >
        <option>Select a service</option>
        {services.map((service, index) => (
          <option className={style.option} key={index} value={service?.service_name}>
            {service?.service_name}
          </option>
        ))}
      </select>
         </th>
         {/* <p className={style.headingSeverity}> Severity</p> */}
            <th>Critical</th>
            <th>High</th>
            <th>Medium</th>
            <th>Low</th>
            <th>Fail Count</th>
          </tr>
        </thead>
        <tbody className={style.tbody}>
          {filteredDetails.map((data, index) => (
            <tr
                        key={index}>
                        <td>{data.service_name}</td>
                        <td className={style.critical}>{data.severity.critical}</td>
                          <td className={style.high}>{data.severity.high}</td>
                          <td className={style.mediums}>{data.severity.medium}</td>
                          <td className={style.low}>{data.severity.low}</td>
                        <td>{data.fail_count}</td>
                     </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Services;

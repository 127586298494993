import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useEffect, useState } from 'react';
import request from '../utils/requests';
import { makeRationalData, getAthenaQueryResponse } from '../utils/functions'
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';

const LineChart = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [graphData, setGraphData] = useState([])

  useEffect(() => {
    const processedData = [];
    async function fetchData() {
      let query = ''
      if (typeof (props.query) === 'number') {
        query = (await request.get(`/athena/cur/query/${props.query}`)).data.sql_template
      } else {
        query = props.query
      }
      const res = await getAthenaQueryResponse(query)
      if (res.data.message) {
        toast.error(res.data.message);
      } else {
        const data = makeRationalData(res.data.ResultSet.Rows)
        const sortedAggregatedSum = data.reduce((acc, obj) => {
          const existingObj = acc.find(item => item.name === obj.name);
          if (existingObj) {
            existingObj.cost = parseFloat(existingObj.cost) + parseFloat(obj.cost);
          } else {
            acc.push({ name: obj.name, cost: obj.cost });
          }
          return acc;
        }, []).sort((a, b) => b.cost - a.cost)
        const topServices = sortedAggregatedSum.slice(0, 4).map(item => item.name);

        data.forEach((item) => {
          const index = processedData.findIndex((val) => val.id === item.date);
          if (index === -1) {
            processedData.push({
              id: item.date,
              color: `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)`,
              data: [{
                x: item.name,
                y: item.cost
              }]
            });
          } else {
            processedData[index].data.push({
              x: item.name,
              y: item.cost
            });
          }
        });
        const result = makeCollectiveData(processedData, topServices)
        setGraphData(result);
      }
    }
    fetchData()
  }, [props.query])

  const makeCollectiveData = (arr, service) => {
    let result = []
    arr.forEach((item) => {
      let cost = 0
      let obj = { id: item.id, color: item.color, data: [] }
      item.data.forEach(value => {
        if (service.indexOf(value.x) > -1) {
          obj.data.push(value)
        } else {
          cost = parseFloat(cost) + parseFloat(value.y)
        }
      })
      obj.data.push({ x: 'Others', y: cost })
      obj.data.sort((a, b) => a.x.localeCompare(b.x));
      result.push(obj)
    })
    return result
  }

  return (
    <>
      {
        graphData.length < 1 &&
        <div className="fixed-loader">
          <CircularProgress color="inherit" />
        </div>}
      {
        graphData.length > 0 &&
        <ResponsiveLine
          data={graphData}
          theme={{
            textColor: "#ffffff",
            axis: {
              domain: {
                line: {
                  stroke: colors.grey[100],
                },
              },
              legend: {
                text: {
                  fill: colors.grey[100],
                },
              },
              ticks: {
                line: {
                  stroke: colors.grey[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.grey[100],
                },
              },
            },

            legends: {
              text: {
                fill: colors.grey[100],
              },
            },
            tooltip: {
              container: {
                color: colors.primary[500],
              },
            },
          }}
          colors={{ scheme: "nivo" }} // added
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          curve="catmullRom"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Services", // added
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickValues: 5, // added
            tickSize: 3,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Cost", // added
            legendOffset: -50,
            legendPosition: "middle",
          }}
          enableGridX={false}
          enableGridY={false}
          pointSize={8}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      }

    </>
  );
};
export default LineChart;

import React ,{useState,useEffect} from "react";
import style from "./Contact.module.scss"
import Footer from "../Footer";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NavigationBar from "../NavigationBar";

const Contact = () => {


const [activeIndex, setActiveIndex] = useState(null);

const toggleAccordion = (index) => {
  setActiveIndex(activeIndex === index ? null : index);
};

useEffect(() => {
  const script = document.createElement("script");
  script.src = "https://assets.calendly.com/assets/external/widget.js";
  script.async = true;
  document.body.appendChild(script);
}, []);



const faqData = [
  {
    question: 'What services do you provide?',
    answer: 'We specialize in AWS cost optimization, helping businesses reduce their cloud expenses through advanced cost management strategies, real-time monitoring, and detailed usage analysis.'
  },
  {
    question: 'How much do you charge?',
    answer: 'Our pricing is based on the specific needs and size of your project. We offer competitive rates and guarantee a 15% cost saving compared to other vendors, ensuring you get the best value for your investment.'
  },
  {
    question: 'How quickly can I see cost savings?',
    answer: 'Many clients start seeing significant cost savings within the first month of using our AWS cost optimization services. Our team works efficiently to implement quick wins and long-term strategies.'
  },
  {
    question: 'What tools do you use for cost optimization?',
    answer: 'We utilize a variety of industry-leading tools for AWS cost optimization, including AWS Cost Explorer, AWS Budgets, and third-party solutions like CloudHealth and CloudCheckr to provide comprehensive cost management.'
  },
  {
    question: 'How fast can you start working on my project?',
    answer: 'Our team of AWS experts is ready to begin working on your project within 24 hours. We ensure rapid deployment and immediate focus on optimizing your AWS costs without any delay.'
  }
];


    return (

        <div className={style.navbody}>
                      <div className={style.upperpart}>
          <div className={style.mainBody}> 
            <NavigationBar/>
        <div className={style.upper_part}>
        <div className={style.headingWrapper}>

        <h1 className={style.heading}>Assisting Your Organization Identify and Close Gaps</h1>
        <p className={style.primary}>
                    We're excited to chat and share more about what we're building.
                     Reach out via the form, and we'll get back to you within one business day!

                    </p>
                    </div>
                    </div>
                    </div>
                    
</div>
 <div className={style.body}>
  <div className={`calendly-inline-widget ${style.calendar}`} data-url="https://calendly.com/va2pt/clarity"></div>
  <div className={style.right}>
    <img className={style.abhishekimage} src={"https://d2qtwwg2436q9r.cloudfront.net/Abhishekimage.png" }/>
    <div className={style.content}>
    <h2 className={style.headingright}>Contact information</h2>
    <hr/>
    <p>
<LocationOnIcon/> 2nd Floor, Plot No 13, Sector 14, Dwarka, <br/> New Delhi, 110078
    </p>
<hr/>
<p style={{display:"flex" , gap:"10px"}}>
  <p> <AccessTimeIcon/></p>
  Monday : 10:00 AM to 07:00 PM <br/>
  Tuesday : 10:00 AM to 07:00 PM <br/>
  Wednesday : 10:00 AM to 07:00 PM <br/>
  Thursday : 10:00 AM to 07:00 PM <br/>
  Friday : 10:00 AM to 07:00 PM<br/>
  Saturday : 10:00 AM to 07:00 PM <br/>
</p>
<hr/>

    </div>
    </div>
 </div>

   

      <div className={style.faq}>
        <p className={style.faqheading}>Frequently Asked Questions</p>
      
        <div className={style.questionsection}>
      
        {faqData.map((item, index) => (
          <div className={style.question} key={index}>
            <div className={style.questionIcon} onClick={() => toggleAccordion(index)}> 
            <p className={style.questionText} >
              {item.question}
              </p>
              <span className={style.dropdownIcon}>
                {activeIndex === index ? '▲' : '▼'}
              </span>
           
            </div>
           
            {activeIndex === index && (
              <div className={style.answer}>
                <p>{item.answer}</p> 
              </div>
            )}
             <hr />
          </div>
        ))}
        </div>
      </div>
            <div>
              <Footer/>
            </div>
        </div>
    )
}
export default Contact;
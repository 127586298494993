import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "../pages/login.module.scss";
import { toast } from "react-toastify";



const Onboardingstep1 = ({
  onboardingDetails,
  setOnboardingDetails,
  setStep,
  handleNavClick,
}) => {
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleChangelinked = (e) => {
    const value = e.target.value;
    console.log("value",value)
    const linkedinregex =
      /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile|company)\/([-a-zA-Z0-9]+)\/?$/;

    setOnboardingDetails((prevValues) => {
      const updatedValues = { ...prevValues, company: value };

      const companyName = updatedValues.company;
      if (linkedinregex.test(companyName)) {
        setError(false);
      } else {
        setError(true);
      }

      return updatedValues;
    });
  };

//   const showErrorToast = () => {
//     toast.error("Please enter a valid LinkedIn URL", {
//         className :"error-toast"
//     });
// };
 
  return (
    <div>
      {/* step1 */}
    
          {/* <div className="welcome-field-content">
                  <div className="sec-heading-title">
                    <h2 className="sec-title mx-3">Welcome to VA2PT</h2>
                    <span className="mb-4 meta-field-text mx-3">
                      Thank you for taking the time today.
                    </span>
                  </div>
                  <p className="sec-content mx-3">
                    Please enter your company's linkedin url to begin the AWS cloud onboarding process.
                  </p>
                  <div className="form-sec-wrap mx-3">
                    {<Form className="form-sec-content">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control type="text" placeholder="LinkedIn url " name="company"
                          value={onboardingDetails.company || ''}
                          onChange={handleChangelinked} required
                          maxLength={100}
                          minLength={10}
                        />
                      </Form.Group>
                      {error && <p style={{ color: 'red' }}>Please enter valid linkedin url</p>}
                      <Button variant="primary" onClick={() => {navigate('/onboarding/step2');setStep(2)}}
                        disabled={error || onboardingDetails?.company?.length < 10} >
                        Continue 
                      </Button>
                    </Form>}

                  </div>
                </div> */}
        <div className="row">
        <div className="col-lg-12">
          <div className="main-step1">
              <h2 className="welcome">Welcome to va2pt</h2>
              <p className="ptext">Thankyou for taking the time today</p>
              <p className="ptext">
                Please enter your company's linkedin url to begin the AWS cloud
                onboarding process.
              </p>
            
            {/* <div className={styles.inputOuter}>
              <input
                className={styles.input}
                name="company"
                type="text"
                placeholder="LinkedIn url...."
                value={onboardingDetails.company || ""}
                onChange={handleChangelinked} required
                maxLength={100}
                minLength={10}
              />

            </div> */}
             <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control type="text" placeholder="LinkedIn url " name="company"
                          value={onboardingDetails.company || ''}
                          onChange={handleChangelinked} required
                          maxLength={100}
                          minLength={10}
                        />
                      </Form.Group>
                     
                     
                {error && <button
                     className="errorbutton"
                    > Please enter valid linkedin url</button> }
                     {error}

                  
                      {/* <Button variant="primary"
                      onClick={() => {navigate('/onboarding/step2');setStep(2)}}
                        disabled={error || onboardingDetails?.company?.length < 10} >
                        Continue 
                      </Button> */}
            <div className={styles.outerButton}>
              <button
                className="nextbutton"
                onClick={() => {
                  navigate("/onboarding/step2");
                  setStep(2);
                }}
                disabled={error || onboardingDetails?.company?.length < 10}
                style={{ backgroundColor: error || onboardingDetails.company.length < 10 ? "rgb(135, 138, 157)" : "#1672EC" }}
              >
                Continue
              </button>
            </div>
          </div>
          <div>
            <video width="580" height="420" controls style={{borderRadius:"10px"}}>
              <source
                src="/home/thinkpad/Videos/video (2160p).mp4"
                type="video/mp4"
              />
              Hello Onboarding Video
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboardingstep1;

import { Box, Button, Card, IconButton, Pagination } from "@mui/material"
import moment from "moment/moment"
import React, { useRef, useEffect, useState } from "react"
import { showLoading, hideLoading } from '../store/actions/loader';
import { useDispatch } from 'react-redux';
import request from "../utils/requests";
import { toast } from 'react-toastify';
import { color } from "highcharts";

const UniversalTable = () => {


    const buttonStyle = {
        backgroundColor: "white",
        color: "black",
        // background:"transparent",
        // color:"#fff",
        textTransform: "capitalize",
        marginRight: '10px',
        marginBottom: '10px',
        '&:hover': {
            backgroundColor: "white",
            color: "black",
            textTransform: "none"

        }
    }
    const selectedButtonStyle = {
        backgroundColor: "grey",
        color: "white",
        // background:"transparent",
        // color:"#fff",
        textTransform: "capitalize",
        marginRight: '10px',
        marginBottom: '10px',
        '&:hover': {
            backgroundColor: "grey",
            color: "white",
            textTransform: "none"

        }
    }
    const [selectedButton, setSelectedButton] = useState('')

    const dispatch = useDispatch();
    const [list, setList] = useState([])
    const [columns, setColumns] = useState([])
    
    const [buttons, setButtons] = useState(['aws_cloudfront_list', 'aws_cloudfront_metrics','aws_cloudtrail_list','aws_ebs_list', 'aws_ebs_metrics' , 'aws_ebs_snapshot_list','aws_ebs_recommendation', 'aws_rds_list','aws_rds_metrics','aws_rds_recommendation', 'aws_ec2_list','aws_ec2_metrics','aws_ec2_recommendation','aws_ec2_ami_list','aws_ec_memcache_list','aws_ec_memcache_metrics','aws_ec_recommendation','aws_ec_redis_list','aws_ec_redis_metrics','aws_ecs_list','aws_ecs_metrics',,'aws_eks_list','aws_eks_metrics','aws_elastic_ip_list','aws_elastic_ip_recommendation','aws_lambda_list','aws_lambda_metrics','aws_loadbalancer_list','aws_loadbalancer_metrics','aws_loadbalancer_recommendation','aws_logs_group_list','aws_logs_recommendation','aws_s3_list','aws_s3_metrics','aws_s3_recommendation','aws_s3_lifecycle_list','aws_saving_plans','aws_msk_list','aws_msk_metrics','aws_msk_recommendation'
])
    const divRef = useRef(null);
    const [divHeight, setDivHeight] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [page_size, setPageSize] = useState(10);

    const [empRes, setEmpRes] = useState(null)

    const getList = (table_name) => {
        setSelectedButton(table_name)
        setList([])
        if (table_name) {
            dispatch(showLoading())
            request.get(`/table/list/${table_name}`, { page: 1, page_size: page_size }).then(res => {
                if (res.data.values && res.data.values.length > 0) {
                    let arr = res.data.columns.map(val => {
                        return {
                            headerName: val.replace(/_/g, " "),
                            field: val
                        }
                    })
                    setColumns(arr)
                    setList(res.data.values)
                    setTotalPages(res.data.total.total_count)
                }
                if (res?.data?.message) {
                    setEmpRes(res?.data?.message)

                }
                dispatch(hideLoading())
            })

                .catch((error) => {
                    dispatch(hideLoading())
                    toast.error(error.message);

                })
        }
    }


    const onPageChange = (page) => {
        dispatch(showLoading())
        request.get(`/table/list/${selectedButton}`, { page: page, page_size: page_size }).then(res => {
            if (res.data.values && res.data.values.length > 0) {
                let arr = res.data.columns.map(val => {
                    return {
                        headerName: val.replace(/_/g, " "),
                        field: val
                    }
                })
                setColumns(arr)
                setList(res.data.values)
                setTotalPages(res.data.total.total_count)
            }
            dispatch(hideLoading())
        }).catch((error) => {
            dispatch(hideLoading())
            toast.error(error.message);
        })
    }

    useEffect(() => {
        const updateDimensions = () => {
            if (divRef.current) {
                // Use the ref to access the DOM node and get the dimensions
                const height = divRef.current.clientHeight;

                // Update state variables with the new dimensions
                setDivHeight(height);
            }
        };

        // Call the updateDimensions function initially and whenever the window is resized
        window.addEventListener('resize', updateDimensions);
        updateDimensions(); // Call it initially

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, [])

const handleDownload = (resource_type) => {
    request.get(`/aws_recommendation/download/${resource_type}/`).then(res => {
        const blob = new Blob([res.data], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${resource_type}`); // Update the download filename dynamically
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
};


    return (
        <>
            <Box sx={{ padding: '16px' }}>

                <Box ref={divRef}>
                    {
                        buttons.map((button, index) =>
                            <Button variant="contained" size="small" key={index} style={selectedButton == button ? selectedButtonStyle : buttonStyle} onClick={() => getList(button)}>
                                {button.replace(/_/g, ' ')}
                            </Button>
                        )
                    }
                </Box>
                
             
                 {list && list.length === 0 && empRes &&  (
                        <Box style={{fontSize: " 35px", display: "flex", flexDirection:"column" ,alignItems: "center", justifyContent: "center", height:"500", marginTop:"15px"}}>
                            <img src="https://d2qtwwg2436q9r.cloudfront.net/empty-folder.png" alt="empty"/>
                            {empRes && <p style={{marginBottom:"0"}}>{empRes}</p>}
                            {<p style={{ color: "#00000096", fontSize: " 25px", display: "flex", alignItems: "center", justifyContent: "center"}}> If you have just registered please give us 24 hour to sync the data</p>}

                        </Box>
                    )}
            <div className="button-table">
              {selectedButton === 'aws_ec2_list' && (
                <Button variant="contained" color="primary" style={{marginLeft:"auto",right:"45px"}} 
                onClick={() => handleDownload("aws_ec2_list")}>
                
                    Download Data
                </Button>
            )}
             {selectedButton === 'aws_ebs_list' && (
                <Button variant="contained" color="primary" style={{marginLeft:"auto",right:"45px"}} onClick={()=> handleDownload("aws_ebs_list")}>
                    Download Data
                </Button>
            )}
             {selectedButton === 'aws_ebs_recommendation' && (
                <Button variant="contained" color="primary" style={{marginLeft:"auto",right:"45px"}} onClick={()=> handleDownload("aws_ebs_recommendation")}>
                    Download Data
                </Button>
            )}
               {selectedButton === 'aws_ec2_recommendation' && (
                <Button variant="contained" color="primary" style={{marginLeft:"auto",right:"45px"}} onClick={()=> handleDownload("aws_ec2_recommendation")}>
                    Download Data
                </Button>
            )}
           
                {list && list.length > 0 &&
                    <>
                        <div sx={{ maxHeight: `calc(100vh - ( 115px + ${divHeight}px ))`, overflow: 'scroll' }} >
                            <div className="universal-table tablelist">
                                <div className="universal-table-header">
                                    {columns.map((column, index) =>
                                        <div key={index} className="universal-table-header-items" style={{ width: `200px` }} title={column.headerName}>
                                            {column.headerName}
                                        </div>

                                    )}
                                </div>
                                  {list.map((item, index) =>

                                    <div className="universal-table-row" key={index}>
                                        {columns.map((column, ind) =>
                                            <div key={ind} className="universal-table-row-items" style={{ width: `200px` }} title={item[column['field']]}>
                                                {
                                                    column['field'] === 'created_at' ||
                                                        column['field'] === 'updated_at' ||
                                                        column['field'].includes('date') ? moment(item[column['field']]).format('DD-MM-YYYY') : item[column['field']] ? item[column['field']] : '-'
                                                }
                                            </div>

                                        )}
                                    </div>

                                )}
                                            
                            </div>
                                            
                        </div>
                      
                        <Box className="d-flex mt-2">
                            <Pagination count={Math.ceil(totalPages / page_size)} variant="outlined" shape="rounded" sx={{ marginLeft: 'auto' }} onChange={(e, page) => onPageChange(page)} />
                        </Box>


                

                    </>
}
</div>
                   
                 </Box>

                
        </>
                
    )

}

export default UniversalTable;
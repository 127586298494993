// import PersonIcon from '@mui/icons-material/Person';
// import AutoGraphIcon from '@mui/icons-material/AutoGraph';
// import HandshakeIcon from '@mui/icons-material/Handshake';
// import NewspaperIcon from '@mui/icons-material/Newspaper';




const aboutcard2Data = [
    {
        image: "https://d2qtwwg2436q9r.cloudfront.net/icons+(1)/leadership.png",
        heading: "Leadership",
        description: "Our leadership team at Va2pt is dedicated to guiding both our company and clients to success, driving excellence in DevOps transformation."
    },
    {
        image: "https://d2qtwwg2436q9r.cloudfront.net/icons+(1)/careers.png",
        heading: "Careers",
        description: "Join Va2pt for growth and development opportunities. Whether experienced or just starting, find a dynamic and supportive environment with us."
    },
    {
        image: "https://d2qtwwg2436q9r.cloudfront.net/icons+(1)/partnership.png",
        heading: "Partnerships",
        description: "Our partnerships are based on trust and collaboration. By working with industry leaders, we deliver the best solutions and technologies to our clients."
    },
    {
        image: "https://d2qtwwg2436q9r.cloudfront.net/icons+(1)/press.png",
        heading: "Press",
        description: "Stay updated with the latest news from Va2pt. Our press section covers our projects, achievements, and industry contributions, showcasing our impact."
    }
]

export default aboutcard2Data;

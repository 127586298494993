import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Menu, MenuItem, Avatar, ListItemIcon, Divider, Tooltip, useTheme } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import request from '../utils/requests'
import EmailIcon from '@mui/icons-material/Email';
import { toast } from 'react-toastify';


import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import { useDispatch } from 'react-redux';
import { selectedAccountId } from '../store/actions/selectedAccountId';

import { useLocation } from 'react-router-dom';

const Topbar = ({ setIsAdmin}) => {

 const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(null);
  const open = Boolean(menu);
  const navigate = useNavigate();
  const [selectedAccount, setSelectedAccount] = useState('A')

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [list, setList] = useState([]);
  const [profileData, setProfileData] = useState(null);

  const [currenturl, setCurrentUrl] = useState(location.pathname);



  useEffect(() => {
      request.get(`/onboarding/lists/`).then(res => {
          setList(res.data);
          localStorage.setItem('currencyCode', 'USD');
      });
  }, []);
  
  const handleProfile = async () => {
    try {
      const profileResponse = await request.get(`users/profile_list/`);
      if (profileResponse.data.length > 0) {
        setProfileData(profileResponse.data[0]);
         setIsAdmin(profileResponse.data[0].is_admin); 
      }
    } catch (error) {
      toast.error("Error fetching profile data");
    }
  };
  useEffect(()=>{
    handleProfile()
  },[])


  // const handleProfile = async () => {
  //   let res;
  //   try{
  //     res = await request.get(`users/profile_list/`);
  //     if (res.data.length > 0 && res.data[0].name) {
       
  //       setProfile(res.data[0].name);
  //       setEmail(res.data[0].email);
  //       // setGlobalEmail(res.data[0].email)
  //       setIsAdmin(res.data[0].is_admin); // Assuming userData is an array and the first item contains user data


  //     }
  //   } catch (error) {
  //     // console.error('Error fetching profile data:', error);
  //   }
  // };

 
  
  // function extractedEmail(identifier) {
  //   if (typeof identifier === "string" && identifier.startsWith("https")) {
  //     const parsedUrl = new URL(identifier);
  //     const fullDomain = parsedUrl.protocol + "//" + parsedUrl.hostname;
  //     return fullDomain;
  //     // User logged in with LinkedIn
  //   } else {
  //     // User logged in with email
  //     return identifier;
  //   }
  // }
  
  

  useEffect(() => {
      setCurrentUrl(location.pathname);
  }, [location]);

  //     const storedUrl = localStorage.getItem('selectedAccountUrl');
  //     const storedAccountId = localStorage.getItem('selectedAccountId');
  //     const storedIndex = list.findIndex(item => item.account_id === storedAccountId);

  //     if (storedUrl && storedAccountId && storedIndex > -1 && storedUrl === currenturl) {
  //         setSelectedIndex(storedIndex);
  //     }
  // }, [currenturl, list]);

  const handleClick = (event) => {
      setMenu(event.currentTarget);
  };

  const handleClose = () => {
      setMenu(null);
  };

  
  const handleMenuItemClick = async (event, index) => {
    const selectedAccount = list[index];
  
    // Create the payload to include onboarding_id
    const payload = {
      onboarding_id: selectedAccount.onboarding_id.toString()  // Ensure it's a string, or keep as a number if required
    };
  
    // Send the onboarding_id in the request payload
    try {
      const res = await request.post('/users/profile_list/', payload); // Make sure this matches the API URL
      window.location.reload();

    } catch (error) {
      toast.error(`Failed to send onboarding_id: ${payload.onboarding_id}`);
    }
  
//   // // Handle navigation and UI state updates
  //   // if (currenturl.startsWith('/dashboard')) {
  //   //   navigate(`/dashboard/${selectedAccount.account_id}`);
  //   // } else if (currenturl.startsWith('/recommendations')) {
  //   //   navigate(`/recommendations/${selectedAccount.account_id}`);
  //   // }    setSelectedIndex(index);

  // if (currenturl.startsWith('/dashboard')) {
    //         navigate(`/dashboard/${list[index].account_id}`);
    //     } else if (currenturl.startsWith('/recommendations')) {
    //         navigate(`/recommendations/${list[index].account_id}`);
    //     }
    localStorage.setItem('currencyCode', selectedAccount.currency_code);
    localStorage.setItem('current_user', selectedAccount.user_id);
    localStorage.setItem('selectedAccountId', selectedAccount.account_id);
    localStorage.setItem('selectedAccountUrl', currenturl);
    setMenu(null);
    setSelectedAccount((selectedAccount.company).charAt(0).toUpperCase());
    dispatch(selectedAccountId(selectedAccount.account_id));
  };
  
  
  const selectAllAccounts = () => {
      if (currenturl.startsWith('/dashboard')) {
          navigate(`/dashboard`);
      } else if (currenturl.startsWith('/recommendations')) {
          navigate(`/recommendations`);
      }

      setSelectedIndex(null);
      setMenu(null);
      setSelectedAccount('A');
      localStorage.setItem('selectedAccountUrl', currenturl); // add
  };
 

  const onLogout = () => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('current_user');
      localStorage.removeItem('currencyCode');
      localStorage.removeItem('filters');
      navigate('/');
  };

  const addAnotherAccount = () => {
      navigate('/onboarding');
      setMenu(null);
  };

  const routeToInvite = () => {
      navigate('/invites');
      setMenu(null);
  };


  const getFirstLetter = () => {
    return profileData?.name ? profileData.name.charAt(0).toUpperCase() : '';
  };

  
  return (
    <Box display="flex" justifyContent="space-between" m={2} sx={{ borderRadius: '4px' }}>
       {/* <Box>  */}
      <Box flex={1} />
      <Box >
        {/* <IconButton onClick={colorMode.toggleColorMode} sx={{ color: `${colors.blueAccent[500]}`, }}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton sx={{ color: `${colors.blueAccent[500]}`, }}>
          <NotificationsOutlinedIcon />
        </IconButton> */}
        <Tooltip title="Account settings">
          <IconButton sx={{ color: `${colors.blueAccent[500]}`, }}
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <p style={{width:"auto", borderRadius:"20px" , padding:"10px" , backgroundColor:"#0C0F27" , color:"#fff", margin:"10px", border:"none"}}>
             Account name : { profileData?.account_name}
</p>          
  <Avatar sx={{ width: 40, height: 40 }}>  {getFirstLetter()} </Avatar>

          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={menu}
          id="account-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
    
           <Avatar sx={{ width: 32, height: 32 }} style={{marginLeft:"70px"}}>
                {getFirstLetter()} 
           </Avatar>
          

            <MenuItem>
            <span className='text-capitalize' style={{marginLeft:"50px"}}>{profileData?.name}</span>
           </MenuItem>

            <MenuItem>
            <span style={{marginLeft:"20px",marginTop:"-10px"}} >{profileData?.email}</span>

            </MenuItem>
           <hr style={{marginTop:"5px" , marginBottom:"8px"}}></hr>

           {list.map((value, index) => (
            <MenuItem
              key={value.onboarding_id}
              selected={index === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              <Avatar /><span className='text-capitalize'>{value.account_alias}</span>
            </MenuItem>
          ))}

          <MenuItem onClick={selectAllAccounts} selected={selectedIndex === null}>
            <Avatar src={'../assets/logo.png'} > <SupervisorAccountIcon /> </Avatar>All accounts
          </MenuItem>
          <Divider />
          <MenuItem onClick={addAnotherAccount}>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
          <MenuItem onClick={routeToInvite}>
            <ListItemIcon>
              <EmailIcon fontSize="small" />
            </ListItemIcon>
            Invites
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={onLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Topbar;

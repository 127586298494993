import { Box, Button, IconButton } from "@mui/material"
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from "react"
import request from "../utils/requests"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const SpotAdoption = ({ list, updateList }) => {

    const [open, setOpen] = useState(false);
    const [volumeId, setVolumeId] = useState('');

    const handleClickOpen = (volume_resource_id) => {
        setVolumeId(volume_resource_id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteVolumeById = () => {
        //     request.del(`/aws/delete_vol/${volumeId}/`).then(res => {
        //         setOpen(false);
        //     })
        //     setOpen(false);
    }

    const columns = [
        { field: 'account_id', headerName: 'Account Id', width: 150, sortable: false, },
        { field: 'account_name', headerName: 'Account Name', width: 150, sortable: false, },
        { field: 'region', headerName: 'Region', width: 150, sortable: false, },
        { field: 'prod_instance_count', headerName: 'Prod Instance Count', width: 150, sortable: false, },
        { field: 'prod_instance_ondemand_count', headerName: 'Prod Instance Ondemand Count', width: 150, sortable: false, },
        { field: 'prod_instance_spot_count', headerName: 'Prod Instance Spot Count', width: 150, sortable: false, },
        { field: 'prod_spot_adoption', headerName: 'Prod Spot Adoption', width: 150, sortable: false, },
        // { field: 'action', headerName: 'Action', width: 100, sortable: false, },
    ];

    const downloadReport = () => {
        request.get('/spot_adoption_report/download_csv/').then(res => {
            const blob = new Blob([res.data], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Spot_Adoption.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }

    return (
        <>
            {list && list.length > 0 && <Box>
                <Box >
                    <h4 style={{ marginTop: 10, fontWeight: 'bold' }}>Spot Adoption (production)(Last Updated on {'date'})</h4>
                    <h6 style={{ marginTop: 30, fontWeight: 'bold' }}>Summary</h6>
                    <h6 style={{ marginTop: 10 }}>
                        This report provides Spot coverage of organization for the production environment.<br />
                        The current organization-level Spot adoption is 2.78%.
                    </h6>
                    <h6 style={{ marginTop: 10, fontWeight: 'bold' }}>Report Criteria</h6>
                    <h6 style={{ marginTop: 10 }}>
                        Region-wise Spot adoption in a production environment.                </h6>
                    <h6 style={{ marginTop: 10, fontWeight: 'bold' }}>Recommended Actions</h6>
                    <h6 style={{ marginTop: 10 }}>
                        It is recommended to increase the Spot adoption for massive savings.
                    </h6>
                    <Box sx={{ display: 'flex' }}>
                        <Button variant="contained" size="small"
                            sx={{
                                backgroundColor: "#1672EC",
                                color: 'white',
                                textTransform: "none",
                                marginLeft: 'auto',
                                marginRight: '20px',
                                '&:hover': {
                                    backgroundColor: "#1672EC",
                                    color: "white",
                                    textTransform: "none"

                                }
                            }}>
                            Generate Report
                        </Button>
                        <IconButton aria-label="download" onClick={downloadReport}>
                            <CloudDownloadIcon />
                        </IconButton>
                    </Box>
                </Box>


                <Box style={{ width: 'fit-content', maxWidth: '100%' }}>
                    <div className="custom-table" style={{ marginTop: '10px' }}>
                        <div className="custom-table-header" >
                            {columns.map((column, index) =>
                                <div key={index} style={{ width: `${column['width']}px` }}>{column.headerName}</div>
                            )}
                        </div>

                        {list.map((item, index) =>
                            <div key={item.instance_id} style={{ borderBottom: '1px solid' }}>
                                <div className="custom-table-row">
                                    {columns.map((column, ind) =>
                                        <div key={ind} style={{ width: `${column['width']}px` }}>

                                            {
                                                column['field'] != 'action' ? item[column['field']] ? item[column['field']] : '-' :
                                                    <Button variant="contained" size="small" onClick={() => handleClickOpen(item['account_id'])}
                                                        sx={{
                                                            backgroundColor: "red",
                                                            color: 'white',
                                                            textTransform: "none",
                                                            fontWeight: 'bold',
                                                            '&:hover': {
                                                                backgroundColor: "red",
                                                                color: "white",
                                                                textTransform: "none"
                                                            }
                                                        }}> Delete </Button>
                                            }
                                        </div>

                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </Box >
            </Box>
            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete this Volume?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        "lorium ipsum"
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={deleteVolumeById} autoFocus sx={{
                        backgroundColor: "red",
                        color: 'white',
                        textTransform: "none",
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: "red",
                            color: "white",
                            textTransform: "none"
                        }
                    }}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SpotAdoption
import { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';


const ApexLineChart = ({ data, name, categories, unit, height = 450, width = 610}) => {
    
    useEffect(() => {


    }, [])

    var series = [{
        name: name,
        data: data
    }]
    var options = {
        chart: {
            type: 'line',
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: false,
                zoomedArea: {
                    fill: {
                        color: '#90CAF9',
                        opacity: 0.4
                    },
                    stroke: {
                        color: '#0D47A1',
                        opacity: 0.4,
                        width: 1
                    }
                }
            },
            events: {
                selection: function (chartContext, { xaxis, yaxis }) {
                },
                zoomed: function (chartContext, { xaxis, yaxis }) {
                    // onGraphClick()
                }
            }

        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            width: 2
        },
        title: {
            text: name,
            style: {
                color: '#fff',
                fontFamily: "Plus Jakarta Sans",
                marginTop: "20px", // Adjust top margin
                marginBottom: "20px" 

              },
            align: 'center',

           
        },
        // grid: {
        //     row: {
        //         colors: ['#f3f3f3', 'transparent'],
        //         opacity: 0.5
        //     },
        // },
        grid: {
            borderColor: '#535A6C', // Set grid border color
            strokeDashArray: 4, // Set stroke dash array for dotted lines
        },

        xaxis: {
            type: 'datetime',
             categories: categories,
             labels: {
                style: {
                  colors: "#fff",
                },
              },
        },
       
        yaxis: {
            labels: {
                style: {
                    colors: "#fff",
                  },
                formatter: function (value) {
                    if (unit === 'Percent') {
                        // If unit is percentage, return value with percent sign
                        return `${value}%`;
                    } else if (unit === 'Bytes') {
                        // Convert bytes to gigabytes (GB)
                        const valueInGB = value / (1024 * 1024 * 1024);
        
                        // Format the value with two decimal places
                        const formattedValue = valueInGB.toFixed(2);
        
                        return `${formattedValue} GB`;
                    } else {
                        // For other units, just return the formatted value
                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                }
            }
        }
        
        
        
    };
    return (
        <>
        <div className='pie-chart line-chart'>
            <ReactApexChart options={options} series={series} type="line" height={height} width={width} /> 
            </div>
        </>
    )

}

export default ApexLineChart;

import React, { useEffect , useState } from 'react'
import styles from "./Scheduleinstancelogs.module.scss"
import request from "../utils/requests"
import { toast } from 'react-toastify';


const Scheduleinstancelogs = () => {
    const[details,setDetails] = useState("")

    const fetchData = async () => {
          try {
            const res = await request.get(`ec2_cron_jobs/list/`);
            const data = Array.isArray(res?.data) && Array.isArray(res?.data[0]) ? res?.data[0] : [];
             setDetails(data)           
           
          } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Failed to fetch cron job data');
          }
        
      };
  
useEffect(()=>{
fetchData()
},[])




  return (
    <div className={styles.accountsTable}>
    <table cellPadding="10px"  className={styles.table}>
      <thead className={styles.heading}>
         <th>Cron job</th>
         <th>Instance id</th>
          <th>Region</th>
          <th>Status</th>
          <th>Action</th>
          <th>Frequency</th>
      </thead>
     
     
         
           <tbody className={styles.tbody}>
        {details?.length && details?.map((data, index) => (
          <tr
            key={index}>
          <td>{data.start_time}</td>
             <td>{data.ec2_instance_id}</td>
            <td>{data.region}</td>
            <td>{data.status}</td>
            <td>{data.action}</td>
            <td>{data.frequency}</td>
         

          </tr>
        ))}
      </tbody>
    </table>
    
  </div>  )
}

export default Scheduleinstancelogs


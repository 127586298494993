import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import request from "../utils/requests";
import {Box,Button} from "@mui/material";
import { headings, tableColumns } from "../utils/constant";
import ApexLineChart from "../components/ApexLineChart";
import CustomCard from "../components/Card";
import styles from "../pages/Recommendation.module.scss";
import ApexHeatMapChart from "./Scheduleinstancegraph";
import Scheduleinstancedrawer from "./Scheduleinstancedrawer";


const RecommendationDetails = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const [graphData, setGraphData] = useState([]);
  const [details, setDetails] = useState(false);
  const [savingCost, setSavingCost] = useState(0);
  const [descriptions, setDescriptions] = useState([]);
  const [instancRecommendation, setInstancRecommendation] = useState([]);
  const[region,setRegion]=useState("")
 

  const handleAction= ()=>{ 
    request
      .get(
        `aws_recommendation/visit_in_console/?id=${id}&type=${type}&region=${region}`
      )
      .then((res) => {
        window.open(res.data)
      });
}



  useEffect(() => {
    request
      .get(`aws_recommendation/detail_by_id/`, { id: id, type: type })
      .then((response) => {
        setDetails(response.data);
        if (response.data.length > 0) {
          const recommendation = response.data[0][0]; 
          setRegion(recommendation.region);
        }

        let maxSavingCost = 0;
        let descArr = [];

        response?.data?.forEach((item) => {
          if (item?.length > 0) {
            const recommendation = item[0]; // Assuming each item is an array with a single recommendation

            // if (recommendation.saving_cost > maxSavingCost) {
            //   maxSavingCost = recommendation.saving_cost;
            //   setSavingCost(maxSavingCost);
            // }

            const savingCost = parseFloat(recommendation.saving_cost?.replace('$', '') || 0);
            if (savingCost > maxSavingCost) {
              maxSavingCost = savingCost;
              setSavingCost(maxSavingCost);
            }

            descArr.push(recommendation.description);
          }
        });
        setDescriptions(descArr);
      });

    

    request
      .get(`aws_recommendation/details/`, { id: id, type: type })
      .then((res) => {
        const transformedData = {};

        res?.data?.forEach((entry) => {
          const name = entry.name;
          const data = entry.data;
          const unit = entry.data[0].unit;
          data.forEach((item) => {
            if (!transformedData[name]) {
              transformedData[name] = {
                name: name,
                unit: unit,
                categories: [],
                value: [],
              };
            }
            transformedData[name].categories.push(item.metric_date);
            transformedData[name].value.push(item.value);
          });
        });
        setGraphData(Object.values(transformedData));
    
      });

    if (
      type == "Instance_type_conversion" ||
      type == "Ec_redis_type_conversion" ||
      type == "Ec_memcache_type_conversion" ||
      type == "Rds_type_conversion" ||
      type == "Rds_type_conversion_in_same_family" ||
      type == "Rds_type_conversion_in_graviton" ||
      type == "Rds_right_size_conversion" ||
      type == "Resize_rds_in_graviton" ||
      type == "Resize_instance_in_graviton" ||
      type == "Resize_EC_redis_in_graviton" ||
      type == "Resize_EC_memcache_in_graviton" ||
      type == "EC_memcache_right_size_conversion" ||
      type == "EC_memcache_type_conversion_in_same_family" ||
      type == "EC_redis_right_size_conversion" ||
      type == "EC_redis_type_conversion_in_same_family" ||
      type == "Msk_instance_type_conversion"||
      type == "Resize_auto_scaling" ||
      type == "Resize_autoscaling_in_graviton" 
    ) {
      request
        .get(`aws_recommendation/all_recommend_option/`, { id: id, type: type })
        .then((res) => {
          let instance_recommend_option = [];

          res.data.flat().forEach((item, index) => {
            if (index == 0) {
              instance_recommend_option.push({
                name: `current`,
                price: item.current_price,
                saving: item.saving_cost,
                region: item.region,
                unit: item.unit,
                cpu: item.cpu,
                ram: item.ram ,
                instance_type: item.current_instance_type,
              });
            } else {
              instance_recommend_option.push({
                name: `option ${index}`,
                price: item.current_price,
                saving: item.saving_cost,
                region: item.region,
                unit: item.unit,
                cpu: item.cpu,
                ram: item.ram ,
                instance_type: item.recommend_instance_type,
              });
            }
          });
          setInstancRecommendation(instance_recommend_option);

        });
    }
  }, []);

  // const parseSavingCost = (cost) => parseFloat(cost?.replace('$', '') || 0);

  const parseSavingCost = (cost) => {
    if (typeof cost === "string") {
      return parseFloat(cost.replace('$', '')) || 0;
    }
    return cost;
  };

  return (
    <Box
      sx={{ maxHeight: `calc(100vh - ( 120px ))`, p: 3 }}
    >
            <div class={`${styles.initialbreadcrumb} ${styles.breadcrumb}`}>
            <div>
        <a className={styles.breadc} href="/recommendations">
          Recommendation
        </a>
        <span className={styles.hyphen}>/</span>
        <a className={styles.breadc} href={`/recommendations/${type}`}>
      {type.replace(/_/g, ' ')} {/* Replaces underscores with spaces */}
       </a>

        <span className={styles.hyphen}>/</span>
        <span className={styles.id}>{id}</span>
        </div>
        <div>               
                 <button style={{backgroundColor:"#FF9900", color:"#000", border:"none" , padding:"10px" , borderRadius:"15px"}} onClick={()=>{handleAction()}}>Visit aws console</button>
              </div>
      </div>

      <h5 className={`${styles.recommendationHeading} ${styles.detailpageh}`}>
        {headings[type]} Recommendations{" "}
      </h5>


 
      <Box className="d-flex justify-content-between">
        <CustomCard
          title={"Current Price"}
          value={
            instancRecommendation.length > 0
            ? parseSavingCost(instancRecommendation[0].price)  // Assuming the current value is at index 0
              : savingCost
          }
        />

        <CustomCard
          title={"Worst Case Saving"}
          value={
            instancRecommendation.length > 0
              ? Math.min(
                  ...instancRecommendation
                    .map((row) => {
                      const savingValue = parseSavingCost(row.saving);
                      return !isNaN(savingValue) ? savingValue : Infinity;
                    })
                    .filter((value) => !isNaN(value))
                )
              : savingCost
          }
        />

        <CustomCard
          title={"Best Case Saving"}
          value={
            instancRecommendation.length > 0
              ? Math.max(
                  ...instancRecommendation
                    .filter((row) => row.name !== "current") // Exclude 'current' from options
                    .map((row) => parseSavingCost(row.saving))
                )
              : savingCost
          }
        />
      </Box>
    
      {type != "Instance_type_conversion" &&
        type != "Ec_redis_type_conversion" &&
        type != "Ec_memcache_type_conversion" &&
        type != "Rds_type_conversion" &&
        type != "Rds_type_conversion_in_same_family" &&
        type != "Rds_type_conversion_in_graviton" &&
        type != "Rds_right_size_conversion" &&
        type != "Msk_instance_type_conversion" && 
        type != "Resize_rds_in_graviton" && 
        type != "Resize_instance_in_graviton" && 
        type != "Resize_auto_scaling" && 
        type != "Resize_autoscaling_in_graviton" && 
        type != "Resize_EC_redis_in_graviton" && 
        type != "Resize_EC_memcache_in_graviton" && 
        type != "EC_memcache_right_size_conversion" && 
        type != "EC_memcache_type_conversion_in_same_family" && 
        type != "EC_redis_right_size_conversion" && 
        type != "EC_redis_type_conversion_in_same_family" &&(
          <div>
            {descriptions &&
              descriptions.length > 0 &&
              descriptions.map((desc, index) => (
                <div className={styles.box} key={index}>
                  {<div dangerouslySetInnerHTML={{ __html: desc }} />}
                </div>
              ))}
          </div>
        )}
      {(  type == "Instance_type_conversion" ||
      type == "Ec_redis_type_conversion" ||
      type == "Ec_memcache_type_conversion" ||
      type == "Rds_type_conversion" ||
      type == "Rds_type_conversion_in_same_family" ||
      type == "Rds_type_conversion_in_graviton" ||
      type == "Rds_right_size_conversion" ||
      type == "Resize_rds_in_graviton" ||
      type == "Resize_instance_in_graviton" ||
      type == "Resize_EC_redis_in_graviton" ||
      type == "Resize_EC_memcache_in_graviton" ||
      type == "EC_memcache_right_size_conversion" ||
      type == "EC_memcache_type_conversion_in_same_family" ||
      type == "EC_redis_right_size_conversion" ||
      type == "EC_redis_type_conversion_in_same_family" ||
      type == "Msk_instance_type_conversion"||
      type == "Resize_auto_scaling" ||
      type == "Resize_autoscaling_in_graviton") && (
   
        <div className={`${styles.resizeTable} ${styles.mainTable}`}>
          <table className={styles.table}>
            <thead className={styles.heading}>
              <tr>
                <th>
                  <b>Options</b>{" "}
                </th>
                <th>Instance type</th>
                <th>Price</th>
                <th>Saving</th>
                <th>Term</th>
                <th>Cpu</th>
                <th>RAM</th>
                <th>Region</th>
              </tr>
            </thead>
            <tbody className={styles.tbody}>
              {instancRecommendation.map((row) => (
                <tr>
                  <td>{row.name}</td>
                  <td>{row.instance_type}</td>
                  <td>{row.price}</td>
                  <td>
                    {row.saving !== undefined ? (
                      <>
                        {/* {Math.abs(row.saving - Math.min(...instancRecommendation.map((r) => r.saving))) < 0.0001 && (
                                                <DoneIcon style={{ color: 'green', marginRight: '4px' }} />
                                            )} */}
                        {row.saving}
                      </>
                    ) : (
                      "---"
                    )}
                  </td>

                  <td>{row.unit}</td>
                  <td>{row.cpu}</td>
                  <td>{row.ram}</td>
                  <td>{row.region}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <Box>
          {headings[type] === "Schedule instances" ? <div>
                {/* <button onClick= {()=>{navigate('/Scheduleinstancedrawer')}} 
                className={styles.schedulebutton}>
                            Schedule instance       
                                </button> */}
                                <Scheduleinstancedrawer/>
                                  <ApexHeatMapChart/>
                                        
                                          </div>

                                :""}
        {headings[type] === "Amazon simple storage service" ? (
          // <button
          //   className={styles.whatsApps}
          //   href="https://wa.me/918532044146/?text=I%20would%20like%20to%20chat%20with%20you"
          //   target="_blank"
          // >
          //   Connect us on WhatsApp
          // </button>
          <button
  className={styles.whatsApps}
  onClick={() => window.open("https://wa.me/918532044146/?text=I%20would%20like%20to%20chat%20with%20you", "_blank")}
>
  Connect us on WhatsApp
</button>
        ) : (
          <Box
            className="d-flex flex-wrap justify-content-between"
            sx={{ marginTop: 5 }}
          >
              

            {graphData &&
              graphData.length > 0 &&
              graphData.map((graph, index) => (
                <ApexLineChart
                  data={graph.value}
                  categories={graph.categories}
                  name={graph.name}
                  key={index}
                  unit={graph.unit}
                />
              ))}

            {graphData.length < 0 && (
              <Box className="fixed-center">
                No metrics avilable for this volume{" "}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RecommendationDetails;

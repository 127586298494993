import { useNavigate } from "react-router-dom"

export default function PageNotFound() {
    const navigate = useNavigate()

    const back = () => {
        navigate("/")
    } 

    return (
        <>
            <div className="fixed-center d-flex flex-column">
                <img src='https://d2qtwwg2436q9r.cloudfront.net/download.png' width={'100%'}/>
                <h1>Page not Found</h1>
                <button onClick={back}>back</button>
            </div>
        </>
    )
}

export const selectedAccountId = (data) => {
    return { 
      type: "SELECT_ACCOUNT",
      payload: data
    };
  };
  
//   export const selectAllAccounts = () => {
//     return { 
//       type: "SELECT_ALL_ACCOUNTS"
//     };
//   };
  
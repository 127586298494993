import ApexTreeMapChart from "../components/ApexTreeMapChart";
import { useState, useEffect } from "react";
import request from "../utils/requests"
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import RecommendationTable from "./RecommendationTable";
import styles from "./Recommendation.module.scss";



const Recommendations = () => {
    
    
   const { type } = useParams()

    const navigate = useNavigate();
    const navigateToRecommendations = (type) => {
        navigate(`/recommendations/${type}`)
    }

    
      const handleDownload = () => {
        request.get('/aws_recommendation/download_csv/').then(res => {
          const blob = new Blob([res.data], { type: "text/csv" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Recommendation.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      }

      return (
        <>
        <div className={styles.graphwrapper}>
        <button onClick={handleDownload} 
        // style={{  backgroundColor:"#01579B" , borderRadius:"5px",padding:"2px 10px",color:"white",borderStyle:"none" ,   marginLeft: "auto", marginRight: "18px",display: "flex"}} 
                  className={styles.recommendationbutton}
                  >
             Download
           </button>
            {/* <button onClick={handleDownload}>Download</button> */}
            <div className="recommedationPage">
            <ApexTreeMapChart 
            onGraphClick={navigateToRecommendations} />
            <RecommendationTable/>
            </div>
            </div>
        </>
    )
}

export default Recommendations
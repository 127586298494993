import React, {useEffect,useState}from 'react';
// import { toast } from 'react-toastify';
import request from "../../utils/requests";
import styles from "./Jobs.module.scss";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Jobshandle = () => {
  const [jobs, setJobs] = useState('');
  
  const fetchList = async () => {
    try {
        const response = await request.get(`jobs/lists/`);
        setJobs(response.data);

  
    } catch (error) {
        // throw error;
    }
  
  };
  
  useEffect(() => {
  
    fetchList();
  }, []);


  const action = async (jobId,action) => {
    try {
        const response = await request.put(`jobs/disable/?state=${action}&job_id=${jobId}`);
        fetchList();

    } catch (error) {
        console.error('helo  :', error);
        // throw error;
    }
  
  };

  return (
    <div className={styles.accountsTable}>
      <table cellPadding="10px" width="90%">
        <thead className={styles.heading}>

            <td>Job Id</td>
            <td>File Name</td>
            <td>Onboarding Id <ArrowDropDownIcon/>  </td>
            <td>Failure count</td>
            <td>Action</td>
        </thead>
        
        <tbody className={styles.body}>
          {jobs?.length && jobs?.map((data, index) => (
            <tr
              key={index}>
              <td>{data.job_id}</td>
              <td>{data.file_name}</td>
              <td>{data.onboarding_id}</td>
              <td>{data.failure_count===null ? "Null" : data.failure_count}</td>
              <td>
                <span className={styles.action}>
               {data.enable === false ? <button className={styles.enable} onClick={() => action(data.job_id,"disable")}>Disable</button> :  <button className={styles.disable} onClick={() => action(data.job_id,"enable")}>Enable</button>
              }
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
     
    </div>
  )
}

export default Jobshandle;

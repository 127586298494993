import React from "react";
import SeverityGraph from "./SeverityGraph";
import style from "../Security/Security.module.scss";
import StatusGraph from "./StatusGraph";
import Checkorder from "./Checkorder";
import RegionGraph from "./RegionChart";
import Services from "./FindingByServices";
import Account from "./FindingByAccount";
import Time from "./FindingOverTime";
import OverTimeLinegraph from "./OverTimeLinegraph";
import RegionTable from "./FindingByRegion";

const SecurityDashboard = () => {
  return (
    <>
      {/* <div className={style.allFilter}>
     <div className={style.mainbread}>
      <h1>hello</h1>
      <div className={style.relative}></div>
      </div>
     </div> */}
      <div className={style.security}>
        <div className={style.left}>
          <SeverityGraph />
          <StatusGraph />
          <Services />
          <Account />
          <Time />
          <RegionTable />
          <RegionGraph />
          <OverTimeLinegraph />
          {/* <RegionLineChart /> */}
        </div>
        <div className={style.right}>
          <Checkorder />
        </div>
      </div>
    </>
  );
};

export default SecurityDashboard;

import React ,{useState}from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import request from '../utils/requests';
import { toast } from "react-toastify";
import { showLoading, hideLoading } from '../store/actions/loader';
import { useNavigate } from "react-router-dom";

const Onboardingstep4 = ({ onboardingDetails,setOnboardingDetails, setStep ,handleNavClick}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate()

  const dispatch = useDispatch();

  const handleFileInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const setUpIAMPermision = () => {
    dispatch(showLoading())
    request.get(`/iamuser/cloudformation_script/`).then((response) => {
      dispatch(hideLoading())
      console.log(response.data)
      window.open(response.data)
    }).catch((error) => {
      dispatch(hideLoading())
      toast.error(error.message)
    });
  }
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setOnboardingDetails(values => ({ ...values, [name]: value }))
  }
  return (
    <div>
     {/* step4 */}
 {/* {activeStep === 4 ? */}
    <div className="row">
      <div className="col-lg-12">
        {/* setup-permission-content */}
        <div className="main-step1 align">

        <div className="setup-permission-content mb-5">
          <div className="sec-heading-title">
            <h2 className="welcome">
              Setup Permission Using CloudFormation
            </h2>
            {/* <p className="mb-4 meta-field-text">
              <strong>1.</strong> Create CloudForecast AWS User Using either
              of the following options.
            </p> */}
            <ul className="attach-list">
              <li>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Cloud Formation</Form.Label>
                  <div className="d-flex align-items-center">
                    <input type="file" hidden onChange={handleFileInputChange} />
                    <Button
                      // onClick={() => document.querySelector('input[type=file]').click()}
                      onClick={setUpIAMPermision}
                      className="onboardingbutton"
                    >
                      Launch Stack
                    </Button>
                  </div>
                </Form.Group>
              </li>
              {/* <li className="">
                Terraform
                <p className="text-field mb-0">
                  <strong className="primary-color me-2">
                    VA2PT Module
                  </strong>
                  <span>with the following configuration:</span>
                </p>
                <ul className="list-wrap">
                  <li>
                    External Id: <strong>4549386549183498</strong>
                  </li>
                  <li>
                    S3 Bucket:{" "}
                    <strong>Sdjhdgafjknbhfbfhahdfbkllfhbk-v1</strong>
                  </li>
                </ul>
              </li> */}
              <li className="">
                Wait for the process to finish and copy the newly created ARN.
                <p className="">
                  <span></span>
                </p>
              </li>
            </ul>
          </div>
          <div className="form-sec-wrap">
            
            <Form className="form-sec-content">
              <Form.Group className="mb-3 arn-input" controlId="exampleForm.ControlInput1">
              <p className="ptext" >Enter the AWS Account Name</p>
                <Form.Control
                  className="mb-3 arn-input"
                  type="text"
                  placeholder=" AWS Account Name"
                  name="account_alias"
                  value={onboardingDetails.account_alias || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /\s/;
                    if (!regex.test(value)) {
                      handleChange(e);
                    }
                  }}
                />
                <p className="ptext" >Enter the AWS IAM ARN</p>
                <Form.Control
                  className="mb-3 arn-input"
                  type="text"
                  placeholder="ARN"
                  name="arn"
                  value={onboardingDetails.arn || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /\s/;
                    if (!regex.test(value)) {
                      handleChange(e);
                    }
                  }}
                />
                {/* <p className="ptext">Enter the AWS Access Key</p>
                <Form.Control
                  className="mb-3 arn-input"
                  type="text"
                  placeholder="AWS Access Key"
                  name="aws_access_key"
                  value={onboardingDetails.aws_access_key || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /\s/;
                    if (!regex.test(value)) {
                      handleChange(e);
                    }
                  }}           
                       />
                <p className="ptext">Enter the AWS Seceret Key</p>
                <Form.Control
                  className="mb-3 arn-input"
                  type="text"
                  placeholder="AWS Secret Key"
                  name="aws_secret_key"
                  value={onboardingDetails.aws_secret_key || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /\s/;
                    if (!regex.test(value)) {
                      handleChange(e);
                    }
                  }}         
                         /> */}
              </Form.Group>

              <Button variant="primary" className="onboardingbutton" onClick={() => {navigate('/onboarding/step5');setStep(5)}}
                disabled={!onboardingDetails.arn } >
                Next
              </Button>
            </Form>
            <div className="help-btn-link">
              <p className="ptext">
                Need help setting up your account? Our team is here to help.
              </p>
            </div>
            <Button
                className="btn-overlay-primary mx-1 my-3 whatsApp"
                href="https://wa.me/918532044146/?text=I%20would%20like%20to%20chat%20with%20you"
                target="_blank"
              >
                Connect us on WhatsApp
              </Button>
          </div>
        </div>
      </div>
      </div>
    </div>
    </div>
  );
};

export default Onboardingstep4;

 
 
 
 
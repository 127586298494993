
const initialState = []

const DashboardList = (state = initialState, action)=>{
    
    switch(action.type){
        case "add": return [...action.payload];
        default: return state
        
    }
}

export default DashboardList
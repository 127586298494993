import request from "../utils/requests"
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showLoading, hideLoading } from '../store/actions/loader';
import { toast } from 'react-toastify';
import { Box, IconButton, Button, Modal, TextField, Tabs, Tab, Card, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import { currentUser } from "../utils/functions";
import moment from "moment/moment";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import style from "./Invite.module.scss"


const Invites = ({ setModlaHandler, modalHandler, tabs, setTabs }) => {

    const dispatch = useDispatch();
    const animatedComponents = makeAnimated();


    const [openSendInviteModal, setOpenSendInviteModal] = useState(false);
    const [inviteEmail, setInviteEmail] = useState('');
    const [accountList, setAccountList] = useState([]);
    const [tab, setTab] = useState(tabs);
    const [sentlist, setSentList] = useState([]);
    const [recievedlist, setRecievedList] = useState([]);
    const [selectedAccountIds, setSelectedAccountIds] = useState([]);
    const [showButtons, setShowButtons] = useState(true);
    // const [updateClicked, setUpdateClicked] = useState(false);
    const [isViewOpen, setIsViewOpen] = useState(true);
    const [firstname, setfirstName] = useState('')
    // const [open, setOpen] = useState(false); // Add state for controlling the dropdown open state
    const [resent, setResent] = useState(null);
    const [emailError, setEmailError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [receiverEmail, setReceiverEmail] = useState(''); // Declare a state to store the email
    const [receiverId, setReceiverId] = useState(''); // Declare a state to store the email





    const senderColumns = [
        { field: 'receiver_data_name', headerName: 'Name', width: 150, sortable: false, },
        { field: 'receiver_data_email', headerName: 'Email', width: 200, sortable: false, },
        { field: 'invite_status', headerName: 'Status', width: 150, sortable: false, },
        { field: 'created_date', headerName: 'Date', width: 150, sortable: false, },
        { field: 'action', headerName: 'Action', width: 150, sortable: false, },
        { field: 'permission', headerName: 'Permission', width: 250, sortable: false, },

    ]

    const receiverColumns = [
        { field: 'sender_data_name', headerName: 'Name', width: 200, sortable: false, },
        { field: 'sender_data_email', headerName: 'Email', width: 200, sortable: false, },
        { field: 'invite_status', headerName: 'Status', width: 150, sortable: false, },
        { field: 'created_date', headerName: 'Date', width: 150, sortable: false, },
        { field: 'action', headerName: 'Action', width: 250, sortable: false, }
    ]

    const style_modal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        display: 'flex',
        flexDirection: 'column'
    };

    const fetchaccount_id = async () => {
        try {
            const response = await request.get(`/onboarding/lists/`);
            console.log("res", response.data.account_id);
            return response;
        } catch (error) {
            console.error('Error fetching accountid:', error);
            throw error;
        }

    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchaccount_id();
                setAccountList(response.data.map(account => account.account_id));
            } catch (error) {
                // Handle errors as needed
            }
        };

        fetchData();
    }, []);



    useEffect(() => {
        getInviteList(tab);
    }, [tabs])
    

    const getInviteList = (tab) => {
        request.get(`/users/invite/list/`).then((res) => {
            if (res) {
                let arr = []
                console.log(currentUser())
                if (tab === 0) {
                    arr = res.data.filter(val => {
                        return val.sender_id == currentUser()
                    })
                    setSentList(arr)
                } else {
                    arr = res.data.filter(val => {
                        return val.receiver_id == currentUser()
                    })
                    setRecievedList(arr)

                }

            }
        })
    }
 

    const handleChange = (event, newValue) => {
        setTab(newValue)
        getInviteList(newValue);
    setModlaHandler(false);
        
    };

    const handleSendInviteOpen = () => {
        setOpenSendInviteModal(true);
    };

    const handleSendInviteClose = () => {
        setInviteEmail('')
        setSelectedAccountIds('')
        setfirstName('')
        setOpenSendInviteModal(false);
    };

    const handleName = (e) => {
        const name = e.target.value;
        setfirstName(name);
        setNameError(name.trim() === '');
    }

    const updateInviteEmail = (e) => {
        const email = e.target.value;
        setInviteEmail(email);
        setEmailError(email.trim() === '' || !isValidEmail(email));
    }

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const handleSubmit = () => {
        setEmailError(inviteEmail.trim() === '' || !isValidEmail(inviteEmail));
        setNameError(firstname.trim() === '');

        if (inviteEmail.trim() === '' || !isValidEmail(inviteEmail) || firstname.trim() === '') {
            return; 
        }

    };

    const handleViewClose = () => {
        setIsViewOpen(false)
        setShowButtons(true);
        setModlaHandler(false)
    }

    const sendInvite = () => {
        console.log("invite")
        dispatch(showLoading());
        handleSubmit();

        request.post(`/users/invite/send/`, { email: inviteEmail, firstname: firstname, account_id: selectedOptions })
            .then((res) => {
                if (res) {
                    handleSendInviteClose();
                    toast.success(res.data.message);
                    getInviteList(0);
                    dispatch(hideLoading());
                    // setSelectedAccountIds([]);
                }
            })


            .catch((error) => {
                dispatch(hideLoading());
                if (error.response) {
                    if (error.response.status === 500) {
                        toast.error("Internal Server Error. Please try again later.");
                    } else if (error.response.status === 400) {
                        if (error.response.data && error.response.data.detail) {
                            toast.error(error.response.data.detail);
                        } 
                    } else if (error.response.status === 422) {
                        toast.error("Please fill out all required fields before proceeding.");

                    } 
                }
             
            });
    };


    const resend = (item) => {
        console.log("Invite Email:", inviteEmail);
        const { receiver_data_email } = item
        dispatch(showLoading());
        setResent(item);

        request.post(`/users/invite/resend_otp/`, { email: receiver_data_email }).then((res) => {
            if (res) {
                handleSendInviteClose();
                toast.success("Resend Mail Successfully");
                getInviteList(0);
                dispatch(hideLoading());
            }
        }).catch((error) => {
            dispatch(hideLoading());
            toast.error("Internal Server Error. Please try again later.");
        });
    }

    console.log("resent",resent)


    const viewAccountIds = (receiverId) => {
        request.get(`/users/account/list/?receiver_id=${receiverId}`) 
        .then((res) => {
                if (res && res.data) {
                    // const accessAccountIds = res.data.access_account_ids || [];
                    // const allAccountIds = res.data.all_account_ids || [];
                    setModlaHandler(true);
                    setReceiverEmail(res.data.email);
                    setReceiverId(res.data.receiver_id)

                    const newArray = res.data.all_account_ids.map(id => ({
                        id: id,
                        key: res.data.access_account_ids.includes(id)
                    }));
                    console.log("new array", newArray)
                    setShowButtons(false);
                    setSelectedAccountIds(newArray);

                }
            })
            .catch((error) => {
                console.error("Error fetching accountIds:", error);
            });
    };

    const update = (item) => {
        console.log("Item to update:", item);

        dispatch(showLoading());
        const extractedIds = selectedAccountIds
            .filter(obj => obj.key) // Filter objects where key is true
            .map(obj => obj.id);

        request.post(`/users/invite/update_permision/`, { email: receiverEmail, account_id: extractedIds })
            .then((res) => {
                if (res) {
                    handleSendInviteClose();
                    toast.success(res.data.message);
                    getInviteList(0);
                    dispatch(hideLoading());
                    setSelectedAccountIds([]);
                    setShowButtons(true);
                    viewAccountIds(receiverId);
                    setIsViewOpen(true);
                    setShowButtons(true);
                    setModlaHandler(false)
                }
            })
            .catch((error) => {
                dispatch(hideLoading());

                if (error.response && error.response.status === 500) {
                    toast.error("Internal Server Error. Please try again later.");
                } else {
                    toast.error("An unexpected error occurred.");
                }
            });
    };


    const acceptInvite = () => {
        dispatch(showLoading())
        request.put(`/users/invite/accept/`).then((res) => {
            if (res) {
                toast.success(res.data.message);
                getInviteList(tab)
                dispatch(hideLoading())
            }
        }).catch((error) => {
            dispatch(hideLoading())
        });
    }

    const rejectInvite = () => {
        dispatch(showLoading())
        request.put(`/users/invite/reject/`).then((res) => {
            if (res) {
                toast.success(res.data.message);
                getInviteList(tab)
                dispatch(hideLoading())
            }
        }).catch((error) => {
            dispatch(hideLoading())
        });
    }

    const handleCheckboxChange = (accountId) => {
        setSelectedAccountIds(prevIds => {
            const updatedIds = prevIds.map(idObj => {
                if (idObj.id === accountId) {
                    return {
                        id: idObj.id,
                        key: !idObj.key // Toggle the key property
                    };
                }
                return idObj;
            });
            return updatedIds;
        });
    };


    const handleSelectChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions.map(item => item.value));
      };
      

      const customStyles = {
        multiValue: (provided, state) => ({
          ...provided,
          border:'1px solid black',
          backgroundColor:"#fff" // Set background color based on the option's color
        }),
        multiValueLabel: (provided, state) => ({
          ...provided,
          color: '#252525', // Set text color to white
        }),
        multiValueRemove: (provided, state) => ({
          ...provided,
          color: 'red', // Set remove icon color to white
          ':hover': {
            backgroundColor: state.data.color, // Change background color on hover
            color: 'red', // Change text color on hover
          },
        }),
      };

   
    console.log("selectedOptions",selectedOptions)
    return (
        // <Box sx={{ padding: 2,maxWidth:"965px" }}>
        <Box sx={{ padding: 2 }}>

            <div className={style.upperbox}>
                <Tabs
                    value={tab}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                >
                    <Tab iconPosition="start"  className={style.tab}  style={{color:"#fff"}}label="Sent" />
                    <Tab iconPosition="start" className={style.tab}  style={{color:"#fff"}} label="Received" />

                </Tabs>
                <button className={style.sentbutton} onClick={handleSendInviteOpen} >
                    Send Invite</button>
            </div>
            <div style={{ position: "relative" }}>
                {
                    tab === 0 ?
                        <div>
                            <div sx={{ maxHeight: `calc(100vh - ( 115px )`, overflow: 'scroll' }} >
                                <div className="recommendation-table">
                                    <div className="recommendation-table-header">
                                        {senderColumns.map((column, index) =>
                                            <div key={index} className="recommendation-table-header-items" style={{ width: `${column['width']}px` }} title={column.headerName}>
                                                {column.headerName}
                                            </div>

                                        )}
                                    </div>

                                    {sentlist.map((item, index) =>
                                        <div className="recommendation-table-row" key={index}>
                                            {senderColumns.map((column, ind) =>
                                                <div key={ind} className="recommendation-table-row-items" style={{ width: `${column['width']}px` }} title={item[column['field']]}>

                                                    {
                                                        column['field'] !== 'action' ? (
                                                            column['field'] === 'created_at' ||
                                                                column['field'] === 'updated_at' ||
                                                                column['field'].includes('date') ?
                                                                moment(item[column['field']]).format('DD-MM-YYYY') :
                                                                column['field'] === 'permission' ?
                                                                    <>
                                                                        {showButtons === true ? (
                                                                            <>
                                                                                <button
                                                                                    variant="contained"
                                                                                    size="small"
                                                                                    onClick={() => viewAccountIds(item.receiver_id)}  // Wrap in arrow function to avoid auto-execution
                                                                                    className={style.sentbutton}
                                                                                >
                                                                                    View
                                                                                </button>

                                                                            </>
                                                                        ) :

                                                                            modalHandler && (

                                                                                // <div style={{ position: "fixed", background: "#e0e0e0", padding: "20px", borderRadius: "5px", height: "100vh", width: "300px", overflowY: "scroll", top: 0, right: 0 }}>
                                                                                    <div className={style.sidemodal}>
                                                                                    <div style={{ display: "flex", flexDirection: "column" }} >
                                                                                        <div>
                                                                                            <p style={{ fontWeight: "bold", marginTop: "-12px" }}>Account Ids </p>
                                                                                            <IconButton onClick={handleViewClose} sx={{ position: 'absolute', top: 1, right: 1 }} >
                                                                                                < CloseIcon className={style.close} />
                                                                                            </IconButton>
                                                                                        </div>
                                                                                        {selectedAccountIds?.map((account) => (
                                                                                            <div key={account.id} style={{ display: "flex" }}>
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    value={account.id}
                                                                                                    style={{ marginRight: "10px" }}
                                                                                                    checked={account.key} // Use account.key instead of accountId.key
                                                                                                    onChange={() => handleCheckboxChange(account.id)} // Implement handleCheckboxChange function
                                                                                                />
                                                                                                <label>
                                                                                                    {account.id}
                                                                                                </label>
                                                                                            </div>
                                                                                        ))}

                                                                                    </div>



                                                                                    <Button
                                                                                        variant="contained"
                                                                                        size="small"
                                                                                        onClick={() => { update(item) }}

                                                                                        sx={{
                                                                                            backgroundColor: "red",
                                                                                            color: 'white',
                                                                                            textTransform: "none",
                                                                                            fontWeight: 'bold',
                                                                                            marginTop: "600px",
                                                                                            marginLeft: '25px',

                                                                                            '&:hover': {
                                                                                                backgroundColor: "red",
                                                                                                color: "white",
                                                                                                textTransform: "none"
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Update
                                                                                    </Button>

                                                                                </div>
                                                                            )
                                                                           

                                                                        }



                                                                    </>
                                                                    : item[column['field']] ? item[column['field']] : '-'
                                                        ) :
                                                         
                                                            (resent?.receiver_data_email!==item['receiver_data_email']
                                                                && (item['invite_status'] === 'Unaccepted' || item['invite_status'] === 'reject') ? (
                                                                <Button variant="contained" size="small"
                                                                    onClick={() => { resend(item) }}
                                                                    sx={{
                                                                        backgroundColor: "green",
                                                                        color: 'white',
                                                                        textTransform: "none",
                                                                        fontWeight: 'bold',
                                                                        '&:hover': {
                                                                            backgroundColor: "green",
                                                                            color: "white",
                                                                            textTransform: "none"
                                                                        }
                                                                    }}>
                                                                    Resend
                                                                </Button>
                                                            ) : 'Sent!')
                                                            
                                                               
                                                            
                                                            }
                                                            
                                                  
                                                </div>
                                            )}
                                        </div>

                                    )}
                                </div>

                            </div>
                        </div > : null}
                {
                    tab === 1 ? <div >
                        <div sx={{ maxHeight: `calc(100vh - ( 115px )`, overflow: 'scroll' }} >
                            <div className="recommendation-table">
                                <div className="recommendation-table-header">
                                    {receiverColumns.map((column, index) =>
                                        <div key={index} className="recommendation-table-header-items" style={{ width: `${column['width']}px` }} title={column.headerName}>
                                            {column.headerName}
                                        </div>

                                    )}
                                </div>

                                {recievedlist.map((item, index) =>
                                    <div className="recommendation-table-row" key={index}>
                                        {receiverColumns.map((column, ind) =>
                                            <div key={ind} className="recommendation-table-row-items" style={{ width: `${column['width']}px` }} title={item[column['field']]}>
                                                {
                                                    column['field'] != 'action' ? column['field'] === 'created_at' ||
                                                        column['field'] === 'updated_at' ||

                                                        column['field'].includes('date') ? moment(item[column['field']]).format('DD-MM-YYYY') : item[column['field']] ? item[column['field']] : '-' :
                                                        item['invite_status'] == 'Unaccepted' ?
                                                            <>
                                                                <Button variant="contained" size="small"
                                                                    onClick={acceptInvite}
                                                                    sx={{
                                                                        backgroundColor: "green",
                                                                        color: 'white',
                                                                        textTransform: "none",
                                                                        fontWeight: 'bold',
                                                                        '&:hover': {
                                                                            backgroundColor: "green",
                                                                            color: "white",
                                                                            textTransform: "none"
                                                                        }
                                                                    }}> Accept </Button>

                                                                <Button variant="contained" size="small"
                                                                    onClick={rejectInvite}
                                                                    sx={{
                                                                        backgroundColor: "red",
                                                                        color: 'white',
                                                                        textTransform: "none",
                                                                        fontWeight: 'bold',
                                                                        marginLeft: '10px',
                                                                        '&:hover': {
                                                                            backgroundColor: "red",
                                                                            color: "white",
                                                                            textTransform: "none"
                                                                        }
                                                                    }}> Reject </Button>
                                                            </> : '-'
                                                }
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div > : null}
            </div>
            <Modal
                open={openSendInviteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style_modal} className={style.popup}>
                    <IconButton onClick={handleSendInviteClose} sx={{ position: 'absolute', top: 1, right: 1 }} >
                    < CloseIcon className={style.close} />
                    </IconButton>
                    <Box className="d-flex flex-column align-items-center">
                        <EmailIcon fontSize="large" />
                        <h4 >Send Invite </h4>
                    </Box>


                    <p>We will send a link  to the user that will take user to our secure registration portal, where he can provide the necessary details to kickstart the onboarding process. </p>

                    {/* <TextField
                        variant="outlined"
                        label="Enter email "
                        fullWidth
                        size='small'
                        sx={{ backgroundColor: 'white' }}
                        value={inviteEmail}
                        onChange={updateInviteEmail}
                        error={emailError}
                        helperText={emailError ? 'Please enter a valid email' : ''}

                    />

                    <TextField
                        variant="outlined"
                        label="Name"
                        fullWidth
                        size='small'
                        sx={{ backgroundColor: 'white', marginTop: "10px" }}
                        value={firstname}
                        onChange={handleName}
                        error={nameError}
                        helperText={nameError ? 'Name cannot be empty' : ''}

                    /> */}
                   
                   <input
              className={style.input}
                name="Name"
                type="Name"
                placeholder="Enter your name..."
                value={firstname}
                onChange={handleName}
                error={nameError}
                helperText={nameError ? 'Name cannot be empty' : ''}
                />
                  <input
              className={style.input}
                name="Email"
                type="email"
                placeholder="Enter your email..."
                value={inviteEmail}
                onChange={updateInviteEmail}
                error={emailError}
                helperText={emailError ? 'Please enter a valid email' : ''}
                />
                    <div style={{marginTop:"10px"}}>
                 <Select
         variant="outlined"
      closeMenuOnSelect
      components={animatedComponents}
    //   defaultValue={[colourOptions[4], colourOptions[5]]}
      isMulti
      options={accountList.map(item => ({ value: item, label: item }))}
      onChange={handleSelectChange}
      placeholder="Select Account Id's"
      styles={customStyles}
      
    />
                    </div>
                

                    <div className={style.popupsent}>
                        <button className={style.sentbutton}
                            onClick={sendInvite}
                        // disabled={!(inviteEmail&&firstname&&selectedAccountIds)}
                        >
                            Send Invite
                        </button>
                    </div>
                </Box>
            </Modal>
        </Box>

    )
}

export default Invites
// import GroupsIcon from '@mui/icons-material/Groups';
// import AutoGraphIcon from '@mui/icons-material/AutoGraph';
// import ArticleIcon from '@mui/icons-material/Article';
// import MovingIcon from '@mui/icons-material/Moving';


const aboutcardData = [
    {
        image: "https://d2qtwwg2436q9r.cloudfront.net/icons+(1)/Comprehensive+services.png",
        heading: "Comprehensive Services",
        description: "At Va2pt, our core values of DevOps transformation and comprehensive services drive our operations. We practice what we preach."
    },
    {
        image: "https://d2qtwwg2436q9r.cloudfront.net/icons+(1)/client+empowerment.png",
        heading: "Client Empowerment",
        description: "We empower our clients to achieve their business goals by providing solutions that meet diverse needs. Your success is our priority."
    },
    {
        image: "https://d2qtwwg2436q9r.cloudfront.net/icons+(1)/expertise+and+knowledge.png",
        heading: "Expertise and Knowledge",
        description: "Our team includes developers, DevOps engineers, SRE professionals, hackers, and cloud partners, providing top-tier solutions."
    },
    {
        image: "https://d2qtwwg2436q9r.cloudfront.net/icons+(1)/commitment+of+excellence.png",
        heading: "Commitment to Excellence",
        description: "At Va2pt, we deliver high-quality services. Our commitment to excellence ensures client satisfaction and outstanding solutions."
    }
]

export default aboutcardData;

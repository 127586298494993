
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from "../pages/NavigationBar.module.scss";

const NavigationBar = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <div className={styles.nav_home}>
        <img 
          className={styles.logo_home} 
          src="https://d2qtwwg2436q9r.cloudfront.net/va2pt-logo.png" 
          onClick={() => { navigate("/") }} 
        />

        <Drawer
          anchor="top"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          classes={{ paper: styles.drawerPaper }}
        >
          <div className={styles.drawerContent}>
            <IconButton onClick={toggleDrawer(false)} className={styles.closeIcon}>
              <CloseIcon />
            </IconButton>
            <ul className={styles.list_home}>
              <li onClick={() => { navigate("/aboutus"); }}>About us</li>
              <li onClick={() => { navigate("/pricing"); }}>Pricing</li>
              <li onClick={() => { navigate("/contact"); }}>Contact us</li>
              <li onClick={() => { navigate("/login"); }}>Log In</li>
            </ul>
          </div>
        </Drawer>
        {window.innerWidth > 768 && <ul className={styles.list_home}>
              <li onClick={() => { navigate("/aboutus"); }}>About us</li>
              <li onClick={() => { navigate("/pricing"); }}>Pricing</li>
              <li onClick={() => { navigate("/contact"); }}>Contact us</li>
              <li onClick={() => { navigate("/login"); }}>Log In</li>
            </ul>
}
      {window.innerWidth < 768 && <MenuIcon className={styles.menuIcon} onClick={toggleDrawer(true)} /> }  

      </div>
    </>
  );
};

export default NavigationBar;

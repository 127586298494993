import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import CircularProgress from '@mui/material/CircularProgress';
import request from '../utils/requests';
import moment from 'moment';
import styles from "./Integration/Card.module.scss"


const RegionChart = () => {
    const [regionChartData, setRegionChartData] = useState(null);
    const today = moment().format("YYYY-MM-DD");
    const sixMonthsAgo = moment().subtract(6, 'months').format("YYYY-MM-DD");
    const [granularity, setGranularity] = useState('MONTHLY');
    const [dimension, setDimension] = useState('REGION');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await request.get(`/ce/cost_breakdown_by_filter?start_date=${sixMonthsAgo}&end_date=${today}&granularity=${granularity}&dimension=${dimension}`);
                const data = response.data;

                const categories = Object.keys(data);
                let regions = [];

                categories.forEach(date => {
                    regions = regions.concat(Object.keys(data[date]));
                });

                regions = [...new Set(regions)].filter(region => region !== 'total');

                const series = regions.map((region, index) => ({
                    name: region,
                    data: categories.map(date => {
                        const value = parseFloat(data[date][region]);
                        return isNaN(value) ? null : value.toFixed(2); // Format to 2 decimal points
                    }),
                    // color: getCustomColor(index), // Assign custom color
                }));

                setRegionChartData({
                    categories: categories.map(date => moment(date).format('MMM')), // Format dates to months
                    series,
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    const options = {
        chart: {
            type: 'bar',
            stacked: true,
        },
        grid: {
            borderColor: '#535A6C', // Set grid border color
            strokeDashArray: 4, // Set stroke dash array for dotted lines
        },
        dataLabels: {
            enabled: false, // This is the option to disable data labels
          },
        xaxis: {
            categories: regionChartData ? regionChartData.categories : [],
            labels: {
                style: {
                    colors: '#fff', // Set X-axis label color to white
                },
            },
        },
        yaxis: {
            title: {
                text: 'Region Cost',
                style: {
                    color: '#fff', // Set title color to white
                },
            },
            labels: {
                style: {
                    colors: '#fff', 
                },
                // formatter: function (val) {
                //     return regionChartData ? val?.toFixed(0) : val; 
                // }
                formatter: function (val) {
                    return val >= 1000 ? (val / 1000)?.toFixed(0) + 'k' : val?.toFixed(0); // Format values to display as '2k', '3k', etc.
                }
            }
        
        },
        legend: {
            position: 'bottom',
            labels: {
                colors: '#fff', // Set legend label color to white
            },
        },
    };

    return (
        <div className='pie-chart'
         >
            <p className={styles.graphHeading}>Last 6 Months Region-wise Costs</p>

            {regionChartData ? (
                <ApexCharts options={options} series={regionChartData.series} type="bar" height={350} />
            ) : (
                <div className="fixed-loader">
                    <CircularProgress color="inherit" />
                </div>
            )}
        </div>
    );
};

export default RegionChart;

export const showLoading = () => {
    return { 
        type: "showLoading" 
    }
}


export const hideLoading = () => {
    return { 
        type: "hideLoading" 
    }
}
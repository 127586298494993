import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Link, useNavigate ,useLocation  } from "react-router-dom";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { Button, Form } from "react-bootstrap";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Onboardingstep1 from "./Onboardingstep1";
import { Route, Routes } from "react-router-dom";
import Onboardingstep2 from "./Onboardingstep2";
import Onboardingstep3 from "./Onboardingstep3";
import Onboardingstep5 from "./Onboardingstep5";
import Onboardingstep4 from "./Onboardingstep4";
export default function Onboarding() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [onboardingDetails, setOnboardingDetails] = useState({ company: "" });
  let [activeStep, setStep] = useState(1)
  // const dispatch = useDispatch();
  const location = useLocation();


  const onLogout = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('current_user')
    localStorage.removeItem('currencyCode')
    localStorage.removeItem('filters')
    navigate('/')
  }
  console.log("onboard", onboardingDetails?.company)

  const handleNavClick = (targetStep) => {
    // Allow navigation only if going backward or to the current step;  
    if (targetStep <= activeStep) {
      setStep(targetStep);
      if(targetStep===1){
        navigate(`/onboarding/`);
      }else {
        navigate(`/onboarding/step${targetStep}`);
      }
    }
  };
  

  useEffect(() => {
    // Check if the route matches the expected route for the current step
    const expectedRoute = `/onboarding/step${activeStep}`;
    if (window.location.pathname !== expectedRoute) {
      // Redirect to the correct route
      navigate(activeStep==1?"/onboarding":expectedRoute);
    }
  }, [activeStep]);

  
  useEffect(() => {
    // Extract the step from the current location pathname
    const match = location.pathname.match(/\/onboarding\/step(\d+)/);
    const step = match ? parseInt(match[1], 10) : 1;

    // Update the activeStep state
      setStep(step);
  }, [location.pathname]);
 
  function getSteps() {
    return [
      "1. Welcome",
      "2. Choose your Own Adventure",
      "3. Enable Cost and usage report",
      "4. Set up permission",
      "5.Enjoy ",
    ];
  }
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <Onboardingstep1 />;
      case 1:
        return <Onboardingstep2 />;
      case 2:
        return <Onboardingstep3 />;
      case 3:
        return <Onboardingstep4 />;
        case 4:
        return <Onboardingstep5/>;
         
      default:
        return null;
    }
  }

  return (
    <>
      <Box className="main-onboarding">
       
      <div className="sidebar-nav">
      {/* <div className="nav-top">
        <img src={logo} alt="logo.png" />
<hr/>
        </div> */}
          <Nav
            className="flex-column nav-menu-wrap my-5"
          >
            <Nav.Link  onClick={() =>{navigate("/onboarding");handleNavClick(1)}} active={activeStep === 1}
              className={ activeStep > 1 ? 'completed-step' : ''}

            >
              WELCOME 
            </Nav.Link>
            <Nav.Link onClick={() =>{handleNavClick(2)}} active={activeStep === 2}
              className={activeStep > 2 ? 'completed-step' : ''}

            >
              CHOOSE YOUR OWN <br />
              ADVENTURE 
            </Nav.Link>
            <Nav.Link onClick={() =>{handleNavClick(3)}} active={activeStep === 3}
              className={activeStep > 3 ? 'completed-step' : ''}

            >
              ENABLE COST AND <br />
              USAGE REPORT 
            </Nav.Link>
            <Nav.Link onClick={() =>{handleNavClick(4)}} active={activeStep === 4}
             className={activeStep > 4 ? 'completed-step' : ''}

            >
              SETUP PERMISSION 
            </Nav.Link>
            <Nav.Link onClick={() =>{handleNavClick(5)}} active={activeStep === 5}
            className={activeStep > 5? 'completed-step' : ''}

            >
              ENJOY! 
            </Nav.Link>
          </Nav>
          {/* <div className="sidebar-brand">
            <Button onClick={onLogout}>
              <RiLogoutCircleRLine className="me-2" />
              LOG OUT
            </Button>
          </div> */}
        </div>
        <div className="onboarding-content">
          <Routes>
            <Route path="/"
            element={
              <Onboardingstep1 onboardingDetails={onboardingDetails} setOnboardingDetails={setOnboardingDetails} handleNavClick={handleNavClick} setStep={setStep}/>
            }/>
              <Route path="/step2"
            element={
              <Onboardingstep2 onboardingDetails={onboardingDetails} setOnboardingDetails={setOnboardingDetails} handleNavClick={handleNavClick} setStep={setStep}/>
            }/>
            <Route path="/step3"
            element={<Onboardingstep3 onboardingDetails={onboardingDetails} setOnboardingDetails={setOnboardingDetails} handleNavClick={handleNavClick} setStep={setStep}/>} />
             <Route path="/step4"
            element={<Onboardingstep4 onboardingDetails={onboardingDetails} setOnboardingDetails={setOnboardingDetails} handleNavClick={handleNavClick} setStep={setStep}/>} />
             <Route path="/step5"
            element={<Onboardingstep5 onboardingDetails={onboardingDetails} handleNavClick={handleNavClick} setStep={setStep}/>} />
          </Routes> 
          </div>
      </Box>
    </>
  );
};





import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import { MenuItem, ProSidebarProvider } from 'react-pro-sidebar';
import { CssBaseline, ThemeProvider, Box, Backdrop } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Login from './pages/Login';
import Reports from './pages/Reports';
import VerifyOtp from './pages/VerifyOtp';
import Onboarding from './pages/Onboarding';
import Dashboard from './pages/Dashboard';
import DashboardList from './pages/DashboardList';
import SidebarComponent from './components/SideBar';
import PageNotFound from './pages/NotFound';
import Topbar from './components/Topbar';
import Alerts from './pages/Alerts';
import Invites from './pages/Invites';
import UniversalTable from './pages/UniversalTable';
import { useLocation } from 'react-router-dom';
import { Grid } from 'react-loader-spinner'
import { useSelector } from 'react-redux';
import AuthGuard from './utils/auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TimeBasedScheduling from './pages/TimeBasedScheduling';
import RecommendationsGraph from './pages/RecommendationsGraph';
import RecommendationDetails from './pages/RecommendationDetails';
import Recommendations from './pages/Recommendations';
import ErrorLogs from './pages/ErrorLogs';
import Profile from "./pages/Profile";
import { useDispatch } from "react-redux";
import Accounts from './pages/Accounts/Accounts';
import Jobshandle from './pages/Jobs/Jobhandle';
import Slack from './pages/Integration/Slack';
import Jira from './pages/Integration/Jira';
import Email from './pages/Integration/Email';
import SummaryDashboard from './pages/SummaryDashboard';
import SecurityDashboard from './pages/Security/SecurityDashboard';
import Home from "./pages/Home/Home"
import Pricing from './pages/Pricing/Pricing';
import Contact from "./pages/Contact/Contact"
import Aboutus from './pages/About/Aboutus';
import 'animate.css';
import ResourceTracker from './pages/ResourceTracker/ResourceTracker';
import RecommendationMemoryDetail from './pages/RecommendationMemoryDetail';
import Scheduleinstancelogs  from './pages/Auditlogs';
import Auditlogs from './pages/Actionlogs/Actionlogs';
import Scheduleinstancedrawer from './pages/Scheduleinstancedrawer';
import JiraLogs from './pages/Integration/Jiralogs';



const App = () => {

  const location = useLocation();
  const [theme, colorMode] = useMode();
  const [showSidebar, setShowSidebar] = useState(false);
  const [modalHandler,setModlaHandler] = useState(false);
  const [tabs,setTabs]=useState(0)
  const [globalemail,setGlobalEmail]=useState("")
  const [isAdmin, setIsAdmin] = useState(false);


  const state = useSelector((state) => state)
  const routes = ['dashboard', 'reports', 'tables', 'alerts', 'invites', 'timeBasedScheduling', 'recommendations','error-logs','recommendations','profile','accounts','jobshandle','slack','jira' , 'jiralogs','email','detailDashboard','summaryDashboard','securityDashboard','resourceTracker' ,'recommendationmemory','actionlogs' , 'auditlogs' , 'Scheduleinstancedrawer']
  

  useEffect(() => {
    if (routes.indexOf(location.pathname.split('/')[1]) === -1) {
      setShowSidebar(false)
    } else {
      setShowSidebar(true)
    }
    setModlaHandler(false);
  }, [location]);

  
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ProSidebarProvider>
          <Backdrop
            sx={{ background: 'rgba(255,255,255,.2)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={state.isLoading}
          >
            <Grid
              color="#1672EC"
              width="200"
              visible={true}
              ariaLabel="grid-loading"
              radius="12.5"
            />
          </Backdrop>
          <ToastContainer position="top-right" autoClose={5000} hideProgressBar newestOnTop closeOnClick={false} rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover theme="light" />
          <Box height={'100%'} width={'100%'} sx={{ display: 'flex'}} className="main-app"
  >
            {showSidebar && (<SidebarComponent isAdmin={isAdmin} setIsAdmin={setIsAdmin} />)}
            <Box width={'100%'} sx={{ overflow: 'hidden',maxWidth: modalHandler ? "1260px":"100%",position:"relative" }} >

              {showSidebar && (<Topbar isAdmin={isAdmin} setIsAdmin={setIsAdmin} />)}
              <Routes >
                <Route path="/login" element={<Login/>} />
                <Route path="/aboutus" element={<Aboutus/>} />
                <Route path="/" element={<Home />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/verify-otp/:token?" element={<VerifyOtp setTabs={setTabs} />} />
                <Route path="/onboarding/*" element={AuthGuard(<Onboarding />)} />
                <Route path="/dashboard" element={AuthGuard(<DashboardList  isAdmin={isAdmin} setIsAdmin={setIsAdmin} 
              />)} />
              <Route path="/dashboard/:id" element={AuthGuard(<Dashboard  isAdmin={isAdmin} setIsAdmin={setIsAdmin}  />)} />
              <Route path="/dashboard/:id" element={AuthGuard(<DashboardList 
              />)} />
                <Route path="/recommendations/:type" element={AuthGuard(<Recommendations />)} />
                <Route path="/recommendations/:type/:id" element={AuthGuard(<RecommendationDetails />)} />
                <Route path="/reports" element={AuthGuard(<Reports />)} />
                <Route path="/profile" element={AuthGuard(<Profile />)} />
                <Route path="/actionlogs" element={AuthGuard(<Auditlogs />)} />

                <Route path="/tables" element={AuthGuard(<UniversalTable />)} />
                <Route path="/alerts" element={AuthGuard(<Alerts />)} />
                <Route path="/auditlogs" element={AuthGuard(<Scheduleinstancelogs />)} />

                <Route path="/accounts" element={AuthGuard(<Accounts />)} />
                <Route path="/scheduleinstancedrawer/:id/:type" element={AuthGuard(<Scheduleinstancedrawer />)} />
                <Route path="/resourceTracker" element={AuthGuard(<ResourceTracker />)} />
                <Route path="/recommendationmemory" element={AuthGuard(<RecommendationMemoryDetail />)} />

                {/* <Route path="/whatsapp" element={AuthGuard(<WhatsApp />)} /> */}
                <Route path="/summaryDashboard" element={AuthGuard(<SummaryDashboard />)} />
                <Route path="/securityDashboard" element={AuthGuard(<SecurityDashboard />)} />

                <Route path="/jobshandle" element={AuthGuard(<Jobshandle />)} />
                <Route path="/slack" element={AuthGuard(<Slack />)} />
                <Route path="/jira" element={AuthGuard(<Jira />)} />
                <Route path="/jiralogs" element={AuthGuard(<JiraLogs />)} />

                <Route path="/email" element={AuthGuard(<Email />)} />

                <Route path="/invites" element={AuthGuard(<Invites setModlaHandler={setModlaHandler} modalHandler={modalHandler} tabs={tabs} setTabs={setTabs}/>)} />
                <Route path="/recommendations" element={AuthGuard(<RecommendationsGraph />)} />
                <Route path="/recommendations/:id" element={AuthGuard(<RecommendationsGraph />)} />

                <Route path="/error-logs" element={AuthGuard(<ErrorLogs />)} />
                <Route path="/timeBasedScheduling" element={AuthGuard(<TimeBasedScheduling />)} />
                <Route path='*' exact={true} element={<PageNotFound />} />
              </Routes>
            </Box>
          </Box>
        </ProSidebarProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}


export default App;
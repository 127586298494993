import React, { useEffect } from "react";
import { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

import TableViewIcon from "@mui/icons-material/TableView";
import PersonIcon from "@mui/icons-material/Person";
import { RiLogoutCircleRLine } from "react-icons/ri";
import RecommendIcon from "@mui/icons-material/Recommend";
import ReportIcon from "@mui/icons-material/Report";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Dashboard, BarChart } from "@mui/icons-material";
import SystemSecurityUpdateWarningIcon from "@mui/icons-material/SystemSecurityUpdateWarning";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Jiralogs from "../assets/jiralogs.png"

export default function SidebarComponent({ isAdmin, setIsAdmin }) {
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const arr = window.location.href.split("/");
  const [selected, setSelected] = useState();
  const [integration, setIntegration] = useState(false);
  const [logs, setLogs] = useState(false);
  const [dashboard, setDashboard] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [setting, setSetting] = useState(false);
  const[administration,setAdministration]=useState(false)

  // const isAdmin = globalemail.includes("@va2pt.com");

  const onLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("current_user");
    localStorage.removeItem("currencyCode");
    localStorage.removeItem("filters");
    navigate("/");
  };

  useEffect(() => {
    setSelected(arr[arr.length - 1]);
  }, [arr]);

  const handleIntegration = () => {
    setIntegration((prevState) => !prevState);
  };
  const handleLogs = () => {
    setLogs((prevState) => !prevState);
  };
  const handleDashboard = () => {
    setDashboard((prevState) => !prevState);
  };
  const handleSetting = () => {
    setSetting((prevState) => !prevState);
  };

  const handleAdministration = () => {
    setAdministration((prevState) => !prevState);
  };
  return (
    <Box
      className="dashboard-sidebar"
      sx={{
        "& .ps-sidebar-root": {
          height: "100%",
        },
        "& .ps-sidebar-container": {
          // background: `${colors.grey[900]} !important`,
          color: `rgba(0, 0, 0, 0.87)`,
          background: `linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)`,
        },
        "& .ps-menu-button:hover": {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: "transparent !important",
        },
        "& .ps-active": {
          color: `${colors.blueAccent[500]} !important`,
        },
      }}
    >
      <Sidebar collapsed={isCollapsed} width={isCollapsed ? "80px" : "270px"}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={
              isCollapsed ? (
                <MenuOutlinedIcon
                  onClick={() => setIsCollapsed(!isCollapsed)}
                />
              ) : undefined
            }
            style={{
              margin: "10px 0 20px 0",
              // color: colors.grey[100],
              color: "#fff",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography
                  variant="h3"
                  // color={colors.grey[100]}
                >
                  VA2PT
                </Typography>
                <IconButton>
                  <MenuOutlinedIcon style={{ color: "#fff" }} />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          <Box>
            <MenuItem
              active={selected === "dashboard"}
              style={{
                color: "#fff",
              }}
              onClick={() => {
                setSelected("dashboard");
                setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown state
              }}
              icon={<HomeOutlinedIcon />}
              // component={<Link to="dashboard" />}
            >
              {!isCollapsed && (
                <Typography variant="h5" onClick={handleDashboard}>
                  Dashboard
                  {isDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Typography>
              )}{" "}
            </MenuItem>
            {dashboard && (
              <>
                <MenuItem
                  active={selected === "summaryDashboard"}
                  style={{
                    color: "#fff",
                    marginLeft: "10px",
                  }}
                  onClick={() => setSelected("summaryDashboard")}
                  icon={<BarChart />}
                  component={<Link to="summaryDashboard" />}
                >
                  {!isCollapsed && (
                    <Typography variant="h5">
                      {" "}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ marginTop: "12px" }}>Summary Dashboard</p>
                      </div>
                    </Typography>
                  )}
                </MenuItem>
                <MenuItem
                  active={selected === "securityDashboard"}
                  style={{
                    color: "#fff",
                    marginLeft: "10px",
                  }}
                  onClick={() => setSelected("securityDashboard")}
                  icon={<SystemSecurityUpdateWarningIcon />}
                  component={<Link to="securityDashboard" />}
                >
                  {!isCollapsed && (
                    <Typography variant="h5">
                      {" "}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ marginTop: "12px" }}>Security Dashboard</p>
                      </div>
                    </Typography>
                  )}
                </MenuItem>
               
              </>
            )}
            <MenuItem
              active={selected === "settings"}
              style={{
                color: "#fff",
              }}
              onClick={() => {
                setSelected("setting");
                setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown state
              }}
              icon={<SettingsIcon />}
              // component={<Link to="settings" />}
            >
              {!isCollapsed && (
                <Typography variant="h5" onClick={handleSetting}>
                  Settings
                  {isDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Typography>
              )}
            </MenuItem>
            {setting && (
              <>
                <MenuItem
                  active={selected === "profile"}
                  style={{
                    color: "#fff",
                    marginLeft: "10px",
                  }}
                  onClick={() => setSelected("profile")}
                  icon={<PersonIcon />}
                  component={<Link to="profile" />}
                >
                  {!isCollapsed && (
                    <Typography variant="h5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2px",
                        }}
                      >
                        <p style={{ marginTop: "12px" }}>Profile</p>
                      </div>
                    </Typography>
                  )}
                </MenuItem>

                <MenuItem
                  active={selected === "accounts"}
                  style={{
                    color: "#fff",
                    marginLeft: "10px",
                  }}
                  onClick={() => setSelected("accounts")}
                  icon={<AccountCircleIcon />}
                  component={<Link to="accounts" />}
                >
                  {!isCollapsed && (
                    <Typography variant="h5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2px",
                        }}
                      >
                        <p style={{ marginTop: "12px" }}>Accounts</p>
                      </div>{" "}
                    </Typography>
                  )}
                </MenuItem>
              </>
            )}

            {isAdmin && (
              <>
               <MenuItem
              active={selected === "administration"}
              style={{
                color: "#fff",
              }}
              onClick={() => {
                setSelected("administration");
                setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown state
              }}
              icon={<AdminPanelSettingsIcon/>}
              // component={<Link to="settings" />}
            >
              {!isCollapsed && (
                <Typography variant="h5" onClick={handleAdministration}>
                  Administration
                  {isDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Typography>
              )}
            </MenuItem>
{administration && (
  <>
             <MenuItem
                  active={selected === "tables"}
                  style={{
                    color: "#fff",
                    marginLeft: "10px",

                  }}
                  onClick={() => setSelected("tables")}
                  icon={<TableViewIcon />}
                  component={<Link to="tables" />}
                >
                  {!isCollapsed && <Typography variant="h5">Tables</Typography>}
                </MenuItem>

                <MenuItem
                  active={selected === "jobshandle"}
                  style={{
                    color: "#fff",
                    marginLeft: "10px",

                  }}
                  onClick={() => setSelected("jobshandle")}
                  icon={<AccountCircleIcon />}
                  component={<Link to="jobshandle" />}
                >
                  {!isCollapsed && (
                    <Typography variant="h5">Jobs handle</Typography>
                  )}
                </MenuItem>

                  <MenuItem
                  active={selected === "detailDashboard"}
                  style={{
                    color: "#fff",
                    marginLeft: "10px",
                  }}
                  onClick={() => setSelected("detailDashboard")}
                  icon={<Dashboard />}
                  component={<Link to="dashboard" />}
                >
                  {!isCollapsed && (
                    <Typography variant="h5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2px",
                        }}
                      >
                        <p style={{ marginTop: "12px" }}>Detail Dashboard</p>
                      </div>
                    </Typography>
                  )}
                </MenuItem> 

                <MenuItem
              active={selected === 'resourceTracker'}
              style={{
                color:"#fff",
                marginLeft: "10px",

              }}
              onClick={() => setSelected('resourceTracker')}
              icon={<ReportIcon />}
              component={<Link to="resourceTracker" />}
            >
              {!isCollapsed && <Typography variant="h5" >Resource Tracker</Typography>}
            </MenuItem>
              </>
            )}
              </>
)}
   
            {/* 
            <MenuItem

              active={selected === 'reports'}
              style={{
                color:"#fff"

              }}
              onClick={() => setSelected('reports')}
              icon={<AssessmentIcon />}
              component={<Link to="reports" />}
            >
              {!isCollapsed && <Typography variant="h5" >Reports</Typography>}
            </MenuItem>
            <MenuItem

              active={selected === 'alerts'}
              style={{
                color:"#fff"

              }}
              onClick={() => setSelected('alerts')}
              icon={<AddAlertIcon />}
              component={<Link to="alerts" />}
            >
              {!isCollapsed && <Typography variant="h5" >Alerts</Typography>}
            </MenuItem> */}
            {/* <MenuItem
              active={selected === "timeBasedScheduling"}
              style={{
                color: "#fff",
              }}
              onClick={() => setSelected("timeBasedScheduling")}
              icon={<AccessAlarmIcon />}
              component={<Link to="timeBasedScheduling" />}
            >
              {!isCollapsed && (
                <Typography variant="h5">Time-based Scheduling</Typography>
              )}
            </MenuItem> */}
            {/* <MenuItem
              active={selected === 'error-logs'}
              style={{
                color:"#fff"
              }}
              onClick={() => setSelected('error-logs')}
              icon={<ReportIcon />}
              component={<Link to="error-logs" />}
            >
              {!isCollapsed && <Typography variant="h5" >Error Logs</Typography>}
            </MenuItem> */}
            <MenuItem
              active={selected === "recommendations"}
              style={{
                color: "#fff",
              }}
              onClick={() => setSelected("recommendations")}
              icon={<RecommendIcon />}
              component={<Link to="recommendations" />}
            >
              {!isCollapsed && (
                <Typography variant="h5">Recommendations</Typography>
              )}
            </MenuItem>
            
          
            <MenuItem
              active={selected === "logs"}
              style={{
                color: "#fff",
              }}
              onClick={() => {
                setSelected("logs");
                setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown state
              }}
              icon={<ListAltIcon />}
            >
              {!isCollapsed && (
                <Typography variant="h5" onClick={handleLogs}>
                  Activities
                  {isDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Typography>
              )}
            </MenuItem>
            {logs && (
              <>
                <MenuItem
              active={selected === "actionlogs"}
              style={{
                color: "#fff",
                marginLeft: "10px",

              }}
              onClick={() => setSelected("actionlogs")}
              icon={<CircleNotificationsIcon />}
              component={<Link to="actionlogs" />}
            >
              {!isCollapsed && (
                <Typography variant="h5"> Events</Typography>
              )}
            </MenuItem>
   <MenuItem
              active={selected === "auditlogs"}
              style={{
                color: "#fff",
                marginLeft: "10px",

              }}
              onClick={() => setSelected("auditlogs")}
              icon={<NotificationImportantIcon />}
              component={<Link to="auditlogs" />}
            >
              {!isCollapsed && (
                <Typography variant="h5"> Cloud Activity</Typography>
              )}
            </MenuItem>

            <MenuItem
                  active={selected === "jiralogs"}
                  style={{
                    color: "#fff",
                    marginLeft: "10px",
                  }}
                  onClick={() => setSelected("jiralogs")}
                  component={<Link to="jiralogs" />}
                >
                  {!isCollapsed && (
                    <Typography variant="h5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{
                            width: "20px",
                            marginLeft:"5px",
                            marginRight: "24px",
                            background: "transparent",
                          }}
                          src={Jiralogs}
                        />
                        <p style={{ marginTop: "12px" }}>Jira tickets</p>
                      </div>
                    </Typography>
                  )}
                </MenuItem>
              </>
            )}

            <MenuItem
              active={selected === "integration"}
              style={{
                color: "#fff",
              }}
              onClick={() => {
                setSelected("integration");
                setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown state
              }}
              icon={<HomeOutlinedIcon />}
            >
              {!isCollapsed && (
                <Typography variant="h5" onClick={handleIntegration}>
                  Integration
                  {isDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Typography>
              )}
            </MenuItem>
            {integration && (
              <>
                <MenuItem
                  active={selected === "slack"}
                  style={{
                    color: "#fff",
                    marginLeft: "10px",
                  }}
                  onClick={() => setSelected("slack")}
                  component={<Link to="slack" />}
                >
                  {!isCollapsed && (
                    <Typography variant="h5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2px",
                        }}
                      >
                        <img
                          style={{
                            width: "20px",
                            marginLeft: "9px",
                            marginRight: "25px",
                            background: "transparent",
                          }}
                          src="https://d2qtwwg2436q9r.cloudfront.net/communication.png"
                        />
                        <p style={{ marginTop: "12px" }}>Slack</p>
                      </div>
                    </Typography>
                  )}
                </MenuItem>
                <MenuItem
                  active={selected === "jira"}
                  style={{
                    color: "#fff",
                    marginLeft: "10px",
                  }}
                  onClick={() => setSelected("jira")}
                  component={<Link to="jira" />}
                >
                  {!isCollapsed && (
                    <Typography variant="h5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2px",
                        }}
                      >
                        <img
                          style={{
                            width: "20px",
                            marginLeft: "9px",
                            marginRight: "25px",
                            background: "transparent",
                          }}
                          src="https://d2qtwwg2436q9r.cloudfront.net/jira.png"
                        />
                        <p style={{ marginTop: "12px" }}>Jira</p>
                      </div>
                    </Typography>
                  )}
                </MenuItem>

               
                <MenuItem
                  active={selected === "email"}
                  style={{
                    color: "#fff",
                    marginLeft: "10px",
                  }}
                  onClick={() => setSelected("email")}
                  component={<Link to="email" />}
                >
                  {!isCollapsed && (
                    <Typography variant="h5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2px",
                        }}
                      >
                        <img
                          style={{
                            width: "20px",
                            marginLeft: "9px",
                            marginRight: "25px",
                            background: "transparent",
                          }}
                          src="https://d2qtwwg2436q9r.cloudfront.net/gmail.png"
                        />
                        <p style={{ marginTop: "12px" }}>Email</p>
                      </div>
                    </Typography>
                  )}
                </MenuItem>

                <MenuItem
                  active={selected === "va2pt-agent"}
                  style={{
                    color: "#fff",
                    marginLeft: "10px",
                  }}
                  onClick={() => setSelected("va2pt-agent")}
                  component={<Link to="recommendationmemory" />}
                  icon={<ManageAccountsIcon />}

                >
                  {!isCollapsed && (
                    <Typography variant="h5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2px",
                        }}
                      >
                        <p style={{ marginTop: "12px" }}>Va2pt Agent</p>
                      </div>
                    </Typography>
                  )}
                </MenuItem>

                {/* <MenuItem
                  active={selected === "whatsapp"}
                  style={{
                    color: "#fff",
                    marginLeft: "10px",

                  }}
                  onClick={() => setSelected("whatsapp")}
                  component={<Link to="whatsapp" />}
                >
                  {!isCollapsed && (
                    <Typography variant="h5">
                      {" "}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          style={{
                            width: "20px",
                            marginLeft: "9px",
                            marginRight: "25px",
                          }}
                          src={whatsApp}
                        />
                        <p style={{ marginTop: "12px" }}>WhatsApp</p>
                      </div>
                    </Typography>
                  )}
                </MenuItem> */}
              </>
            )}
           
            
            <MenuItem
              active={selected === "logout"}
              style={{
                color: "#fff",
                margin:"15px 0px"
              }}
              icon={<RiLogoutCircleRLine />}
            >
              {!isCollapsed && (
                <Typography variant="h5">                 
                 <Button onClick={onLogout}>LOG OUT</Button>
              </Typography>
              )}
            </MenuItem>
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import styles from "./Profile.module.scss";
import request from "../utils/requests";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const Profile = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [settings, setSettings] = useState(null);


  const fetchData = async () => {
    try {
      const profileResponse = await request.get(`users/profile_list/`);
      if (profileResponse.data.length > 0) {
        setProfileData(profileResponse.data[0]);
      }
    } catch (error) {
      toast.error("Error fetching profile data");
    }
  };

  const handleChange = (key, value) => {
    const updatedSettings = { ...settings, [key]: value };
    setSettings(updatedSettings);
  };

  const handleToggleChange = (key, value) => {
    const updatedSettings = { ...settings, [key]: value };
    setSettings(updatedSettings);
    handleSaveSetting(key, value);
  };


  useEffect(() => {
    fetchData();
    fetchSettings();
  }, []);

  useEffect(() => {
    if (settings !== null) {
      saveSettingsToDB();
    }
}, []);

  const fetchSettings = async () => {
    try {
      const settingsResponse = await request.get(`settings/setting_list/`);
      const settingsData = settingsResponse.data.reduce((acc, setting) => {
        acc[setting.key] = setting.value;
        return acc;
      }, {});
      setSettings(settingsData);
    } catch (error) {
      toast.error("Error fetching settings data");
    }
  };

  const handleSaveSetting = async (key, value) => {
    try {
      const response = await request.put(`settings/update/`, {
        key,
        value: value.toString(), // Convert value to string if necessary
      });
      if (response.status === 201 || response.status === 200) {
        toast.success(response.data.detail);
      }
    } catch (error) {
      toast.error("An internal server error occurred");
    }
  };

  const saveSettingsToDB = async () => {
    try {
      const payload = Object.entries(settings).map(([key, value]) => ({
        key,
        value: value,
      }));
      await request.post(`settings/create/`, payload);
    } catch (error) {
      // toast.error("An internal server error occurred");
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.top}>
        <div className={styles.logo}>Va2pt</div>
        <div>
          {profileData && (
            <>
              <p className={styles.profilen}>{profileData.name}</p>
              <p className={styles.profilee}>{profileData.email}</p>
            </>
          )}
        </div>
        <div>
          <button className={styles.button}>Overview</button>
          <button className={styles.button}>Invite</button>
          <button
            className={styles.button}
            onClick={() => {
              navigate("/accounts");
            }}
          >
            Account
          </button>
        </div>
      </div>

      <div className={styles.medium}>
        <div className={styles.welcome}>
          <img
            src="https://d2qtwwg2436q9r.cloudfront.net/welcome-profile.png"
            alt="Welcome"
          />
          <h3 className={styles.wheading}>Welcome back!</h3>
          <p className={styles.secondaryHeading}>Nice to see you</p>
        </div>
        {profileData && (
          <div className={styles.profile}>
            <h3 className={styles.heading}>Profile Information</h3>
            <div className={styles.middlesection}>
              <p className={styles.list}>Full Name</p>
              <p className={styles.list2}>{profileData.name}</p>
            </div>
            <div className={styles.middlesection}>
              <p className={styles.list}>Email</p>
              <p className={styles.list2}>{profileData.email}</p>
            </div>
            <div className={styles.middlesection}>
              <p className={styles.list}>Company LinkedIn URL</p>
              <p className={styles.list2}>{profileData.company}</p>
            </div>
            <div className={styles.middlesection}>
              <p className={styles.list}>IAM Role ARN</p>
              <p className={styles.list2}>{profileData.arn}</p>
            </div>
            <div className={styles.middlesection}>
              <p className={styles.list}>AWS Account ID</p>
              <p className={styles.list2}>{profileData.account_id}</p>
            </div>
          </div>
        )}

        {settings && (
          <div className={styles.platform}>
            <h3 className={styles.heading}>Platform settings</h3>
            {Object.entries(settings)
              .filter(
                ([key]) =>
                  key !== "Minimum amount for slack notifications" &&
                  key !==
                    "Minimum EC2 CPU percentage for recommendation over slack" &&
                    key !== "Jira auto follow up" && 
                    key !== "Slack auto follow up" && 
                    key !== "Email auto follow up" && 
                    key !== "Summary Dashboard" 

              )
              .map(([key, value]) => (
                <div className="form-check form-switch" key={key}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id={key}
                    checked={value === "True"}
                    onChange={(e) =>
                      handleToggleChange(key, e.target.checked ? "True" : "False")
                    }
                  />
                  <p className={styles.lists}>{key}</p>
                </div>
              ))}

            {/* Minimum Amount for Slack Notifications */}
            <hr />
            <p className={styles.alert}>
              Minimum Amount for Slack Notifications
            </p>
            <div>
              <input
                className={styles.input}
                type="text"
                name="minimumAlert"
                value={settings["Minimum amount for slack notifications"]}
                onChange={(e) =>
                  handleChange(
                    "Minimum amount for slack notifications",
                    e.target.value
                  )
                }
              />
              <button
                className={styles.buttonp}
                onClick={() =>
                  handleSaveSetting(
                    "Minimum amount for slack notifications",
                    settings["Minimum amount for slack notifications"]
                  )
                }
              >
                Save
              </button>
            </div>

            {/* Minimum EC2 CPU Percentage for Recommendations */}
            <hr />
            <p className={styles.alert}>
              Minimum EC2 CPU Percentage for Recommendations Over Slack
            </p>
            <div>
              <input
                className={styles.input}
                type="number"
                name="minimumCpu"
                value={
                  settings[
                    "Minimum EC2 CPU percentage for recommendation over slack"
                  ]
                }
                onChange={(e) =>
                  handleChange(
                    "Minimum EC2 CPU percentage for recommendation over slack",
                    e.target.value
                  )
                }
              />
              <button
                className={styles.buttonp}
                onClick={() =>
                  handleSaveSetting(
                    "Minimum EC2 CPU percentage for recommendation over slack",
                    settings[
                      "Minimum EC2 CPU percentage for recommendation over slack"
                    ]
                  )
                }
              >
                Save
              </button>
            </div>
          </div>
        )}
      </div>
      <div className={styles.autofollow}>
      <h3 className={styles.heading}>Auto follow up settings</h3>
      <div className={styles.autobox}>

      { settings && Object.entries(settings)
      .filter(
        ([key]) =>
          key === "Email auto follow up" ||
          key === "Slack auto follow up" ||
          key === "Jira auto follow up"
      )
      .map(([key, value]) => (
        <div key={key}>
          {/* Email */}
          {key === "Email auto follow up" && (
            <>
              <p className={styles.apiKey}>
                <img
                  className={styles.imglogo}
                  src="https://d2qtwwg2436q9r.cloudfront.net/gmail.png"
                  alt="Email"
                />
                Email
              </p>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id={key}
                  checked={value === "True"}
                  onChange={(e) =>
                    handleToggleChange(key, e.target.checked ? "True" : "False")
                  }
                />
                <p className={styles.list2}>Email me after 14 days</p>
              </div>
            </>
          )}

          {/* Slack */}
          {key === "Slack auto follow up" && (
            <>
              <p className={styles.apiKey}>
                <img
                  className={styles.imglogo}
                  src="https://d2qtwwg2436q9r.cloudfront.net/communication.png"
                  alt="Slack"
                />
                Slack
              </p>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id={key}
                  checked={value === "True"}
                  onChange={(e) =>
                    handleToggleChange(key, e.target.checked ? "True" : "False")
                  }
                />
                <p className={styles.list2}>Slack me after 7 days</p>
              </div>
            </>
          )}

          {/* Jira */}
          {key === "Jira auto follow up" && (
            <>
              <p className={styles.apiKey}>
                <img
                  className={styles.imglogo}
                  src="https://d2qtwwg2436q9r.cloudfront.net/jira.png"
                  alt="Jira"
                />
                Jira
              </p>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id={key}
                  checked={value === "True"}
                  onChange={(e) =>
                    handleToggleChange(key, e.target.checked ? "True" : "False")
                  }
                />
                <p className={styles.list2}>Jira</p>
              </div>
            </>
          )}
        </div>
      ))}
      </div>
    </div>
    </div>
  );
};

export default Profile;



import React, {useEffect,useState}from 'react';
import { toast } from 'react-toastify';
import request from "../../utils/requests";
import style from "../Security/Security.module.scss"



const Checkorder = () => {
   const [details, setDetails] = useState('');
   const [filteredDetails, setFilteredDetails] = useState(null);
   const [selectedSeverity, setSelectedSeverity] = useState(null);

   const fetchData = async () => {
     try {
         const response = await request.get(`/prowler/findings_check_ordered_by_severity/`);
         setDetails(response.data);
         setFilteredDetails(response.data); // Initialize filteredDetails with all details initially

   
     } catch (error) {
         toast.error("Error fetching data");

     }
   
   };
   
   useEffect(() => {
   
     fetchData();
   }, []);


const handleFilter = (severity) => {
  setSelectedSeverity(severity);
  if (severity === '') {
      // If no severity is selected, show all details
      setFilteredDetails(details);
  } else {
      // Filter details based on severity
      const filteredData = details.filter(data => data.severity === severity);
      setFilteredDetails(filteredData);
  }
};

  return (
    <div className={`${style.SeverityTable} ${style.checkTitle}`}>
         <p className={style.tableHeading}>Finding by severity</p>
         <div className={style.service_table_outer}> 

    <table cellPadding="10px" width="100%" className={style.table}>
      <thead className={style.heading}>
       <th>Check Title</th>
         <th>Check Type</th>
         <th>Count Of Resource</th>
         
         {/* <th>Severity</th> */}
         <th>
         <div className={style.filterDropdown}>
                {/* <label htmlFor="severityFilter">Severity </label> */}
                <select className={style.Input} id="severityFilter" value={selectedSeverity} onChange={(e) => handleFilter(e.target.value)}>
                    <option className={style.option} value="">All</option>
                    <option className={style.option} value="critical">Critical</option>
                    <option className={style.option} value="high">High</option>
                    <option className={style.option} value="medium">Medium</option>
                    <option className={style.option} value="low">Low</option>
                </select>
            </div>
         </th>
      </thead>
         
           <tbody className={style.tbody}>
        {/* {details?.length && details?.map((data, index) => ( */}
           {filteredDetails?.map((data, index) => (

          <tr
            key={index}>
            <td>{data.check_title}</td>
            <td>{data.check_type}</td>
            <td>{data.count_of_resource}</td>
            <td>
                <div className={`${data.severity === 'critical' ? style.criticalb : data.severity === 'medium' ? style.mediumb : data.severity === 'low' ? style.lowb : data.severity === 'high' ? style.highb : ''}`}>
                  {data.severity}
                </div>
              </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
 
  </div>
  )
}

export default Checkorder
import React,{useState} from 'react'
import styles from "./home.module.scss";
import cardData1 from './cardData1';
import Card from './Card';
import Card2 from './Card2';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import cardData2 from './cardData2';
import NavigationBar from '../NavigationBar';




const Home = () => {


  const navigate = useNavigate()
 
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,       
      autoplaySpeed: 3000,
    };

   
  
  return (
    <>
    <div className={styles.mainBody} >
    <div className={styles.body}>
      <NavigationBar/>
            </div>

            <div className={`${styles.secondSection} animate__animated animate__slideInLeft`}>
              <div className={styles.content}>
              <div className={styles.mainHeading}>  
              We improve your cloud financial processes of any business from 
             </div>
              <div className={styles.minutes}> crawl,walk,and run.
             </div>
              <p>
              Our mission is to empower technology companies to scale their Cloud FinOps processes,build sustainability by bringing financial observability, identify leaky buckets, and optimize their cloud expenditures.
                            </p>
              <button className={styles.started} onClick={() => {
              navigate("/login");
            }} >Get started</button>
              <button className={styles.demo} onClick={() => {
              navigate("/contact");
            }} >Book a demo</button>
              </div>
              <div className={styles.homeimage}>
              <img 
              src="https://d2qtwwg2436q9r.cloudfront.net/Home+page-01.png"
              className={styles.homeimg}/>
              </div>
              </div>

             
             <div className={`${styles.collaborationsection} animate__animated animate__heartBeat`}>
             <p className={styles.collabheading}>Our collaboration</p>
             <div className={styles.logosimage}>
             <img src='https://static.wixstatic.com/media/4ecd90_1d4a12ca67ae46e99b25e04104b45bf3~mv2.png/v1/fill/w_155,h_103,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/tira-bw.png'/>
             
             <img src='https://static.wixstatic.com/media/4ecd90_1e3b88858b7945569afbbfe07be54cbf~mv2.png/v1/fill/w_155,h_103,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/clovia-BW_edited.png'/>

             <img src='https://static.wixstatic.com/media/4ecd90_14ce036c295c49f79e4bf8ed94e4379b~mv2.png/v1/fill/w_176,h_110,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/redcliffelabs-BW.png' />

             <img src='https://static.wixstatic.com/media/4ecd90_44fc10d4cba647de9b1b9455ca04703c~mv2.png/v1/fill/w_155,h_103,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/fynd-BW.png' />
             
             <img src='https://static.wixstatic.com/media/4ecd90_172f2735de654512a2d23bdbefefc62b~mv2.png/v1/fill/w_155,h_103,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Aaj_tak-BW.png' />

             <img src='https://static.wixstatic.com/media/4ecd90_2ee8586664fc4e27bd129bc44303fbff~mv2.png/v1/fill/w_166,h_110,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/BeatO-BW.png'/>

             <img src='https://static.wixstatic.com/media/4ecd90_2eba7e05c9c047dbb1fd824f739e6114~mv2.png/v1/fill/w_155,h_110,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ace-cloud-hosting-BW.png'/>

             <img src='https://static.wixstatic.com/media/4ecd90_ae1b66a304f0499ca39977ac8578a059~mv2.png/v1/fill/w_166,h_110,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Shiprocket-BW.png'/>
             </div>
             </div>

              <div className={`${styles.fourthSection} animate__animated animate__slideInLeft`}>
              <div className={styles.image}>
              <img 
              src="https://d2qtwwg2436q9r.cloudfront.net/Implemented+instance.png"
              className={styles.websiteimg}/>
              </div>
              <div className={styles.content}>
              <div className={styles.mainHeading}>  Implemented 6 Pillars of Cloud Well-Architected Framework.
             </div>

              <p>
              Our in-house tools can scan and generate reports on 6 pillars of the cloud, which summarize the operations, financial, security, and performance enhancement with appropriate explanations of why that change needs to be done architecture-wise and follow industry standards like CIS, NIST, etc.</p>
              <button className={styles.started} onClick={() => {
              navigate("/login");
            }} >Get started</button>
 <button className={styles.demo} onClick={() => {
              navigate("/contact");
            }} >Book a demo</button>              </div>
          
              </div>
              <p className={styles.collabheading}>Our services</p>
              <div className={styles.cardSection}>
              {cardData1.map((items, id) => {
                    return (
                        <Card
                            key={items.id}
                            image={items.image}
                            title={items.title}
                            description={items.description}
                            className={styles.transitionCard}

                            />
                    )
                })}
              </div>
              <div className={`${styles.fourthSection} animate__animated animate__slideInLeft`}>
              <div className={styles.content}>
              <div className={styles.mainHeading}> Guaranteed 10% Saving on AWS Bill 
             </div>

              <p>
              Our expertise in AWS cost management ensures you achieve significant savings on your cloud expenses. We utilize advanced cost optimization techniques, real-time monitoring, and strategic resource allocation to guarantee at least a 10% reduction in your AWS bill. Trust our proven methods and tools to keep your cloud infrastructure both efficient and cost-effective.

</p>
              <button className={styles.started} onClick={() => {
              navigate("/login");
            }} >Get started</button>
 <button className={styles.demo} onClick={() => {
              navigate("/contact");
            }} >Book a demo</button>              </div>
              <div className={styles.image}>
              <img 
              src="https://d2qtwwg2436q9r.cloudfront.net/Guranteed+instance.png"
               className={styles.websiteimg}/>
              </div>
              </div>
              <p className={styles.collabheading}>Why us ?</p>
              <p className={styles.whyus}>Because we deliver, we don't drop or pass the ball, we follow up!</p>

              <div className={styles.card2Section}>
              {cardData2.map((items, id) => {
                    return (
                        <Card2
                            key={items.id}
                            image={items.image}
                            title={items.title}
                            description={items.description}
                            className={styles.transitionCard}
                            />
                    )
                })}
              </div>
              <div className={`${styles.fourthSection} animate__animated animate__slideInLeft`}>
              <div className={styles.image}>
                <img 
                src="https://d2qtwwg2436q9r.cloudfront.net/Smartly+Schedule+Instances+.png"
                className={styles.websiteimg}/>
              </div>
              <div className={styles.content}>
              <div className={styles.mainHeading}>  Smartly Schedule Instances to Cut Costs

             </div>

              <p>
              Optimize your AWS expenses with intelligent instance scheduling. Our solution ensures your instances run only when needed, significantly reducing unnecessary costs. Leverage our expertise to automate and fine-tune your cloud resource usage, maximizing efficiency and savings.
</p>
              <button className={styles.started} onClick={() => {
              navigate("/login");
            }} >Get started</button>
 <button className={styles.demo} onClick={() => {
              navigate("/contact");
            }} >Book a demo</button>              </div>
             
              </div>

              <div className={styles.slidersection}>
              <Slider {...settings}>
           <div>
        <div className={styles.sliderbox}>
          <div className={styles.slidercontent}>
          Choosing their AWS DevOps consulting proved invaluable. Their insightful strategies and hands-on support significantly improved our deployment processes, ensuring a seamless and secure development environment. Excellent service!
          </div>
        </div>
        <h3 className={styles.sliderHeading}>Jigar Dafda, Director of Engineering, Fynd</h3>

      </div>
      <div>
        <div className={styles.sliderbox}>
          <div className={styles.slidercontent}>
          The AWS DevOps consulting services by va2pt team were a game-changer for us. Their expert guidance streamlined our operations, optimized costs, and elevated our overall efficiency. Highly recommended!
          </div>
        </div>
        <h3 className={styles.sliderHeading}>Pankaj Vermani, CEO, Clovia.com</h3>

      </div>
      <div>
        <div className={styles.sliderbox}>
          <div className={styles.slidercontent}>
          Exceptional AWS DevOps support! They tailored solutions to our unique needs, enhanced system reliability, and accelerated our development cycles. A reliable partner for any organization seeking top-notch DevOps expertise.
          </div>
        </div>
        <h3 className={styles.sliderHeading}>Prabhat Pankaj, CTO Redclifflabs</h3>

      </div>

    </Slider>

              </div>

    <div>
    <Footer/>

    </div>
              </div>

    </>
  )
}

export default Home
const initialState = false

const isLoading = (state = initialState, action)=>{
    
    switch(action.type){
        case "showLoading": return true
        case "hideLoading": return false
        default: return state
        
    }
}


export default isLoading
import React from 'react'
import design from "./Footer.module.scss";
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate= useNavigate()
  return (
    <>
    <div className={design.main}>
    <div className={design.footer}>
      <div className={design.address}>
      <img className={design.logo_footer} src="https://d2qtwwg2436q9r.cloudfront.net/va2pt-logo.png" alt='logo' onClick={()=>{navigate("/")}} loading="lazy" />
     
     <p className={design.content}>
     Va2pt.com is a New Delhi-based company <br/> that provides DevOps consultancy, 24x7 <br/> SRE monitoring and alerting, cloud cost <br/> reduction, VAPT, ISO, and SOC2  <br/> certification services.       </p>
      </div>
      {/* <div className={design.footerSection}>
        <p>PRODUCTS</p>
        <ul>
          <li>Copilot</li>
          <li>ClearCost</li>
        </ul>
      </div>
      <div className={design.footerSection}>
        <p>RIS</p>
        <ul>
          <li>EC2</li>
          <li>Redshift</li>
          <li>RDS</li>
          <li>OpenSearch</li>
          <li>ElastiCache</li>
        </ul>
      </div>
      <div className={design.footerSection}>
        <p>COMPANY</p>
        <ul>
          <li onClick={()=>{navigate("abotus")}}>About Us</li>
          <li onClick={()=>{navigate("contact")}}>Contact</li>
          <li onClick={()=>{navigate("pricing")}}>Pricing </li>
          <li>Partners</li>
          <li>FAQ</li>
        </ul>
      </div>
      <div className={design.footerSection}>
        <p>TOOLS</p>
        <ul>
          <li>EC2 Calculator</li>
          <li>EC2 Pricing</li>
        </ul>
      </div>
      <div className={design.footerSection}>
        <p>RESOURCES</p>
        <ul>
          <li>Blog</li>
        </ul>
      </div> */}
      <div className={design.rightsection}>
        <div className={design.footerSection}>
        <p>FOLLOW</p>
        <ul>
          <li>
          <a className={design.link} href="https://www.linkedin.com/company/va2pt-com/">LinkedIn</a>
          </li>
        </ul>
      </div> 
         <div className={design.footerSection}>
        <p>ACCOUNT</p>
        <ul>
          <li onClick={()=>{navigate("/login")}}>Login</li>
        </ul>
      </div>
      </div>
      </div>
      <div className={design.footerBottom}>
        <div>
        <p className={design.loc}>Address</p>
        <p className={design.location}>2nd Floor, Plot No 13, <br/>Sector 14, Dwarka, New Delhi, 110078</p>
        </div>
        <div className={design.footerAws}>
          <img src="https://usage.ai/_next/static/media/AWSPartnerBadge.9f0b3e39.svg"  className={design.footerimg} alt="AWS Partner" />
          <img src="https://usage.ai/_next/static/media/QualifiedSoftwareBadge.d25569f4.svg"  className={design.footerimg} alt="AWS Qualified Software" />
        </div>
      </div>
      </div>
      </>
  )
}

export default Footer
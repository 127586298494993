import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "../pages/login.module.scss";

const Onboardingstep3 = ({
  onboardingDetails,
  setOnboardingDetails,
  setStep,
  handleNavClick,
}) => {
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setOnboardingDetails((values) => ({ ...values, [name]: value }));
  };
  return (
    <div>
      {/* step3 */}
      {/* {activeStep === 3 ? */}
      {/* <div className="row">
                <div className="col-lg-12">
                  <div className="enable-cost-content mb-5">
                    <div className="sec-heading-title">
                      <h2 className="sec-title mb-3">Enable Cost and Usage Report</h2>
                    </div>
                    <div className="form-sec-wrap">
                      <Form className="form-sec-content">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Cost and usage Bucket</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="S3 Bucket"
                            name="s3_bucket"
                            value={onboardingDetails.s3_bucket || ''}
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /\s/;
                              if (!regex.test(value) ) {
                                handleChange(e);
                              } 
                            }}
                        />
                          
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <Form.Label>Cost and usage prefix</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Prefix"
                            name="prefix"
                            value={onboardingDetails.prefix || ''}
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /\s/;
                              const underscoreRegex = /_/;
    
                              if (!regex.test(value) && !underscoreRegex.test(value)) {
                                      handleChange(e);
                                    }
                              
                            }}
                          />
                        </Form.Group>
                      </Form> */}

      {/* <Form className="checkbox-sec">
                          {["checkbox"].map((type) => (
                            <div
                              key={`reverse-${type}`}
                              className="mb-3 text-left d-flex"
                            >
                              <Form.Check
                                reverse
                                label="Setup Cost and Usage Report"
                                name="group1"
                                type={type}
                                id={`reverse-${type}-1`}
                              />
                            </div>
                          ))}
                        </Form> */}
      {/* <div className="btn-wrap flex-content-center flex-wrap">
                        <Button className="btn-primary me-3" onClick={() => {navigate('/onboarding/step4');setStep(4)}}
                          disabled={!onboardingDetails.prefix || !onboardingDetails.s3_bucket}>
                          Next
                        </Button> */}

      {/* <Link to="/onboarding" className="setup-link my-3">
                          <Button onClick={setUpIAMPermision}> Setup IAm Permission Using CloudFormation Access Keys</Button>
                        </Link> */}
      {/* Setup IAm Permission Using CloudFormation Access Keys */}

      {/* </div>
                      <div className="help-btn-link my-1">
                        <span className="text-field">
                          Need help setting up your account? Our team is here to help.

                        </span>
                       
                      </div>
                      <Button
                          className="btn-overlay-primary mx-1 my-3"
                          href="https://wa.me/918532044146/?text=I%20would%20like%20to%20chat%20with%20you"
                          target="_blank"
                        >
                          Connect us on WhatsApp
                        </Button>
                    </div>
                  </div>
                </div>
              </div> */}

      <div className="row">
        <div className="col-lg-12">
          <div className="main-step1 align" >
            <div>
              <h2 className="welcome">Enable Cost and Usage Report</h2>
            </div>
            <div className="form-sec-wrap">
              {/* <form className={styles.form}>
            <label htmlFor="issuetype" className={styles.label}>Cost and usage Bucket</label>
                <input
                    className={styles.minput}
                    type="text"
                    placeholder="S3 Bucket"
                    name="s3_bucket"
                    value={onboardingDetails.s3_bucket || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /\s/;
                      if (!regex.test(value)) {
                        handleChange(e);
                      }
                    }}
                />
                </form> */}
              <Form className="form-sec-content">
                <Form.Group
                  className="mb-3 all-input"
                  controlId="exampleForm.ControlInput1"
                >
                  <p className="ptext">Cost and usage Bucket</p>
                  <Form.Control
                    type="text"
                    placeholder="S3 Bucket"
                    name="s3_bucket"
                    value={onboardingDetails.s3_bucket || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /\s/;
                      if (!regex.test(value)) {
                        handleChange(e);
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3 all-input"
                  controlId="exampleForm.ControlInput1"
                >
                  <p className="ptext">Cost and usage prefix</p>
                  <Form.Control
                    type="text"
                    placeholder="Prefix"
                    name="prefix"
                    value={onboardingDetails.prefix || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /\s/;
                      const underscoreRegex = /_/;

                      if (!regex.test(value) && !underscoreRegex.test(value)) {
                        handleChange(e);
                      }
                    }}
                  />
                </Form.Group>
              </Form>

              <div className="btn-wrap flex-content-center flex-wrap">
                <Button
                  className="btn-primary me-3 onboardingbutton"
                  onClick={() => {
                    navigate("/onboarding/step4");
                    setStep(4);
                  }}
                  disabled={
                    !onboardingDetails.prefix || !onboardingDetails.s3_bucket
                  }
                >
                  Next
                </Button>
              </div>
              <div className="help-btn-link">
                <span className="ptext">
                  Need help setting up your account? Our team is here to help.
                </span>
              </div>
              <Button
                className="btn-overlay-primary mx-1 my-3 whatsApp"
                href="https://wa.me/918532044146/?text=I%20would%20like%20to%20chat%20with%20you"
                target="_blank"
              >
                Connect us on WhatsApp
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboardingstep3;

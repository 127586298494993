
import React, { useState, useEffect } from 'react';
import request from "../../utils/requests";
import styles from "./ResourceTracker.module.scss";
import { showLoading, hideLoading } from "../../store/actions/loader";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Pagination from '@mui/material/Pagination';


const ResourceTrackerTable = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState([]);
  const [filteredResource, setFilteredResource] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");


  const fetchData = async () => {
    try {
      dispatch(showLoading());
      const response = await request.get(`resource_tracker/lists/`);
      setDetails(response.data.items);
      setFilteredResource(response.data.items);
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      toast.error('Error fetching data: ' + error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePaste = (event) => {
    const pastedText = event?.clipboardData?.getData("text");
    setSearchQuery(pastedText);
  };

  useEffect(() => {
    const filterFunction = details.filter((item) =>
      Object.values(item).some(
        (value) =>
          value !== null &&
          value?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      )
    );
    setFilteredResource(filterFunction);
  }, [details, searchQuery]);

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    setPageSize(newSize);
    setPage(1); // Reset to first page on page size change
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const paginatedData = filteredResource.slice((page - 1) * pageSize, page * pageSize);

  return (
    <div className={styles.resourceTable}>
      <div className={styles.searchBar}>
        <div className={styles.searchnumber}>
          <select
            className={styles.Input}
            id="entryperpage"
            onChange={handlePageSizeChange}
          >
            <option className={styles.option} value="5">5</option>
            <option className={styles.option} value="10">10</option>
            <option className={styles.option} value="15">15</option>
            <option className={styles.option} value="20">20</option>
          </select>
          <p className={styles.ptag}>entries per page</p>
        </div>
        <div>
          <input
            type="text"
            className={`${styles.Input} ${styles.search}`}
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search..."
            onPaste={handlePaste}
          />
        </div>
      </div>
      <table cellPadding="10px" className={styles.table}>
        <thead className={styles.heading}>
          <tr>
            <th>Resource Id</th>
            <th>Resource Name</th>
            <th>Change type</th>
            <th>Event time</th>
            <th>Current Resource type</th>
            <th>Current cost</th>
            <th>Previous Resource type</th>
            <th>Previous cost</th>
            <th>Region</th>
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {paginatedData.map((data, index) => (
            <tr key={index}>
              <td>{data.resource_id}</td>
              <td>{data.resource_name}</td>
              <td>{data.changes_type}</td>
              <td>{data.event_time}</td>
              <td>{data.current_resource_type}</td>
              <td>{data.current_cost}</td>
              <td>{data.previous_resource_type}</td>
              <td>{data.previous_cost}</td>
              <td>{data.region}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <div className="d-flex mt-2">
          <Pagination
            count={Math.ceil(filteredResource.length / pageSize)}
            variant="outlined"
            shape="rounded"
            sx={{ marginLeft: "auto" }}
            page={page}
            onChange={handlePageChange}
          />
        </div>
      </div>

      
    </div>
  );
};

export default ResourceTrackerTable;

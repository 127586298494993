import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import request from "../../utils/requests";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import style from "../Security/Security.module.scss"


const SeverityGraph = () => {

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await request.get(`/prowler/findings_by_severity/`);
        const data = res.data;
        setChartData(data);
      } catch (error) {
        toast.error("Error fetching data");
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <>
      {chartData ? (
        // <div className={style.pieChart} style={{ width:"50%",height:"400px"}}>
            <div className={style.pieChart} style={{height:"400px"}}> 
            <p className={style.graphHeading}>Finding by severity</p>

          <Chart
            options={{
              // colors : ['#9C27B0', '#E91E63','#F44336' , '#2196F3'],
              colors: ["rgba(255, 69, 96, 0.85)","rgba(254, 176, 25, 0.85)","rgba(0, 143, 251, 0.85)","rgba(0, 227, 150, 0.85)"],
              chart: {
                type: "bar",
                height: 350,
              },
              grid: {
                borderColor: '#535A6C', // Set grid border color
                strokeDashArray: 4, // Set stroke dash array for dotted lines
            },
              plotOptions: {
                bar: {
                  horizontal: false,
                  distributed: true

                },
              },
              dataLabels: {
                enabled: true,
              },
              xaxis: {
                categories: Object.keys(chartData),
                labels: {
                  style: {
                    colors: "#fff",
                  },
          
                },
              },
              yaxis: {
                labels: {
                  style: {
                    colors: "#fff",
                  },
                },
              },
              legend: {
                labels: {
                    colors: '#fff', // Set legend label color to white
                },
            },
            }}
            series={[{ data: Object.values(chartData) }]
          }
        
            type="bar"
            height="80%"
          />
        </div>
      ) : (
        <div className="fixed-center">
          <CircularProgress color="inherit" />
        </div>
      )}
    </>
  );
};


export default SeverityGraph
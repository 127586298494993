import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as THREE from "three";
import { OrbitControls } from "@three-ts/orbit-controls";

function Globe({ canvasStyle, ...rest }) {
  const globeRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    function createGlobe() {
      const container = globeRef.current;
      const canvas = canvasRef.current;
    
      if (!container || !canvas) {
        console.error('Container or canvas is not available.');
        return;
      }
    
      // WebGL Context Check
      if (!hasWebGL()) {
        console.error('WebGL is not supported or failed to initialize.');
        return;
      }
    
      // Function to check WebGL support
      function hasWebGL() {
        try {
          const canvas = document.createElement('canvas');
          return !!window.WebGLRenderingContext && !!canvas.getContext('webgl');
        } catch (e) {
          return false;
        }
      }
    
      // Globe Geometry and Setup
      const globeRadius = 100;
      const globeWidth = 4098 / 2;
      const globeHeight = 1968 / 2;
    
      function convertFlatCoordsToSphereCoords(x, y, globeWidth, globeHeight, globeRadius) {
        let latitude = ((x - globeWidth) / globeWidth) * -180;
        let longitude = ((y - globeHeight) / globeHeight) * -90;
        latitude = (latitude * Math.PI) / 180;
        longitude = (longitude * Math.PI) / 180;
        const radius = Math.cos(longitude) * globeRadius;
    
        return {
          x: Math.cos(latitude) * radius,
          y: Math.sin(longitude) * globeRadius,
          z: Math.sin(latitude) * radius,
        };
      }
    
      function makeMagic(points) {
        const { width, height } = container.getBoundingClientRect();
    
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(45, width / height, 1, 10000);
        const renderer = new THREE.WebGLRenderer({
          canvas,
          antialias: true,
          alpha: true, // Allows the background to be transparent
        });
        renderer.setSize(width, height);
        renderer.setClearColor(0x000000, 0); // Set color to transparent
        
    
        const pointMaterial = new THREE.MeshBasicMaterial({
          color: "#3993fe",
        });
    
        points.forEach((point) => {
          const { x, y, z } = convertFlatCoordsToSphereCoords(
            point.x,
            point.y,
            globeWidth,
            globeHeight,
            globeRadius
          );
          if (x && y && z) {
            const pointGeometry = new THREE.SphereGeometry(0.5, 6, 6);
            pointGeometry.translate(x, y, z);
            const pointMesh = new THREE.Mesh(pointGeometry, pointMaterial);
            scene.add(pointMesh);
          }
        });
    
        container.classList.add("peekaboo");
    
        const orbitControls = new OrbitControls(camera, canvas);
        orbitControls.enableKeys = false;
        orbitControls.enablePan = false;
        orbitControls.enableZoom = false;
        orbitControls.enableDamping = false;
        orbitControls.enableRotate = true;
        orbitControls.autoRotate = true;
        camera.position.z = -265;
    
        function animate() {
          orbitControls.update();
          requestAnimationFrame(animate);
          renderer.render(scene, camera);
        }
        animate();
      }
    
      function init() {
        window
          .fetch("https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-dashboard-pro/assets/js/points.json")
          .then((response) => response.json())
          .then((data) => makeMagic(data.points))
          .catch((error) => console.error('Failed to load data:', error));
      }
    
      init();
    }

    createGlobe();
  }, []);

  return (
    <div ref={globeRef} {...rest} style={{ width: "50%" }}>
      <canvas
        ref={canvasRef}
        width="900"
        height="600"
        style={{ outline: "none", ...canvasStyle }}
      />
    </div>
  );
}

Globe.defaultProps = {
  canvasStyle: {},
};

Globe.propTypes = {
  canvasStyle: PropTypes.objectOf(PropTypes.any),
};

export default Globe;

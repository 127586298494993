// import React from 'react'
// import styles from "./card.module.scss"

// const Card = (props) => {
//   return (
//     <div className={`${styles.card} ${props.className}`}>
//     <img src={props.image} />
//         <p className={styles.title}>{props.title}</p>
//         <p className={styles.content}>{props.description}</p>
//     </div>
//   )
// }

// export default Card

import React from 'react'
import styles from "./card.module.scss"

const Card = ({ image: Icon, title, description }) => {
  return (
    <div className={styles.card}>
        <div>
            <Icon className={styles.icon} />
        </div>
           <p className={styles.title}>{title}</p>
            <p className={styles.content}>{description}</p>
           </div>
  )
}

export default Card
import { useEffect } from "react"
import styles from "../pages/Integration/Card.module.scss"

const CustomCard = ({ title, icon, value }) => {

    useEffect(() => {

    }, [])
    return (
        <>
           
            <div className={`${styles.card} ${styles.recommendationCard}`}>
         <div className={styles.content}>
        <p className={styles.content}>{title}</p>
        <p className={styles.number}>{value &&  `${localStorage.getItem('currencyCode')}  ${value}` }</p>
        </div>
        <div className={styles.icon}>
            {icon}
       </div>
      </div>
      
        </>
    )
}

export default CustomCard
import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import request from '../utils/requests';

const TopServicesPieChart = () => {
    const today = moment().format("YYYY-MM-DD");
    const sixMonthsAgo = moment().subtract(6, 'months').format("YYYY-MM-DD");
    const [granularity, setGranularity] = useState('MONTHLY');
    const [dimension, setDimension] = useState('SERVICE');
    const [pieChartData, setPieChartData] = useState({ categories: [], series: [] });

    const formatNumberWithCommas = (number) => {
        const [integerPart, decimalPart] = number.split(".");
        const lastThreeDigits = integerPart.slice(-3);
        const otherDigits = integerPart.slice(0, -3);
        const formattedIntegerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + (otherDigits ? "," : "") + lastThreeDigits;
        return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
      };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await request.get(`/ce/cost_breakdown_by_filter?start_date=${sixMonthsAgo}&end_date=${today}&granularity=${granularity}&dimension=${dimension}`);
                const data = response.data;

                // Extract and prepare pie chart data
                const pieServices = {};
                Object.values(data).forEach(date => {
                    Object.entries(date).forEach(([service, cost]) => {
                        if (service !== 'total') {
                            pieServices[service] = (pieServices[service] || 0) + parseFloat(cost);
                        }
                    });
                });

                const sortedPieServices = Object.entries(pieServices).sort(([, cost1], [, cost2]) => cost2 - cost1);
                const top5PieServices = sortedPieServices.slice(0, 5);
                const pieCategories = top5PieServices.map(([service]) => service);
                const pieSeries = top5PieServices.map(([, cost]) => cost);

                setPieChartData({ categories: pieCategories, series: pieSeries });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [granularity, dimension]);

    const options = {
        chart: {
            type: 'pie',
            backgroundColor: {
                linearGradient: [0, 0, 0, 400],
                stops: [
                    [0, 'rgba(6, 11, 40, 0.94)'],
                    [1, 'rgba(10, 14, 35, 0.49)']
                ]
            },
            style: {
                borderRadius: '17px',
                color: 'rgba(0, 0, 0, 0.87)'
            },
            marginTop: 70, // Adjust the top margin of the chart to create space between chart and title
        },
        title: {
            text: 'Top 5 Services of 6 months',
            style: {
                color: '#fff',
                fontFamily: "Plus Jakarta Sans",
            },
            x: 0, // Adjust the horizontal position
            y: 30,
        },
        series: [{
            name: 'Service Cost',
            data: pieChartData.series.map((cost, index) => ({
                name: pieChartData.categories[index],
                y: cost
            }))
        }],
        tooltip: {
            pointFormatter: function () {
                return `  ${this.series.name}: <b> $ ${formatNumberWithCommas(this.y.toFixed(0).toString())}</b>`;
            }
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return `${this.point.name}: $ ${formatNumberWithCommas(this.y.toFixed(0).toString())}`;
                    },
                },
            },
            series: {
                borderWidth: 0, // Hide the border of the series
            },
        },
    };

    return (
        <div className='two-chart'>
            {pieChartData.categories.length > 0 ? (
                <HighchartsReact highcharts={Highcharts} options={options} />
            ) : (
                <div className="fixed-loader">
                    <CircularProgress color="inherit" />
                </div>
            )}
        </div>
    );
};

export default TopServicesPieChart;

import React from 'react';
import style from "./About.module.scss"
import AboutCard from './AboutCard';
import aboutcardData from "./AboutcardData";
import AboutCard2 from './AboutCard2';
import aboutcard2Data from "./Aboutcard2Data";
import { useNavigate } from "react-router-dom";
import Footer from '../Footer';
import NavigationBar from '../NavigationBar';



const Aboutus = () => {
  const navigate = useNavigate()
  return (
    <div className={style.mains}>
    <div className={style.main}>
    <div className={style.firstSection}>
    
      <NavigationBar/>
       
      
    </div>
    <div className={style.secondSection} >
              <div className={style.content}>
              <div className={style.mainHeading}>  About Va2pt 
             </div>

              <p className={style.firstpara}>
              Va2pt.com is a New Delhi-based company that provides DevOps consultancy, 24x7 SRE monitoring and alerting, cloud cost reduction, VAPT, ISO, and SOC2 certification services. Our aim is to be the catalyst for DevOps transformation, empowering companies to achieve agility, innovation, and market leadership through streamlined processes and robust technology integration. Our unique selling point is the comprehensive range of services aimed at optimizing and securing cloud infrastructure, targeting potential clients seeking DevOps and cloud optimization services.

</p>
              <button className={style.started} onClick={() => {
              navigate("/login");
            }} >Get started</button>
              </div>
              <div>
              <img 
              src="https://d2qtwwg2436q9r.cloudfront.net/icons+(1)/Abhishek+image+copy.png"
              className={style.websiteimg}/>
              
              </div>
    </div>
    <div className={style.thirdSection}>
        <p className={style.primaryHeading}>What We Value</p>
        <div className={style.cardAbout}>
         {aboutcardData.map((item,index)=>{
          return(
          <AboutCard
          index={index}
          key={item.id}
          image={item.image}
          heading={item.heading}
          description={item.description}
          className={style.particularCard}
          />
          )
         })}
        </div>

      
        <div className={style.collaborationsection}>
             <p className={style.collabheading}>Our collaboration</p>
             <div className={style.logosimage}>
             <img src='https://static.wixstatic.com/media/4ecd90_1d4a12ca67ae46e99b25e04104b45bf3~mv2.png/v1/fill/w_155,h_103,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/tira-bw.png'/>
             
             <img src='https://static.wixstatic.com/media/4ecd90_1e3b88858b7945569afbbfe07be54cbf~mv2.png/v1/fill/w_155,h_103,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/clovia-BW_edited.png'/>

             <img src='https://static.wixstatic.com/media/4ecd90_14ce036c295c49f79e4bf8ed94e4379b~mv2.png/v1/fill/w_176,h_110,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/redcliffelabs-BW.png' />

             <img src='https://static.wixstatic.com/media/4ecd90_44fc10d4cba647de9b1b9455ca04703c~mv2.png/v1/fill/w_155,h_103,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/fynd-BW.png' />
             
             <img src='https://static.wixstatic.com/media/4ecd90_172f2735de654512a2d23bdbefefc62b~mv2.png/v1/fill/w_155,h_103,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Aaj_tak-BW.png' />

             <img src='https://static.wixstatic.com/media/4ecd90_2ee8586664fc4e27bd129bc44303fbff~mv2.png/v1/fill/w_166,h_110,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/BeatO-BW.png'/>

             <img src='https://static.wixstatic.com/media/4ecd90_2eba7e05c9c047dbb1fd824f739e6114~mv2.png/v1/fill/w_155,h_110,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ace-cloud-hosting-BW.png'/>

             <img src='https://static.wixstatic.com/media/4ecd90_ae1b66a304f0499ca39977ac8578a059~mv2.png/v1/fill/w_166,h_110,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Shiprocket-BW.png'/>
             </div>
             </div>

<div>
  <p className={style.primaryHeading}>Get to know us</p>
  <div className={style.cardAbout}>
         {aboutcard2Data.map((item,index)=>{
          return(
          <AboutCard2
          index={index}
          key={item.id}
          image={item.image}
          heading={item.heading}
          description={item.description}
          />
          )
         })}
        </div>
</div>
    </div>
    <div>
      <Footer/>
    </div>
    </div>
    </div>
  )
}

export default Aboutus
import React from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "../pages/login.module.scss";

const Onboardingstep2 = ({ setStep, handleNavClick }) => {
  const navigate = useNavigate();

  return (
    <div>
      {/* <div className="row">
        <div>
          <h2 className="mb-5">Choose your own advanture!</h2>
          <p className="sec-content">
            You have the option of setting up on your own or contacting us right
            away for assistance. Rest assured, we are here to support you every
            step of the way.
          </p>
          <p className="sec-content">Choose your own guided path</p>
          <div className="form-sec-wrap">
            {/* <Form className="form-sec-content">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control type="text" placeholder="" />
                        </Form.Group>
                      </Form> */}
            {/* <div className="btn-wrap flex-content-center">
              <Button
                className="btn-primary"
                onClick={() => {
                  navigate("/onboarding/step2");
                  setStep(3);
                }}
              >
                Set Up Now
              </Button>
              <Button
                className="btn-overlay-primary mx-3"
                href="https://wa.me/918532044146/?text=I%20would%20like%20to%20chat%20with%20you"
                target="_blank"
              >
                Connect us on WhatsApp
              </Button>
            </div>
          </div>
        </div>
      </div> */} 

      <div className="row">
        <div className="col-lg-12">
          <div className="main-step1 align">
            <div className="innerdiv">
              <h2 className="welcome">Choose your own adventure!</h2>
              <p className="ptext">
                You have the option of setting up on your own or contacting us
                right away for assistance. Rest assured, we are here to support
                you every step of the way.
              </p>
              <p className="ptext">Choose your own guided path</p>
            </div>
            <div className={styles.outerButton}>
              {/* <button
                className="nextbutton"
                onClick={() => {
                  navigate("/onboarding/step2");
                  setStep(3);
                }}
              >
                Set Up Now
              </button> */}
               <Button
                className="btn-primary onboardingbutton setup"
                onClick={() => {
                  navigate("/onboarding/step2");
                  setStep(3);
                }}
              >
                Set Up Now
              </Button>
            </div>
              <div className="help-btn-link">
              <p className="ptext">
                Need help setting up your account? Our team is here to help.
              </p>
            </div>
            <Button
                className="btn-overlay-primary mx-1 my-3 whatsApp"
                href="https://wa.me/918532044146/?text=I%20would%20like%20to%20chat%20with%20you"
                target="_blank"
              >
                Connect us on WhatsApp
              </Button>
            </div>

          </div>
        </div>
      </div>
  );
};

export default Onboardingstep2;

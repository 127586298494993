import React, { useEffect, useState } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more'; // Import Highcharts More for gradient support
import request from "../../utils/requests";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";

// Initialize Highcharts More module
if (typeof Highcharts === 'object') {
  HighchartsMore(Highcharts);
}

const OverTimeLinegraph = () => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await request.get(`/prowler/findings_over_time/`);
        const data = res.data;
        setChartData(data);
      } catch (error) {
        toast.error("Error fetching data");
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  // Render loading spinner if data is still loading
  if (!chartData) {
    return <CircularProgress />;
  }

  // Prepare data for chart
  const seriesData = chartData.map(item => ({
    x: new Date(item.day).getTime(),
    critical: item.severity.critical,
    high: item.severity.high,
    medium: item.severity.medium,
    low: item.severity.low,
    pass: item.pass_count
  }));

  const chartOptions = {
    chart: {
      backgroundColor: {
        linearGradient: [0, 0, 0, 400],
        stops: [
          [0, 'rgba(6, 11, 40, 0.94)'],
          [1, 'rgba(10, 14, 35, 0.49)']
        ]
      },
      style: {
        borderRadius: '17px',
        color: 'rgba(0, 0, 0, 0.87)'
      },
      marginTop: 70,
      options3d: {
        enabled: true,
        alpha: 45
      },
      // Stroke properties
      stroke: {
        borderColor: '#535A6C !important', // Set grid border color
        strokeDashArray: '4 !important'
      }
    },
    title: {
      text: 'Severity Distribution Over Time',
      style: {
        color: '#fff',
        fontFamily: "Plus Jakarta Sans",
      },
      x: 0,
      y: 40,
    },
      xAxis: {
        type: 'datetime',
        labels: {
          style: {
            color: '#fff' // Set x-axis label color to white
          },
          format: '{value:%Y-%m-%d}' // Format x-axis labels to display date
        },
        
      },
    yAxis: {
        title: {
          text: 'Count',
          style: {
            color: '#fff' // Set y-axis label color to white
          }
        },
        labels: {
          style: {
            color: '#fff' // Set y-axis label color to white
          }
        },

      },
      legend: {
        itemStyle: {
          color: '#fff' // Set legend item color to white
        }
      },
    plotOptions: {
      series: {
        marker: {
          enabled: false // Disable markers on data points
        }
      }
    },
    series: [
      {
        name: 'Critical',
        data: seriesData.map(item => [item.x, item.critical]),
        color: 'rgba(255, 0, 0, 0.7)', // Red color for critical
        fillOpacity: 0.3 // Set fill opacity for gradient effect
      },
      {
        name: 'High',
        data: seriesData.map(item => [item.x, item.high]),
        color: 'rgba(255, 165, 0, 0.7)', // Orange color for high
        fillOpacity: 0.3
      },
      {
        name: 'Medium',
        data: seriesData.map(item => [item.x, item.medium]),
        color: 'rgba(255, 255, 0, 0.7)', // Yellow color for medium
        fillOpacity: 0.3
      },
      {
        name: 'Low',
        data: seriesData.map(item => [item.x, item.low]),
        color: 'rgba(0, 255, 0, 0.7)', // Green color for low
        fillOpacity: 0.3
      },
      {
        name: 'Pass',
        data: seriesData.map(item => [item.x, item.pass]),
        color: 'rgba(0, 0, 255, 0.7)', // Blue color for pass
        fillOpacity: 0.3
      }
    ],
    credits: {
      enabled: false // Disable credits
    }
  };

  return (
    <div style={{ height: '400px'}}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  );
};

export default OverTimeLinegraph;
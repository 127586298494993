import { Box, Button, IconButton } from "@mui/material"
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from "react"
import request from "../utils/requests"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';

const S3 = ({ list, updateList }) => {

    const [open, setOpen] = useState(false);
    const [bucketName, setBucketName] = useState('');
    const [moveToGlacier, setMoveToGlacier] = useState(false);

    const handleClickOpen = (bucket_name, toGlacier) => {
        setMoveToGlacier(toGlacier)
        setBucketName(bucket_name)

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setMoveToGlacier(false)
    };

    const deleteBucketByName = () => {
        request.del(`/s3_report/delete_s3_bucket/${bucketName}/`).then(res => {
            toast.info(res.data.message);
            updateList('S3 Report');
        })
        setMoveToGlacier(false)
        setOpen(false);
    }

    const moveBucketByName = () => {
        request.del(`/s3_report/move_toglacier/${bucketName}/`).then(res => {
            toast.info(res.data.message);
            updateList('S3 Report');
        })
        setMoveToGlacier(false)
        setOpen(false);
    }

    const columns = [
        { field: 'bucket_name', headerName: 'Bucket Name', width: 300, sortable: false, },
        { field: 'region', headerName: 'Region', width: 150, sortable: false, },
        { field: 'size_14_days_ago', headerName: moment().subtract(14, 'd').format("YYYY-MM-DD"), width: 150, sortable: false, },
        { field: 'today_size', headerName: moment().format("YYYY-MM-DD"), width: 150, sortable: false, },
        { field: 'increased_size', headerName: 'Increase', width: 150, sortable: false, },
        { field: 'policy', headerName: 'Life Cycle Policy', width: 150, sortable: false, },
        { field: 'action', headerName: 'Action', width: 300, sortable: false, },
    ];

    useEffect(() => {
    }, [])

    const downloadReport = () => {
        request.get('/s3_report/download_csv/').then(res => {
            const blob = new Blob([res.data], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "S3_Bucket.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }

    return (
        <>
            {list && list.length > 0 &&
                <Box>
                    <Box>
                        <h4 style={{ marginTop: 10, fontWeight: 'bold' }}>S3 Report(Last Updated on {'date'})</h4>
                        <h6 style={{ marginTop: 30, fontWeight: 'bold' }}>Summary</h6>
                        <h6 style={{ marginTop: 10 }}>This report identifies all S3 buckets with increase in their storage size in last 14 days in account 867467468687.<br />
                            0 GB increase in the total S3 storage size.<br />
                        </h6>
                        <h6 style={{ marginTop: 10, fontWeight: 'bold' }}>Report Criteria</h6>
                        <h6 style={{ marginTop: 10 }}>
                            Compares the current storage size of all the buckets in the account with what it was 14 day prior.
                        </h6>
                        <h6 style={{ marginTop: 10, fontWeight: 'bold' }}>Recommended Actions</h6>
                        <h6 style={{ marginTop: 10 }}>
                            If there is an increase in the storage size, identify those buckets appearing in this report and take corrective actions if needed.
                        </h6>
                        <Box sx={{ display: 'flex' }}>
                            <Button variant="contained" size="small"
                                sx={{
                                    backgroundColor: "#1672EC",
                                    color: 'white',
                                    textTransform: "none",
                                    marginLeft: 'auto',
                                    marginRight: '20px',
                                    '&:hover': {
                                        backgroundColor: "#1672EC",
                                        color: "white",
                                        textTransform: "none"

                                    }
                                }}>
                                Historical Data
                            </Button>
                            <Button variant="contained" size="small"
                                sx={{
                                    backgroundColor: "#1672EC",
                                    color: 'white',
                                    textTransform: "none",
                                    marginLeft: '10px',
                                    marginRight: '20px',
                                    '&:hover': {
                                        backgroundColor: "#1672EC",
                                        color: "white",
                                        textTransform: "none"

                                    }
                                }}>
                                Generate Report
                            </Button>
                            <IconButton aria-label="download" onClick={downloadReport}>
                                <CloudDownloadIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box style={{ width: 'fit-content', maxWidth: '100%' }}>
                        <div className="custom-table" style={{ marginTop: '10px' }}>
                            <div className="custom-table-header" >
                                {columns.map((column, index) =>
                                    <div key={index} style={{ width: `${column['width']}px` }}>{column.headerName}</div>
                                )}
                            </div>

                            {list.map((item, index) =>
                                <div key={item.bucket_name} style={{ borderBottom: '1px solid' }}>
                                    <div className="custom-table-row">
                                        {columns.map((column, ind) =>
                                            <div key={ind} style={{ width: `${column['width']}px` }}>
                                                {
                                                    column['field'] != 'action' ? item[column['field']] ? item[column['field']] : '-' :
                                                        <>
                                                            <Button variant="contained" size="small" onClick={() => handleClickOpen(item['bucket_name'], false)}
                                                                sx={{
                                                                    backgroundColor: "red",
                                                                    color: 'white',
                                                                    textTransform: "none",
                                                                    fontWeight: 'bold',
                                                                    '&:hover': {
                                                                        backgroundColor: "red",
                                                                        color: "white",
                                                                        textTransform: "none"
                                                                    }
                                                                }}> Delete </Button>
                                                            <Button variant="contained" size="small" onClick={() => handleClickOpen(item['bucket_name'], true)}
                                                                sx={{
                                                                    backgroundColor: "green",
                                                                    color: 'white',
                                                                    textTransform: "none",
                                                                    fontWeight: 'bold',
                                                                    marginLeft: '10px',
                                                                    '&:hover': {
                                                                        backgroundColor: "green",
                                                                        color: "white",
                                                                        textTransform: "none"
                                                                    }
                                                                }}> Move to glacier </Button>
                                                        </>

                                                }
                                            </div>

                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Box >
                </Box>
            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {moveToGlacier ? "Are you sure you want to move this Bucket?" : "Are you sure you want to delete this Bucket?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <b>{bucketName}</b>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" sx={{
                        backgroundColor: "grey",
                        color: 'white',
                        textTransform: "none",
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: "grey",
                            color: "white",
                            textTransform: "none"
                        }
                    }}>Cancel</Button>
                    <Button onClick={moveToGlacier ? moveBucketByName : deleteBucketByName} autoFocus sx={{
                        backgroundColor: "red",
                        color: 'white',
                        textTransform: "none",
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: "red",
                            color: "white",
                            textTransform: "none"
                        }
                    }}>
                        {moveToGlacier ? 'Move' : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default S3
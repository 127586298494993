import React, {useEffect, useState} from "react";
import style from "./Slack.module.scss";
import { toast } from 'react-toastify';
import request from "../../utils/requests";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
    email: yup.string().required("Email is required"),
    frequency: yup.string().required("Frequency is required"),
    auto_follow_up: yup.string().required("Auto follow up is required"),

});

const Email = () => {
    const [formData, setFormData] = useState({
        email: "",
        type: "Email",
        frequency: "",
        auto_follow_up:""
    });

    const [isSubmitted, setIsSubmitted] = useState(false);

    const { handleSubmit, register, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

 

    async function handleFormData(formData) {
        try {
            console.log("Form Data:", formData);

            const postData = {
                username: formData.email,
                frequency: formData.frequency,
                type: formData.type,
                auto_follow_up:formData.auto_follow_up


            };

            const postResponse = await request.post('users/submit_email/', postData);
            if (postResponse.status === 201 || postResponse.status === 200) {
                toast.success(postResponse.data.detail);
                setIsSubmitted(true);
            }
        } catch (error) {
            toast.error("An internal server error occcurred")
        }
    }

   
    const handleData = async (data) => {
        try {
            console.log("Form Data:", data);
            await handleFormData(data);
        } catch (error) {
            console.error('An internal error occurred:', error);
            toast.error("An internal error occurred");
        }
    };


    useEffect(() => {
        async function fetchData() {
            try {
                const res = await request.get(`/users/integration_list/`);
                const responseData = res.data;

                if (responseData.length > 0) {
                    const emailEntry = responseData.find(entry => entry.type === "Email");
                    if (emailEntry) {
                        const { username, frequency ,auto_follow_up} = emailEntry;
                        setFormData({
                            email: username,
                            frequency: frequency,
                            autofollowup:auto_follow_up
                        });
                    }
                }
            } catch (error) {
                toast.error("An error occurred while fetching data");
            }
        };
        fetchData();
    }, []);

    useEffect(() => {

        setValue("email", formData.email);
        setValue("frequency", formData.frequency);
        setValue("auto_folllow_up", formData.auto_follow_up);

    }, [formData, setValue]);

    return (
        <div className={style.form}>
            <form  onSubmit={handleSubmit(handleData)}>
                <label htmlFor="email" className={style.label}>Email</label>
                <input
                 className={`${style.multipleInput} ${errors.email && style.errorInput}`}
                 type="text"
                    name="email"
                    placeholder='Enter email'
                    {...register("email", { required: "Email is required" })}
                />
                {errors.email && <p className={style.error}>{errors.email.message}</p>}


                    <input
                    className={`${style.multipleInput} ${style.type}`}
                    // type="text"
                        name="type"
                        value="Email" 
                        {...register("type")}

                        // {...register("type", { required: "Type is required" })}
                    />

                <label htmlFor="frequency" className={style.label}>Frequency</label>
                <select
                    name="frequency"
                    {...register("frequency", { required: "Frequency is required" })}
                    className={`${style.multipleInput} ${errors.frequency && style.errorInput}`}
                    >
                    <option className={style.option} value="">Select Frequency</option>
                    <option className={style.option} value="7">Every week</option>
                    <option className={style.option} value="15">Every 15 days </option>
                    <option className={style.option} value="30">Every month</option>
                </select>
                {errors.frequency && <p className={style.error}>{errors.frequency.message}</p>}


                <label htmlFor="autofollowup" className={style.label}>Auto Follow Up</label>

               <select
              name="auto_follow_up"
              {...register("auto_follow_up", { required: "Auto follow up is required" })}

              className={`${style.multipleInput} ${errors.auto_follow_up && style.errorInput}`}
              >
<option className={style.option} value="">Select Frequency</option>
<option className={style.option} value="7">Weekely</option>
<option className={style.option} value="15">Every 15 days</option>
<option className={style.option} value="30">Every month</option>
      </select>
      {errors.auto_follow_up && <p className={style.error}>{errors.auto_follow_up.message}</p>}


                {!isSubmitted && (
                    <button type="submit" className={style.button}>
                        Submit
                    </button>
                )}
              
            </form>
        </div>
    );
}

export default Email;






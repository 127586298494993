import { Box, Button, IconButton, Select, MenuItem, FormControl,InputLabel } from "@mui/material"
import React, { useState } from "react"
import request from "../utils/requests"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Label } from "@mui/icons-material";


const OldGenInstance = ({ list, updateList }) => {

    const [open, setOpen] = useState(false);
    const [instanceId, setInstanceId] = useState('');
    const [instanceGen, setInstanceGen] = useState('');

    const handleClickOpen = (item) => {
        setInstanceId(item['instance_id'])
        setInstanceGen('gen1')
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const convertInstanceGenration = () => {
        request.post(`/old_gen_report/instance/${instanceId}/${instanceGen}`).then(res => {
            setOpen(false);
        })
        setOpen(false);
    }

    const columns = [
        { field: 'account_id', headerName: 'Account Id', width: 100, sortable: false, },
        { field: 'account_name', headerName: 'Account Name', width: 100, sortable: false, },
        { field: 'region', headerName: 'Region', width: 100, sortable: false, },
        { field: 'current_gen_count', headerName: 'Current Gen Count', width: 100, sortable: false, },
        { field: 'gen1_count', headerName: 'Gen1 Count', width: 80, sortable: false, },
        { field: 'gen2_count', headerName: 'Gen2 Count', width: 80, sortable: false, },
        { field: 'gen3_count', headerName: 'Gen3 Count', width: 80, sortable: false, },
        { field: 'gen4_count', headerName: 'Gen4 Count', width: 80, sortable: false, },
        { field: 'gen5_count', headerName: 'Gen5 Count', width: 80, sortable: false, },
        { field: 'engg_negation', headerName: 'Engg Negation', width: 100, sortable: false, },
        { field: 'total_count', headerName: 'Total Count', width: 80, sortable: false, },
        { field: 'old_gen_coverage', headerName: 'Old Gen Coverage', width: 100, sortable: false, },
        { field: 'monthly_possible_savings', headerName: 'Monthely Possible Savings', width: 100, sortable: false, },
        { field: 'action', headerName: 'Action', width: 100, sortable: false, },
    ];

    const downloadReport = () => {
        request.get('/old_gen_report/download_csv/').then(res => {
            const blob = new Blob([res.data], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Old_Gen_Instance.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }

    const handleInstanceChange = (e) => {
        setInstanceGen(e.target.value)
    };

    return (
        <>
            {list && list.length > 0 && <Box>
                <Box>
                    <h4 style={{ marginTop: 10, fontWeight: 'bold' }}>Old Gen Instance Report(Last Updated on {'date'})</h4>
                    <h6 style={{ marginTop: 30, fontWeight: 'bold' }}>Summary</h6>
                    <h6 style={{ marginTop: 10 }}>
                        This report provides coverage of old generation instances in the organization.<br />
                        The current old-generation coverage is 12.86%.<br />
                        Monthly Savings of upto <span style={{ fontWeight: 'bold' }}>USD 176.4</span> is possible by moving to the latest generation instances.
                    </h6>
                    <h6 style={{ marginTop: 10, fontWeight: 'bold' }}>Report Criteria</h6>
                    <h6 style={{ marginTop: 10 }}>
                        <u>Generations Map</u>
                        <br />
                        <br />
                        <br />
                        gen1_instance =["t1", "m1", "c1" , "hs1"] <br />
                        gen2_instance =["t2", "m2", "d2" , "g2"] <br />
                        gen3_instance =["m3", "r3" , "c3"] <br />
                        gen4_instance =["c4"] <br />
                        <br />
                        <br />
                        Monthly Possible savings are calculated while mapping old generation instance to the fifth generation instances. Also, instance that have been negated through UI are excluded from this report.
                    </h6>
                    <h6 style={{ marginTop: 10, fontWeight: 'bold' }}>Recommended Actions</h6>
                    <h6 style={{ marginTop: 10 }}>
                        Convert all old generation instances to the latest generation for better price and performance.
                    </h6>
                    <Box sx={{ display: 'flex' }}>
                        <Button variant="contained" size="small"
                            sx={{
                                backgroundColor: "#1672EC",
                                color: 'white',
                                textTransform: "none",
                                marginLeft: 'auto',
                                marginRight: '20px',
                                '&:hover': {
                                    backgroundColor: "#1672EC",
                                    color: "white",
                                    textTransform: "none"

                                }
                            }}>
                            Generate Report
                        </Button>
                        <IconButton aria-label="download" onClick={downloadReport}>
                            <CloudDownloadIcon />
                        </IconButton>
                    </Box>
                </Box>


                <Box style={{ width: 'fit-content', maxWidth: '100%' }}>
                    <div className="custom-table" style={{ marginTop: '10px' }}>
                        <div className="custom-table-header" >
                            {columns.map((column, index) =>
                                <div key={index} style={{ width: `${column['width']}px` }}>{column.headerName}</div>
                            )}
                        </div>

                        {list.map((item, index) =>
                            <div key={item.account_id} style={{ borderBottom: '1px solid' }}>
                                <div className="custom-table-row">
                                    {columns.map((column, ind) =>
                                        <div key={ind} style={{ width: `${column['width']}px` }}>
                                            {
                                                column['field'] != 'action' ? item[column['field']] ? item[column['field']] : '-' :
                                                    <Button variant="contained" size="small" onClick={() => handleClickOpen(item)}
                                                        sx={{
                                                            backgroundColor: "green",
                                                            color: 'white',
                                                            textTransform: "none",
                                                            fontWeight: 'bold',
                                                            '&:hover': {
                                                                backgroundColor: "green",
                                                                color: "white",
                                                                textTransform: "none"
                                                            }
                                                        }}> Convert </Button>
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </Box >
            </Box>
            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to convert old gen instance ?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormControl fullWidth size='small' sx={{ marginTop: 2 }} >
                            <InputLabel >Please select the gen type</InputLabel>
                            <Select
                                label="Please select the gen type"
                                value={instanceGen}
                                onChange={handleInstanceChange}
                            >
                                <MenuItem value={'gen1'}>Gen1</MenuItem>
                                <MenuItem value={'gen2'}>Gen2</MenuItem>
                                <MenuItem value={'gen3'}>Gen3</MenuItem>
                                <MenuItem value={'gen4'}>Gen4</MenuItem>
                                <MenuItem value={'gen5'}>Gen5</MenuItem>

                            </Select>
                        </FormControl>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={convertInstanceGenration} autoFocus sx={{
                        backgroundColor: "green",
                        color: 'white',
                        textTransform: "none",
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: "green",
                            color: "white",
                            textTransform: "none"
                        }
                    }}>
                        Convert
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default OldGenInstance
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import request from '../utils/requests';
import { showLoading, hideLoading } from '../store/actions/loader';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { Box, Button, Drawer, IconButton, TextField, Menu, MenuItem } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { MoreVert } from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function Dashboard({isAdmin , setIsAdmin}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    // const selectedId = useSelector(state => state.selectId.selectedId);
    const [drawer, setDrawer] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [dashboardList, setDashboardList] = useState('');
    // const EmailsId = ["nancy@va2pt.com", "gyanurai@va2pt.com","abhishek@va2pt.com", "siddharth@va2pt.com","abhishekchakraborty@va2pt.com"]
    // const loggedInUserEmail = globalemail


    const [dashboard, setDasboard] = useState({ dashboard_name: '', limit_of_panels: 2, no_of_columns: 2 })
    const columns = [
        {
            field: 'dashboard_name', headerName: 'Name', width: 300, sortable: false,
            renderCell: (params) => {
                return <span style={{ textDecoration:'underline', textTransform: 'capitalize'}}> {params.value}</span>
            }
        },
        {
            field: 'name', headerName: 'Created By',width: 200,sortable: false,
            renderCell: (params) => {
                const username = params.row.name.split('@')[0]; // Extract username from email
                return <span>{username}</span>;
            },
        },
        
        {
            field: 'created_at', headerName: 'Created At', width: 200, sortable: false,
            renderCell: (params) => {
                const formattedDate = moment(params.value).format("DD-MM-YYYY");
                return <span>{formattedDate}</span>;
            },
        },
        {
            field: 'updated_at', headerName: 'Updated At', width: 200, sortable: false,
            renderCell: (params) => {
                const formattedDate = moment(params.value).format("DD-MM-YYYY");
                return <span>{formattedDate}</span>;
            },
        },
       
        {
            field: "actions",
            type: "actions",
            width: 100,
            getActions: (cell, res) => {
                    if (isAdmin && cell.row.owner) {
                    return [
                        <GridActionsCellItem
                            label='Update'
                            onClick={() => openDrawer(cell.row)}
                            showInMenu
                        />,
                        <GridActionsCellItem
                            label='Delete'
                            onClick={() => deleteDashboard(cell.row)}
                            showInMenu
                        />,
                    ];
                } else {
                    return [];
               }
             },
        }
        
    ];
    
    const getCurrentUrl = () => {
    const parts = window.location.pathname.split('/');
    return parts[parts.length - 1] || 'all';}

    let currentUrl = getCurrentUrl();
    useEffect(() => {
        async function fetchDashboardData(id=null) {
            dispatch(showLoading())
            let res;
            if(currentUrl !== "" && currentUrl!=="dashboard"){
                res =  await request.get(`/dashboard/list_by_account_id/${currentUrl}/`);
            }else {
                res = await request.get(`dashboard/list/`);
            }
            setList(res?.data)
            dispatch(hideLoading())
          }
          fetchDashboardData(currentUrl);
      }, [currentUrl]);
      


    function handleRowClick(event) {
        navigate(`/dashboard/${event.row.dashboard_id}`)
    }

    function closeDrawer() {
        setDasboard({ dashboard_name: '', limit_of_panels: 2, no_of_columns: 2 })
        setIsEdit(false)
        setDrawer(false)
    };
    function handleNameChange(event) {
        setDasboard({ ...dashboard, dashboard_name: event.target.value })
    }

   
    function createDashboard() {
        dispatch(showLoading());
        if (isEdit) {
            request.put(`dashboard/update/${dashboard.dashboard_id}/`, dashboard)
                .then((res) => {
                    request.get('dashboard/list/')
                        .then((response) => {
                            setList(response.data);
                            closeDrawer();
                        })
                        .catch((error) => {
                            dispatch(hideLoading());
                            handleErrorResponse(error.response);
                        });
                    dispatch(hideLoading());
                })
                .catch((error) => {
                    dispatch(hideLoading());
                    handleErrorResponse(error.response);
                });
        } else {
            request.post('dashboard/create/', dashboard)
                .then((res) => {
                    request.get('dashboard/list/')
                        .then((response) => {
                            setList(response.data);
                            closeDrawer();
                        })
                        .catch((error) => {
                            dispatch(hideLoading());
                            handleErrorResponse(error.response);
                        });
                    dispatch(hideLoading());
                })
                .catch((error) => {
                    dispatch(hideLoading());
                    handleErrorResponse(error.response);
                });
        }
    }

    function handleErrorResponse(response) {
        if (response && response.status === 403) {
            toast.error(response.data.detail);
        } 
    }


    function deleteDashboard(dashboard) {
        dispatch(showLoading())
        request.del(`/dashboard/delete/${dashboard.dashboard_id}/`).then((res) => {
            request.get('dashboard/list/').then((response) => {
                setList(response.data)
            })
            dispatch(hideLoading())
        }).catch((error) => {
            dispatch(hideLoading())
            handleErrorResponse(error.response);

        });
    }

    function openDrawer(details) {
        if (details && details.dashboard_name) {
            setDasboard({ ...dashboard, ...details })
            setIsEdit(true)
        }
        setDrawer(true)
    }

    return (
        <>
            <Box p={'0 20px '} className="d-flex">
                {/* <Button variant="contained" size="small" startIcon={<FileUploadIcon />}
                    sx={{
                        backgroundColor: "black",
                        color: "white",
                        textTransform: "none",
                        marginLeft: 'auto',
                        '&:hover': {
                            backgroundColor: "black",
                            color: "white",
                            textTransform: "none"

                        }
                    }}>
                    Import Dashboard
                </Button> */}
                {isAdmin &&
                <Button variant="contained" size="small" startIcon={<AddIcon />} onClick={openDrawer}
                    sx={{
                        backgroundColor: "white",
                        color: "black",
                        textTransform: "none",
                        marginLeft: 'auto',
                        '&:hover': {
                            backgroundColor: "white",
                            color: "black",
                            textTransform: "none"

                        }
                    }}>
                    Create a dashboard
                </Button>
}
            </Box>
           <div className="dashboardList">
                {list.length > 0 &&
                    <DataGrid
                        p={2}
                        getRowId={(row) => row.dashboard_id}
                        rows={list}
                        columns={columns}
                        autoHeight={false}
                        onRowClick={handleRowClick}
                        hideFooterPagination={true}
                        showCellVerticalBorder={true}
                        showColumnVerticalBorder={true}
                        disableColumnMenu={true}
                        moreActionsIcon={<MoreVert />}
                        getRowClassName={() => {return 'clickable-row'}}
                        hideFooter={true} 
                    />
                    }
            </div>
            <>
                <Drawer
                    anchor={'right'}
                    open={drawer}
                >

                    <Box sx={{ width: 400, height: '100vh', display: 'flex', flexDirection: 'column' }} >
                        <IconButton onClick={closeDrawer} sx={{ marginLeft: 'auto' }} >
                            < CloseIcon />
                        </IconButton>
                        <Box sx={{ p: 2, margin: 'auto 0' }}>
                            <h5>{isEdit ? 'Update' : 'Create'} dashboard</h5>
                            <TextField label="Name" fullWidth value={dashboard.dashboard_name} onChange={handleNameChange}></TextField>
                            <Button
                                variant="contained"
                                sx={{
                                    display: 'flex',
                                    backgroundColor: "white",
                                    color: "black",
                                    textTransform: "none",
                                    marginTop: '10px',
                                    marginLeft: 'auto',
                                    '&:hover': {
                                        backgroundColor: "white",
                                        color: "black",
                                        textTransform: "none"

                                    }
                                }}
                                onClick={createDashboard}
                                disabled={!(dashboard.dashboard_name).trim()}
                            >
                                {isEdit ? 'Update' : 'Create'}
                            </Button>
                        </Box>

                    </Box>
                </Drawer>
            </>
        </>
    );
}
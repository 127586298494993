import React, { useState, useEffect } from "react";
import Card from "./Integration/Card";
import styles from "./Integration/Card.module.scss";
import AllServicesGraph from "./AllServicesGraph";
import TopServicesPieChart from "./PieChart";
import RegionChart from "./RegionChart";
import RegionPieChart from "./RegionPieChart";
import RegionPieHighChart from "./Regionpiehighchart";
import PieChartServices from "./Piechartservices";
import request from "../utils/requests";
import moment from "moment";
import Globe from "./Globe";
import AllServicesStack from "./AllServicesStack";
import AllServicesLine from "./AllServicesLine";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { BarChart } from "@mui/icons-material";
import InsightsIcon from "@mui/icons-material/Insights";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import RegionLineChart from "./RegionLinechart";
import RegionStackChart from "./RegionStackChart";
import SummaryDashboardTable from "./SummaryDashboardTable";

const SummaryDashboard = () => {
  const [forecastData, setForeCastData] = useState("");
  const [currentMonthBill, setCurrentMonthBill] = useState("");
  const [previousMonthBill, setPreviousMonthBill] = useState("");
  const [currentGraph, setCurrentGraph] = useState("BarChart");
  const [regioncurrentGraph, setregionCurrentGraph] = useState("BarChart");

  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const today = moment().format("YYYY-MM-DD");
  const [granularity, setGranularity] = useState("MONTHLY");
  const [dimension, setDimension] = useState("SERVICE");


  const startOfpreviousMonth = moment()
  .subtract(1, "months")
  .startOf("month")
  .format("YYYY-MM-DD");

const endOfMonth = moment()
  .startOf("month")
  .format("YYYY-MM-DD");

  const components = {
    StackedBarChart: <AllServicesStack />,
    BarChart: <AllServicesGraph />,
    LineChart: <AllServicesLine />,
  };

  const regionComponents = {
    StackedBarChart: <RegionChart />,
    BarChart: <RegionStackChart />,
    LineChart: <RegionLineChart />,
  };

  const handleCaseChange = (newValue) => {
    setCurrentGraph(newValue);
  };

  const handleregionCaseChange = (newValue) => {
    setregionCurrentGraph(newValue);
  };

  useEffect(() => {
    const fetchForecastData = async () => {
      try {
        const response = await request.get("/ce/forecast");
        const data =  formatNumberWithCommas(response.data.toFixed(0));
        setForeCastData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchForecastData();
  }, []);

  useEffect(() => {
    const currentMonthBill = async () => {
      try {
        const response = await request.get(
          `/ce/cost_breakdown_by_filter?start_date=${startOfMonth}&end_date=${today}&granularity=${granularity}&dimension=${dimension}`
        );

        const data = response.data;
        const totalValue = formatNumberWithCommas(data?.[Object.keys(data)[0]]?.total?.toFixed(0));
        setCurrentMonthBill(totalValue);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    currentMonthBill();
  }, []);

  useEffect(() => {
    const previousMonthBill = async () => {
      try {
        const response = await request.get(
          `/ce/cost_breakdown_by_filter?start_date=${startOfpreviousMonth}&end_date=${endOfMonth}&granularity=${granularity}&dimension=${dimension}`
        );
        const data = response.data;
        const totalValue = formatNumberWithCommas(data?.[Object.keys(data)[0]]?.total?.toFixed(0));
        setPreviousMonthBill(totalValue);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    previousMonthBill();
  }, []);

  // const difference = (
  //   ((previousMonthBill - currentMonthBill) / previousMonthBill) *
  //   100
  // ).toFixed(0);
  // const forecastdifference = (
  //   ((forecastData - previousMonthBill) / forecastData) *
  //   100
  // ).toFixed(0);

  const parseFormattedNumber = (formattedNumber) => {
    return parseFloat(formattedNumber.replace(/,/g, ''));
  };
  
  const difference = previousMonthBill && currentMonthBill
    ? (
        ((parseFormattedNumber(previousMonthBill) - parseFormattedNumber(currentMonthBill)) / parseFormattedNumber(previousMonthBill)) *
        100
      ).toFixed(0)
    : "N/A";
  
  const forecastdifference = forecastData && previousMonthBill
    ? (
      ((parseFormattedNumber(previousMonthBill) - parseFormattedNumber(forecastData)) / parseFormattedNumber(previousMonthBill)) *
        100
      ).toFixed(0)
    : "N/A";
  

  const formatNumberWithCommas = (number) => {
    const [integerPart, decimalPart] = number.split(".");
    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);
    const formattedIntegerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + (otherDigits ? "," : "") + lastThreeDigits;
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  };

  return (
    <>
      <div className={styles.top}>
        <div className={styles.medium1}>
          <div className={styles.dashboard}>
            <div className={styles.table1}>
              <Card
                title="Current Month Bill"
                number={`$ ${currentMonthBill}`}
                numbers={`${difference}`}
                icon={CalendarMonthIcon}
              />
              <Card
                title="Previous Month Bill"
                number={`$ ${previousMonthBill}`}
                icon={CalendarMonthIcon}
              />
              <Card
                title="Projected Bill of Current Month"
                number={`$ ${forecastData}`}
                numbers={` ${forecastdifference} `}
                icon={CalendarMonthIcon}
              />
            </div>

            <SummaryDashboardTable />
          </div>

          <Globe />
        </div>
        <div className={styles.summaryGraph}>
          <div className={styles.graphBox}>
            <div className={styles.icons}>
              <BarChart
                style={{ marginLeft: "10px", color: "#fff" }}
                onClick={() => handleCaseChange("BarChart")}
              />
              <InsightsIcon
                style={{ marginLeft: "20px", color: "#fff" }}
                onClick={() => handleCaseChange("LineChart")}
              />
              <StackedBarChartIcon
                style={{ marginLeft: "20px", color: "#fff" }}
                onClick={() => handleCaseChange("StackedBarChart")}
              />
            </div>

            <div>{components[currentGraph] || <div>...</div>}</div>
          </div>
          <div className={styles.graphBox}>
            <div className={styles.icons}>
              <BarChart
                style={{ marginLeft: "10px", color: "#fff" }}
                onClick={() => handleregionCaseChange("BarChart")}
              />
              <InsightsIcon
                style={{ marginLeft: "20px", color: "#fff" }}
                onClick={() => handleregionCaseChange("LineChart")}
              />
              <StackedBarChartIcon
                style={{ marginLeft: "20px", color: "#fff" }}
                onClick={() => handleregionCaseChange("StackedBarChart")}
              />
            </div>
            <div>{regionComponents[regioncurrentGraph] || <div>...</div>}</div>
          </div>
        </div>

        <div className={styles.medium}>
          <RegionPieChart />
          <TopServicesPieChart />
        </div>

        <div className={styles.medium}>
          <PieChartServices />
          <RegionPieHighChart />
        </div>
      </div>
    </>
  );
};

export default SummaryDashboard;

import { Box, Button, IconButton, TextField, Drawer, FormControl, InputLabel, Select, MenuItem, Card, Pagination } from "@mui/material"
import React, { useEffect, useState, useRef } from "react"
import { useDispatch } from "react-redux";
import request from "../utils/requests";
import { showLoading, hideLoading } from "../store/actions/loader";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from '@mui/icons-material/Delete';
import utc from 'dayjs/plugin/utc';

const TimeBasedAutoScaling = () => {
  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [page_size, setPageSize] = useState(10);
  const [list, setList] = useState([]);
  const [showFirstHandler,setShowFirstHandler] =useState(true);
  const [currentInstanceId, setCurrentInstanceId]=useState("")


  
  const [cronDetails, setCronDetails] = useState({
    ec2_instance_id: "",
    region: "",
    cron_job: '',
    action: "",
    frequency: "",
    ec2_cron_job_id:""
  });

  const [cronDetailsArray, setCronDetailsArray] = useState([]);


  useEffect(() => {
    const updateDimensions = () => {
      if (divRef.current) {
        const height = divRef.current.clientHeight;
        setDivHeight(height);
      }
    };

    window.addEventListener("resize", updateDimensions);
    updateDimensions();

    dispatch(showLoading());
    request
      .get(`/table/list/aws_ec2_list`, { page: 1, page_size: page_size })
      .then((res) => {
        if (res.data.values && res.data.values.length > 0) {
          setList(res.data.values);
          setTotalPages(res.data.total.total_count);
        }
        dispatch(hideLoading());
      })
      .catch((error) => {
        dispatch(hideLoading());
        toast.error(error.message);
      });
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  
  const fetchInstances = (page, page_size) => {
    dispatch(showLoading());
    request
      .get(`/table/list/aws_ec2_list`, { page, page_size })
      .then((res) => {
        if (res.data.values && res.data.values.length > 0) {
          setList(res.data.values);
          setTotalPages(res.data.total.total_count);
        }
        dispatch(hideLoading());
      })
      .catch((error) => {
        dispatch(hideLoading());
        toast.error(error.message);
      });
  };

  const onPageChange = (page) => {
    fetchInstances(page, page_size);
  };

  const handleClickOpen = (instance) => {
    setCronDetails((values) => ({
      ...values,
      ["ec2_instance_id"]: instance["instance_id"],
      ["region"]: instance["region"],
    }));
    setOpen(true);
  };

  const handleactionChange = (e) => {
    setCronDetails((values) => ({ ...values, ["action"]: e.target.value }));
  };

  const handleFrequencyChange = (e) => {
    setCronDetails((values) => ({ ...values, ["frequency"]: e.target.value }));
  };
  const handleTimeChange = (newValue) => {
    setCronDetails((values) => ({ ...values, ["cron_job"]: newValue }));
  };
  const columns = [
    {
      field: "instance_id",
      headerName: "Instance Id",
      width: 200,
      sortable: false,
    },
    {
      field: "instance_name",
      headerName: "Instance Name",
      width: 200,
      sortable: false,
    },
    {
      field: "instance_type",
      headerName: "Instance Type",
      width: 200,
      sortable: false,
    },
    { field: "region", headerName: "Region", width: 200, sortable: false },
    {
      field: "instance_state",
      headerName: "State",
      width: 200,
      sortable: false,
    },
    { field: "action", headerName: "Action", width: 100, sortable: false },
  ];

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCronDetails((values) => ({ ...values, [name]: value }));
  };

  function closeDrawer() {
    setCronDetails({
      ec2_instance_id: "",
      region: "",
      cron_job: "",
      action: "",
    });
    setOpen(false);
  }

  const handleActionChange = (e) => {
    setCronDetails((values) => ({ ...values, ["action"]: e.target.value }));
  };


  const setAutomation = () => {

    dispatch(showLoading());
    request

      .post("ec2_cron_jobs/create/", [...cronDetailsArray,cronDetails])
      .then((response) => {
        dispatch(hideLoading());
        toast.success("Automation created");
        closeDrawer();

        setList((prevList) =>
          prevList.map((item) =>
            item.instance_id === cronDetailsArray.ec2_instance_id
              ?  { ...item, action: cronDetailsArray.action }
              : item
          )
        );

      })
      .catch((error) => {
        dispatch(hideLoading());
        toast.error(error.message);
      });
  };

  const fetchData = async (id) => {
    if(id){
      try {
        const res = await request.get(`ec2_cron_jobs/list/?instance_id=${id}`);
        const data = Array.isArray(res?.data) && Array.isArray(res?.data[0]) ? res?.data[0] : [];
        console.log("data123",data)
        if (data.length > 0) {
          const formattedData = data.map(item => ({
            ...item,
            // cron_job: dayjs(item.cron_job).format('MM/DD/YYYY HH:mm:ss')
            cron_job: dayjs(item.cron_job).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

          }));
          setCronDetailsArray(formattedData.slice(1));
          setCronDetails(formattedData[0]);
        }      
       
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to fetch cron job data');
      }
    }
  };

  const addMoreAction = () => {
    setCronDetailsArray([...cronDetailsArray, {
      ec2_instance_id: cronDetails.ec2_instance_id,
      region: cronDetails.region,
      cron_job: '' ,
      frequency: '',
      action: '',
      ec2_cron_job_id:""

    }]);
  };

  const handleDeleteAction = (index,show=false) => {
    if(show){
     setShowFirstHandler(false);
    } else {
      setCronDetailsArray(prev => prev.filter((_, i) => i !== index));
    }
  };

  const handleRemoveAction = (id,show=false) => {
    if(show){
      setShowFirstHandler(false);
     
    }
    if(!id){
      return
    }
      dispatch(showLoading()); 
      request
        .del(`ec2_cron_jobs/delete/${id}/`)
        .then(() => {
          dispatch(hideLoading());
          toast.success("Action removed successfully");
    
          // Update the cronDetailsArray state after deletion
          setCronDetailsArray(prev => prev.filter(cron => cron.ec2_cron_job_id !== id));
                   fetchData(currentInstanceId);

   
            closeDrawer();
    

          // Handle case where the array becomes empty
          if (cronDetailsArray.length === 1) {
            closeDrawer();
          }
          
        })
        .catch((error) => {
          dispatch(hideLoading());
          toast.error(error.message);
        });
  };
 
  return (
    <>
      <div sx={{ padding: "16px" }}>
        <div ref={divRef}></div>

        {list && list.length > 0 && (
          <>
            <div
              sx={{
                maxHeight: `calc(100vh - ( 115px + ${divHeight}px ))`,
                overflow: "scroll",
              }}
            >
              <div className="universal-table">
                <div style={{ overflow: "scroll" }}>
                  <div className="universal-table-header">
                    {columns.map((column, index) => (
                      <div
                        key={index}
                        className="universal-table-header-items"
                        // style={{ width: `200px` }}
                        title={column.headerName}
                      >
                        {column.headerName}
                      </div>
                    ))}
                  </div>

                  {list.map((item, index) => (
                    <div className="universal-table-row" key={index}>
                      {columns.map((column, ind) => (
                        <div
                          key={ind}
                          className="universal-table-row-items"
                          // style={{ width: `200px` }}

                          title={item[column["field"]]}
                        >
                          {column["field"] != "action" ? (
                            column["field"] === "created_at" ||
                            column["field"] === "updated_at" ||
                            column["field"].includes("date") ? (
                              moment(item[column["field"]]).format("DD-MM-YYYY")
                            ) : item[column["field"]] ? (
                              item[column["field"]]
                            ) : (
                              "-"
                            )
                          ) : (
                            // <Button
                            //   variant="contained"
                            //   size="small"
                            //   onClick={() => handleClickOpen(item)}
                            //   sx={{
                            //     backgroundColor: "#2196F3",
                            //     color: "white",
                            //     textTransform: "none",
                            //     fontWeight: "bold",
                            //     "&:hover": {
                            //       backgroundColor: "rgb(5, 41, 105)",
                            //       color: "white",
                            //       textTransform: "none",
                            //     },
                            //   }}
                            // >
                            //   {" "}
                            //   Schedule Instance{" "}
                            // </Button>

                            <Button
                            variant="outlined"
                            onClick={(event) => {
                              event.stopPropagation();
                              setCurrentInstanceId(item["instance_id"])
                              handleClickOpen(item);

                                if (item.action === "edit") {
                                  if(item["instance_id"]){
                                    
                                    console.log("test",item["instance_id"])
                                    fetchData(item["instance_id"]);
                                  }
}
}}
                            style={{
                              marginLeft: "-10px",
                              backgroundColor: "#2196F3",
                              color: "white",
                              borderRadius: "10px",
                              border: "none",
                            }}
                          >
                            {item.action === "edit"
                              ? "Edit Instance"
                              : "Schedule Instance"}
                          </Button>
                

                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div className="d-flex mt-2">
                  <Pagination
                    count={Math.ceil(totalPages / page_size)}
                    variant="outlined"
                    shape="rounded"
                    sx={{ marginLeft: "auto" }}
                    onChange={(e, page) => onPageChange(page)}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <React.Fragment>
        <Drawer anchor={"right"} open={open}>
          <Box
            sx={{
              width: 350,
              height: 2100,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "rgb(15, 21, 53)",
              color: "#fff",
            }}
          >
            {" "}
            <IconButton
              onClick={closeDrawer}
              sx={{ marginLeft: "auto", color: "#fff" }}
            >
              <CloseIcon />
            </IconButton>
            <Box sx={{ p: 2, margin: "auto 0" }}>
              <h5>Set Schedule</h5>

              <div>
                <label className="label">Instance Id</label>
                <input
                  className="scheduling"
                  name="ec2_instance_id"
                  fullWidth
                  value={cronDetails.ec2_instance_id}
                  onChange={handleChange}
                  disabled
                ></input>
              </div>
              <div>
                <label className="label">Region</label>
                <input
                  className=" scheduling"
                  name="region"
                  fullWidth
                  value={cronDetails.region}
                  onChange={handleChange}
                  disabled
                ></input>
              </div>

            {showFirstHandler &&  <div>
<div className="timePicker">
  <LocalizationProvider dateAdapter={AdapterDayjs}>
  <div style={{display:"flex",justifyContent:"space-between", marginTop:"10px",alignItems:"center"}}>

  <label className="timelabel">Select Time</label>
  <div style={{marginTop:"-13px"}}>

  {cronDetails.cron_job && cronDetails.frequency && cronDetails.action && (
   <DeleteIcon
     onClick={() => handleRemoveAction(cronDetails.ec2_cron_job_id, true )}
     style={{ marginRight: "20px" }}
   />

)
}
<CloseIcon onClick ={() =>{handleDeleteAction(null,true)}} />
</div>
  </div>
    <TimePicker
       name="cron_job"
      //  value={cronDetails.cron_job ? dayjs(cronDetails.cron_job, "HH:mm") : null}
      // value={cronDetails.cron_job ? dayjs(cronDetails.cron_job, 'MM/DD/YYYY HH:mm:ss') : null}
      value={cronDetails.cron_job ? dayjs(cronDetails.cron_job, 'YYYY-MM-DDTHH:mm:ss.SSSZ') : null}

       onChange={handleTimeChange}
      renderInput={(params) => <input {...params} />}

    />
  
  </LocalizationProvider>
  
</div>

<label className="timelabel" htmlFor="frequency">Select Frequency</label>
<select
  label="Please Select Frequency"
  style={{ color: "#fff", marginBottom: "10px" }}
  className="inputaction"
  value={cronDetails.frequency}
  onChange={handleFrequencyChange}


>
  <option className="option" value="">
    Select Frequency
  </option>
  <option className="option" value="daily">
    Daily
  </option>
  <option className="option" value="weekends">
    Weekends
  </option>
  <option className="option" value="weekdays">
    Weekdays
  </option>
</select>

<label className="timelabel" htmlFor="action">Select Action</label>
<select
  label="Please select the action"
  value={cronDetails.action}
  onChange={handleactionChange}
  style={{ color: "#fff" }}
  className="inputaction"

>
  <option className="option" value="">
    Select Action
  </option>
  <option className="option" value="start">
    Start
  </option>
  <option className="option" value="stop">
    Stop
  </option>
</select>
</div>}  
   
              <hr style={{marginTop:"30px" ,color:"#fff"}}/>
   
              {cronDetailsArray?.map((cron, index) => (
 // <div key={index}>
<div key={cron.ec2_cron_job_id || index}>

  <div style={{display:"flex" ,  alignItems:"center", justifyContent:"space-between" , marginTop:"10px"}}>
  <h6 style={{margin:"15px 0px", color:"#afacac"}}>More Action </h6> 
  <div>
  {cron.cron_job && cron.frequency && cron.action && (

<DeleteIcon
  onClick={() => handleRemoveAction(cron.ec2_cron_job_id, true,)}
  style={{ marginRight: "20px" }}
/>

  ) 
  }

  <CloseIcon onClick={() => {handleDeleteAction(index)} }/>
</div>
  </div>
  <div>
  <div className="timePicker">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <label className="timelabel">Select Time</label>
      <TimePicker
        name="cron_job"
        // value={cron.cron_job ? dayjs(cron.cron_job, "HH:mm") : null}
        // value={cron.cron_job ? dayjs(cron.cron_job, 'MM/DD/YYYY HH:mm:ss') : null}
        value={cron.cron_job ? dayjs(cron.cron_job, 'YYYY-MM-DDTHH:mm:ss.SSSZ') : null}

        onChange={(newValue) => handleActionChange(index, 'cron_job', newValue)}

        renderInput={(params) => <TextField {...params} fullWidth />}

      />
    </LocalizationProvider>
  </div>
  

  <label  className=" timelabel" htmlFor={`frequency-${index}`}>Select Frequency</label>
  <select
    label="Please Select Frequency"
    style={{ color: "#fff", marginBottom: "10px" }}
    className="inputaction"

    value={cron.frequency} 
    onChange={(e) => handleActionChange(index, 'frequency', e.target.value)}
    inputProps={{
      name: `frequency-${index}`,
      id: `frequency-${index}`
    }}

  >
    <option className="option" value="">
      Select Frequency
    </option>
    <option className="option" value="daily">
      Daily
    </option>
    <option className="option" value="weekends">
      Weekends
    </option>
    <option className="option" value="weekdays">
      Weekdays
    </option>
  </select>

  <label className="timelabel" htmlFor={`action-${index}`}>Select Action</label>
  <select
    label="Please select the action"
    value={cron.action}

    onChange={(e) => handleActionChange(index, 'action', e.target.value)}
    inputProps={{
      name: `action-${index}`,
      id: `action-${index}`
    }}                style={{ color: "#fff" }}
    className="inputaction"
  >
    <option className="option" value="">
      Select Action
    </option>
    <option className="option" value="start">
      Start
    </option>
    <option className="option" value="stop">
      Stop
    </option>
  </select>
  </div>

  <hr style={{marginTop:"30px" ,color:"#fff"}}/>

  </div>     
          ))}




          <div>
            
            <Button
              variant="contained"
              onClick={addMoreAction}
              sx={{
                display: 'flex',
                background: 'transparent',
                color: 'white !important',
                textTransform: 'none',
                marginTop: '10px',
                marginLeft: 'auto',
                borderRadius: '15px',
                width: '125px',
                border: '1px solid rgba(255, 255, 255, 0.75) !important',

                '&:hover': {
                  background: '#007bff',
                  color: 'white !important',
                  textTransform: 'none',
                },
              }}
              disabled={cronDetailsArray.length >= 3}
            >
              Add more action
            </Button>

            
          </div>           
            
          <Button
            variant="contained"
            sx={{
              display: 'flex',
              background: 'transparent',
              color: 'white !important',
              textTransform: 'none',
              marginTop: '10px',
              marginLeft: 'auto',
              borderRadius: '15px',
              border: '1px solid rgba(255, 255, 255, 0.75) !important',
              width: 'auto',

              '&:hover': {
                background: '#007bff',
                color: 'white !important',
                textTransform: 'none',
              },
            }}
            onClick={setAutomation}
            disabled={cronDetailsArray.some(
              (cronDetails) =>
                !cronDetails.action || !cronDetails.cron_job || !cronDetails.frequency
            )}
            style={{
              background: cronDetailsArray.some(
                (cronDetails) =>
                  !cronDetails.action || !cronDetails.cron_job || !cronDetails.frequency
              )
                ? 'rgb(135 138 157)'
                : '#1672EC',
            }}
          >
            Schedule Instance
            {/* {fetchData(id)? 'Save changes' : 'Schedule Instance'} */}

                </Button>
            </Box>
          </Box>
        </Drawer>
      </React.Fragment>
    </>
  );
};

export default TimeBasedAutoScaling;

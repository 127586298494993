import React, {useEffect, useState} from "react";
import style from "./Slack.module.scss";
import { toast } from 'react-toastify';
import request from "../../utils/requests";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
    name: yup.string().required("Channel is required"),
    slackUrl: yup.string().required("Slack URL is required"),
    frequency: yup.string().required("Frequency is required"),
});

const Slack = () => {
    const [formData, setFormData] = useState({
        name: "",
        slackUrl: "",
        type: "Slack",
        frequency: ""
    });

    const [isSubmitted, setIsSubmitted] = useState(false);

    const { handleSubmit, register, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    // useEffect(() => {
    //     reset();
    // }, [reset]);

    async function handleFormData(formData) {
        try {
            console.log("Form Data:", formData);

            const postData = {
                channel: formData.name,
                created_at: "created_at",
                updated_at: "updated_at",
                type: formData.type,
                user_id: "user_id",
                integration_id: "integration_id",
                webhook_url: formData.slackUrl,
                frequency: formData.frequency,
            };

            const postResponse = await request.post('/users/integration/', postData);
            if (postResponse.status === 201 || postResponse.status === 200) {
                toast.success(postResponse.data.detail);
                setIsSubmitted(true);
            }
        } catch (error) {
            toast.error("An internal server error occcurred")
        }
    }


    const handleData = async (data) => {
        try {
            console.log("Form Data:", data);
            await handleFormData(data);
            // reset(); 
        } catch (error) {
            console.error('An internal error occurred:', error);
            toast.error("An internal error occurred");
        }
    };


    useEffect(() => {
        async function fetchData() {
            try {
                const res = await request.get(`/users/integration_list/`);
                const responseData = res.data;
                if (responseData.length > 0) {
                    const slackEntry = responseData.find(entry => entry.type === "Slack");
                    if (slackEntry) {
                        const { channel, webhook_url, frequency } = slackEntry;
                        setFormData({
                            name: channel,
                            slackUrl: webhook_url,
                            frequency: frequency
                        });
                    }
                }
            } catch (error) {
                toast.error("An error occurred while fetching data");
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        setValue("name", formData.name);
        setValue("slackUrl", formData.slackUrl);
        setValue("frequency", formData.frequency);
    }, [formData, setValue]);

    return (
        <div className={style.form}>
            <form  onSubmit={handleSubmit(handleData)}>
                <label htmlFor="name" className={style.label}>Channel</label>
                <input
                 className={`${style.multipleInput} ${errors.name && style.errorInput}`}
                 type="text"
                    name="name"
                    placeholder='Enter channel name'
                    {...register("name", { required: "Channel is required" })}
                />
                {errors.name && <p className={style.error}>{errors.name.message}</p>}


                <label htmlFor="slackUrl" className={style.label}>Slack Url</label>
                <input
                 className={`${style.multipleInput} ${errors.slackUrl && style.errorInput}`}
                 type="text"
                    name="slackUrl"
                    placeholder='Enter slack url'
                    {...register("slackUrl", { required: "Slack url is required" })}
                />
                 {errors.name && <p className={style.error}>{errors.slackUrl.message}</p>}

                 {/* <label htmlFor="type" className={style.label}>Type</label> */}
                    <input
                    className={`${style.multipleInput} ${style.type}`}
                    // type="text"
                        name="type"
                        value="Slack" 
                        // disabled // Disable editing of the type field
                        {...register("type")}

                        // {...register("type", { required: "Type is required" })}
                    />

                <label htmlFor="frequency" className={style.label}>Frequency</label>
                <select
                    name="frequency"
                    {...register("frequency", { required: "Frequency is required" })}
                    className={`${style.multipleInput} ${errors.frequency && style.errorInput}`}
                    >
                    <option className={style.option} value="">Select Frequency</option>
                    <option className={style.option} value="daily">Daily</option>
                    <option className={style.option} value="weekly">Weekly</option>
                    <option className={style.option} value="hourly">Hourly</option>
                </select>
                {errors.frequency && <p className={style.error}>{errors.frequency.message}</p>}


                {!isSubmitted && (
                    <button type="submit" className={style.button}>
                        Submit
                    </button>
                )}
              
            </form>
        </div>
    );
}

export default Slack;






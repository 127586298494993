
import React, {useEffect,useState}from 'react';
import { toast } from 'react-toastify';
import request from "../../utils/requests";
import style from "../Security/Security.module.scss"



const Time = () => {
   const [details, setDetails] = useState('');
   const fetchData = async () => {
     try {
         const response = await request.get(`/prowler/findings_over_time/`);
         setDetails(response.data);
   
     } catch (error) {
         console.error('Error fetching accountid:', error);
         toast.error("Error fetching data");

     }
   
   };
   
   useEffect(() => {
   
     fetchData();
   }, []);
  return (
    <div className={style.SeverityTable}>
           <p className={style.tableHeading}>Finding Over Time</p>

    <table cellPadding="10px" className={style.table}>
      <thead className={style.heading}>

       <th>Day</th>
         <th>Critical</th>
            <th>High</th>
            <th>Medium</th>
            <th>Low</th>
         <th> Pass Count</th>
        
      </thead>
         
           <tbody className={style.tbody}>
        {details?.length && details?.map((data, index) => (
          <tr
            key={index}>
            <td>{data.day}</td>
            <td className={style.critical}>{data.severity.critical}</td>
              <td className={style.high}>{data.severity.high}</td>
              <td className={style.mediums}>{data.severity.medium}</td>
              <td className={style.low}>{data.severity.low}</td>
            <td >{data.pass_count}</td>
       </tr>
        ))}
      </tbody>
    </table>
 
  </div>
  )
}

export default Time
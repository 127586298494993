
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { toast } from "react-toastify";
import request from "../../utils/requests";
import style from "../Security/Security.module.scss";

const StackedBarChart = () => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await request.get(`/prowler/findings_by_region/`);
        const data = res.data;
        if (Array.isArray(data) && data.length > 0) {
          const aggregatedData = aggregateData(data);
          console.log("Aggregated data:", aggregatedData);

          const options = {
            chart: {
              type: "bar",
              stacked: true,
              height: 350,
            },
            grid: {
              borderColor: "#535A6C", // Set grid border color
              strokeDashArray: 4, // Set stroke dash array for dotted lines
            },
            plotOptions: {
              bar: {
                horizontal: false, // Change to false for vertical bars
                distributed: true

              },
            },

            xaxis: {
              categories: Object.keys(aggregatedData),
              labels: {
                style: {
                  colors: "#fff", // Set X-axis label color to white
                },
                formatter: function (val) {
                  return val; // Region names
                },
                // rotate: 0, // Set rotation angle to 0 for horizontal alignment
              },
            },
            yaxis: {
              title: {
                text: "Fail Count", // Y-axis label
                style: {
                  color: "#fff",
                },
              },
              labels: {
                style: {
                  colors: "#fff",
                },
              },
            },
            legend: {
              labels: {
                  colors: '#fff', // Set legend label color to white
              },
          },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val + " fail count";
                },
              },
            },
          
          };

          const series = Object.values(aggregatedData);

          setChartData({ options, series });
        } else {
          toast.error("Data received from the API is invalid or empty.");
        }
      } catch (error) {
        toast.error("Error fetching data from the API.");
        console.error("Error fetching data from the API:", error);
      }
    }

    fetchData();
  }, []);

  // const aggregateData = (data) => {
  //   const aggregatedData = {};

  //   data.forEach((entry) => {
  //     const region = entry.region;
  //     const failCount = entry.fail_count;

  //     if (!aggregatedData[region]) {
  //       aggregatedData[region] = failCount;
  //     } else {
  //       aggregatedData[region] += failCount;
  //     }
  //   });

  //   return aggregatedData;
  // };
  const aggregateData = (data) => {
    const aggregatedData = {};
  
    data.forEach((entry) => {
      const region = entry.region;
      const failCount = entry.fail_count;
  
      if (!aggregatedData[region]) {
        aggregatedData[region] = failCount;
      } else {
        aggregatedData[region] += failCount;
      }
    });
  
    // Sort regions by fail count in descending order
    const sortedRegions = Object.keys(aggregatedData).sort(
      (a, b) => aggregatedData[b] - aggregatedData[a]
    );
  
    // Take only the top 5 regions
    const top5Regions = sortedRegions.slice(0, 5);
  
    // Create a new object with only the top 5 regions
    const top5Data = {};
    top5Regions.forEach((region) => {
      top5Data[region] = aggregatedData[region];
    });
  
    return top5Data;
  };
  

  return (
    <div className={style.pieChart}>
      <p className={style.tableHeading}> Top 5 Region Fail Count</p>

      {chartData.options && chartData.series && (
        <Chart
          options={chartData.options}
          series={[{ data: chartData.series }]}
          type="bar"
          height={350}
        />
      )}
    </div>
  );
};

export default StackedBarChart;


import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Pagination, Box, IconButton, Drawer, TextField } from "@mui/material";
import request from "../utils/requests";
import { headings, tableColumns } from "../utils/constant";
import { showLoading, hideLoading } from "../store/actions/loader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../pages/Recommendation.module.scss";


function Recommendations() {
  const { id, type } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [recommendations, setRecommendations] = useState([]);
  const [sortBy, setSortBy] = useState("desc"); // State to keep track of the column to sort by
  const [sortOrder, setSortOrder] = useState("asc"); // State to keep track of the sorting order
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState(""); // Step 1: Add search query state
  const [filteredRecommendations, setFilteredRecommendations] = useState([]); // Define filtered state
  const [open, setOpen] = useState(false);

  const handleSearch = (event) => {
    // Step 2: Update search query state
    setSearchQuery(event.target.value);
  };
  const handlePaste = (event) => {
    const pastedText = event?.clipboardData?.getData("text");
    setSearchQuery(pastedText);
    // console.log(pastedText, "pasted");
  };

  // // Step 3: Filter recommendations based on search query
  useEffect(() => {
    const filterFunction = recommendations.filter((item) =>
      Object.values(item).some(
        (value) =>
          value !== null &&
          value?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      )
    );
    setFilteredRecommendations(filterFunction);
  }, [recommendations, searchQuery]);

 

  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
    // Sorting logic for filteredRecommendations
    let sortedRecommendations = [...filteredRecommendations];
    sortedRecommendations.sort((a, b) => {
      if (a[field] === null && b[field] !== null) return 1;
      if (a[field] !== null && b[field] === null) return -1;

      if (typeof a[field] === "number" && typeof b[field] === "number") {
        return sortOrder === "asc" ? a[field] - b[field] : b[field] - a[field];
      } else {
        return sortOrder === "asc"
          ? a[field]?.localeCompare(b[field], "en-US")
          : b[field]?.localeCompare(a[field], "en-US");
      }
    });
    setFilteredRecommendations(sortedRecommendations);
  };


const handleAction = () => { 
  request
    .get(`aws_recommendation/action_detail/?type=${type}`)
    .then((res) => {
      setRecommendations(res?.data?.details);
      setFilteredRecommendations(res?.data?.details);
      
      // Map over columns and conditionally modify 'cpu_percentage'
      const col = tableColumns[type]?.map((item) => {
        if (item === "cpu_percentage") {
          return {
            field: item,
            headerName: "CPU %",
            renderCell: (params) => `${params.value} %`, // Format the value with the '%' sign
          };
        } else if (item === "saving_cost") {
          return {
            field: item,
            headerName: "Monthly Saving",
            renderCell: (params) => `$${params.value}`, // Format the value as a currency
          };
        } else {
          // For other columns, keep them unchanged
          return {
            field: item,
            headerName: item.replace(/_/g, " "),
          };
        }
      });

      setColumns(col);
    });
};



useEffect(()=>{
handleAction()
  }, [type]);

 


  const showDetails = (item) => {
    let id = "";
    switch (type) {
      case "Instance_type_conversion":
      case "Not_in_use_instances":
      case "Status_failed":
      case "Schedule_instances":
        case "Resize_instance_in_graviton":
          id = item.instance_id;
          break;
        case "Resize_auto_scaling":
          case "Resize_autoscaling_in_graviton":
          id = item.tag_value;
          break;
        case "item": 
        id = item.instance_id;
        break;
      case "Ec_redis_type_conversion":
      case "EC_redis_right_size_conversion":
      case "EC_redis_type_conversion_in same_family":
      case "Resize_EC_redis_in_graviton":
      case "Ec_memcache_type_conversion":
        case "EC_memcache_right_size_conversion":
      case "EC_memcache_type_conversion_in_same_family":
      case "Resize_EC_memcache_in_graviton":
      case "Msk_instance_type_conversion":
      case "Not_in_use_msk":
        id = item.cluster_name;
        break;
        case "Resize_rds_in_graviton":
      case "Rds_type_conversion":
        case "Rds_type_conversion_in_same_family":
      case "Zero_db_connections":
      case "Rds_type_conversion_in_graviton":
      case "Rds_right_size_conversion":
        id = item.db_instance_identifier;
        break;
      case "Not_in_use_loadbalancer":
        id = item.loadbalancer_name;
        break;
      case "Not_in_use_eip":
        id = item.public_ip;
        break;
      case "Amazon_cloudFront":
      case "Amazon_simple_storage_service":
      case "Aws_data_transfer":
        id = item.key;
        break;
        case "Ami":
          id = item.ami_id
          break;
        case "Snapshot":
          id = item.snapshot_id
          break;
      default:
        id = item.volume_id;
    }
    navigate(`/recommendations/${type}/${id}`);
  };
  const handleModify = (item) => {
    // console.log("Modify item:", item);
  };

  
  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value); // Parse selected value to integer
    setPageSize(newSize); // Update page size state
    setPage(1); // Reset to first page on page size change

  };
 
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const paginatedData = filteredRecommendations.slice((page - 1) * pageSize, page * pageSize);


  return (
    <div>        
          {/* <div
            sx={{
              maxHeight: `calc(100vh - ( 120px ))`,
              overflowY: "scroll",
              paddingRight: "15px",
            }}
          > */}
            {/* <button style={{marginBottom:"10px"}} onClick={()=>{navigate("/recommendations")}}>Back</button> */}

            <div class={styles.breadcrumb}>
              <a className={styles.breadc} href="/recommendations">
                Recommendation
              </a>
              <span className={styles.hyphen}>/</span>
              <span className={styles.type}>  {type.replace(/_/g, ' ')}</span>
            
            </div>
          
            {paginatedData.length === 0 ? (
              <div className={styles.noDataMessage}>
                <div className="universal-table">
                  <h5 className={styles.recommendationHeading}>
                    {headings[type]} Recommendation
                  </h5>
                  <div className={styles.searchBar}>
                    <div className={styles.searchnumber}>
                      <select
                        className={styles.Input}
                        id="entryperpage"
                        onChange={handlePageSizeChange} // Call handlePageSizeChange on change
                      >
                        {/* <option className={styles.option} value="">
                          All
                        </option> */}
                        <option className={styles.option} value="5">
                          5
                        </option>
                        <option className={styles.option} value="10">
                          10
                        </option>
                        <option className={styles.option} value="15">
                          15
                        </option>
                        <option className={styles.option} value="20">
                          20
                        </option>
                      </select>
                      <p>entries per page</p>
                    </div>
                    <div>
                      <input
                        type="text"
                        className={`${styles.Input} ${styles.search}`}
                        value={searchQuery}
                        onChange={(event) => handleSearch(event)}
                        placeholder="Search..."
                        onPaste={() => {
                          setSearchQuery("");
                          handlePaste();
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.tablemain}>
                    <div className="universal-table-header">
                      {columns.map((column, index) => (
                        <div
                          key={index}
                          className="universal-table-header-items"
                          // style={{ width: `${column["width"]}px` }}
                          // style={{ width: `270px` }}
                          title={column?.headerName}
                          onClick={() => handleSort(column.field)} // Add onClick handler
                        >

                          {column.headerName}
                          {sortBy === column.field && (
                            <span className={styles.ascdesc}>
                              {sortOrder === "asc" ? " ▲" : " ▼"}
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.message}>No data available</div>
                </div>
              </div>
            ) : (
              <>
                <div
                  sx={{ maxHeight: "100%", overflow: "scroll" }}
                  className={styles.recommendation}
                >
      <div className="universal-table" style={{ width: open ? '70%' : '93%' }}>
      <h5 className={styles.recommendationHeading}>
                      {headings[type]} Recommendation
                    </h5>
                    <div className={styles.searchBar}>
                      <div className={styles.searchnumber}>
                        <select
                          className={styles.Input}
                          id="entryperpage"
                          onChange={handlePageSizeChange} // Call handlePageSizeChange on change
                        >
                          <option className={styles.option} value="">
                            All
                          </option>
                          <option className={styles.option} value="5">
                            5
                          </option>
                          <option className={styles.option} value="10">
                            10
                          </option>
                          <option className={styles.option} value="15">
                            15
                          </option>
                          <option className={styles.option} value="20">
                            20
                          </option>
                        </select>
                        <p>entries per page</p>
                      </div>
                      <div>
                        {/* Search */}
                        <input
                          type="text"
                          className={`${styles.Input} ${styles.search}`}
                          value={searchQuery}
                          onChange={(event) => handleSearch(event)}
                          placeholder="Search..."
                          onPaste={() => {
                            setSearchQuery("");
                            handlePaste();
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.tablemain}>
                      <div className="universal-table-header">
                        {columns.map((column, index) => (
                          <div
                            key={index}
                            className="universal-table-header-items"
                            // style={{ width: `${column["width"]}px` }}
                            // style={{ width: `270px` }}
                            title={column?.headerName}
                            onClick={() => handleSort(column.field)} // Add onClick handler
                          >
                            {/* {column.headerName} */}

                            {column.headerName}
                            {sortBy === column.field && (
                              <span className={styles.ascdesc}>
                                {sortOrder === "asc" ? " ▲" : " ▼"}
                              </span>
                            )}
                          </div>
                        ))}
                      </div>

                      {paginatedData.map((item, index) => (
                        <div
                          className="universal-table-row clickable"
                          key={index}
                          onClick={() => showDetails(item)}
                        >
                          {columns.map((column, ind) => (
                            <div
                              key={ind}
                              // className="universal-table-row-items"
                              className={`universal-table-row-items ${
                                // Apply underline-text class to instance_id and volume_id columns
                                column.field === "instance_id" ||
                                column.field === "volume_id"  ||
                                column.field === "tag_value"
                                  ? "underline-text"
                                  : ""
                              }`}
                              // style={{ width: `${column["width"]}px` }}
                              // style={{ width: `270px` }}
                              title={item[column["field"]]}
                              onClick={() => handleSort(column.field)} // Add onClick handler
                            >
                              {item[column["field"]]
                                ? item[column["field"]]
                                : "-"}

                              {column.field === "Modify" && (
                                <Button
                                  variant="outlined"
                                  onClick={() => handleModify(item)}
                                  style={{
                                    marginLeft: "-10px",
                                    backgroundColor: "#2196F3",
                                    borderRadius: "10px",
                                    color: "white",
                                    border: "none",
                                  }}
                                >
                                  Modify
                                </Button>
                              )}
                              
                              {/* if using date , use this
                                                            column['field'] === 'created_at' ||column['field'] === 'updated_at' ||
                                                            column['field'].includes('date') ? moment(item[column['field']]).format('DD-MM-YYYY') : item[column['field']] ? item[column['field']] : '-' 
                                                            */}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>

                   
                    <div className={styles.pagination}>
          <Pagination
            count={Math.ceil(filteredRecommendations.length / pageSize)}
            variant="outlined"
            shape="rounded"
            sx={{ marginLeft: "auto"  }}
            page={page}
            onChange={handlePageChange}

          />
      </div>
                  </div>
                </div>
              </>
            )}
            {/* </div> */}

        
      </div>
  );
}

export default Recommendations;

import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import request from "../utils/requests";
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";
import styles from "./Integration/Card.module.scss";


const RegionPieHighChart = () => {
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const today = moment().format("YYYY-MM-DD");

  const [regionChartData, setRegionChartData] = useState({});
  const [granularity, setGranularity] = useState("MONTHLY");
  const [dimension, setDimension] = useState("REGION");


  const formatNumberWithCommas = (number) => {
    const [integerPart, decimalPart] = number.split(".");
    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);
    const formattedIntegerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + (otherDigits ? "," : "") + lastThreeDigits;
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  };
  const fetchData = async () => {
    try {
      const response = await request.get(
        `/ce/cost_breakdown_by_filter?start_date=${startOfMonth}&end_date=${today}&granularity=${granularity}&dimension=${dimension}`
      );
      const responseData = response.data;

      // Initialize an empty object to accumulate data from all dates
      let accumulatedData = {};

      // Loop through each date in the response
      Object.keys(responseData).forEach((date) => {
        // Loop through each region in the current date
        Object.keys(responseData[date]).forEach((region) => {
          // Exclude 'total' from the accumulation
          if (region !== "total") {
            // Check if the region exists in the accumulated data
            if (!(region in accumulatedData)) {
              // If the region doesn't exist, initialize it with the current value
              accumulatedData[region] = parseFloat(responseData[date][region]);
            } else {
              // If the region exists, add the current value to the accumulated value
              accumulatedData[region] += parseFloat(responseData[date][region]);
            }
          }
        });
      });

      // Convert the accumulated data into Highcharts series format
      const seriesData = Object.keys(accumulatedData).map((region) => ({
        name: region,
        y: accumulatedData[region],
      }));

      // Set chart data
      setRegionChartData(seriesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [granularity, dimension]);

  const options = {
    chart: {
      type: "pie",
      backgroundColor: {
        linearGradient: [0, 0, 0, 400],
        stops: [
          [0, 'rgba(6, 11, 40, 0.94)'],
          [1, 'rgba(10, 14, 35, 0.49)']
        ]
      },
      style: {
        borderRadius: '17px',
        color: 'rgba(0, 0, 0, 0.87)'
      },
      marginTop: 70, // Adjust the top margin of the chart to create space between chart and title

    },
    title: {
      text: "Current Month Region Chart",
      style: {
        color: '#fff',  
        fontFamily: "Plus Jakarta Sans",
     
      },
      x: 0, // Adjust the horizontal position
      y: 30,
      
    },
    series: [
      {
        name: "Cost",
        data: regionChartData || [],
      },
    ],
    tooltip: {
      pointFormatter: function () {
          return `  ${this.series.name}: <b> $ ${formatNumberWithCommas(this.y.toFixed(0).toString())}</b>`;
      }
  },
  plotOptions: {
      pie: {
          dataLabels: {
              enabled: true,
              formatter: function () {
                  return `${this.point.name}: $ ${formatNumberWithCommas(this.y.toFixed(0).toString())}`;
              },
          },
      },
      series: {
          borderWidth: 0, // Hide the border of the series
      },
  },
  };
  

  return (
    <div className='two-chart'>
    {regionChartData !== null ? (
        <HighchartsReact highcharts={Highcharts} options={options} className={styles.graphHeading}
        />
      ) : (
        <div className="fixed-loader">
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  );
};

export default RegionPieHighChart;

import moment from 'moment';
import request from './requests';




const serviceList = ['line_item_product_code', 'product_location', 'product_region', 'case_product_instance_type']
const costList = ['line_item_usage_amount', 'line_item_blended_cost', 'line_item_unblended_cost']

export function makeRationalData(data) {

    let desc = data?.shift().Data
    let monthIndex = desc?.findIndex(item => item.VarCharValue === 'month')
    let yearIndex = desc?.findIndex(item => item.VarCharValue === 'year')
    let dateIndex = desc?.findIndex(item => item.VarCharValue === 'line_item_usage_start_date')
    let servicesIndex = desc?.findIndex(item => item.VarCharValue.includes('product') ||
        item.VarCharValue.includes('region') ||
        item.VarCharValue.includes('line_item_usage_type') ||
        item.VarCharValue.includes('line_item_operation') ||
        item.VarCharValue.includes('case_product_instance_type') || item.VarCharValue.includes('case_product_instance_type'))
    let costIndex = desc.findIndex(item => item.VarCharValue.includes('amount') || item.VarCharValue.includes('cost'))
    let rawData = []
    rawData = data.map(item => {
        return {
            name: item.Data[servicesIndex].VarCharValue,
            cost: item.Data[costIndex].VarCharValue ? parseFloat(parseFloat(item.Data[costIndex].VarCharValue).toFixed(2)) : 0,
            date: dateIndex > -1 ? moment(item.Data[dateIndex].VarCharValue).format('DD-MM-YYYY') : `${item.Data[monthIndex].VarCharValue}-${item.Data[yearIndex].VarCharValue}`
        }
    })

    //sorting data according to date

    rawData.sort((a, b) => {
        const dateA = new Date(a['date'].split('-').reverse().join('-'));
        const dateB = new Date(b['date'].split('-').reverse().join('-'));
        return dateA - dateB;
    });

    // combining repetative data of same date

    const combinedItems = {};
    rawData.forEach(item => {
        const key = `${item.name}-${item.date}`;

        if (combinedItems[key]) {
            combinedItems[key].cost = parseFloat((parseFloat(combinedItems[key].cost) + parseFloat(item.cost)).toFixed(2));
        } else {
            combinedItems[key] = { ...item };
        }
    });


    rawData = Object.values(combinedItems)

    return rawData


}



export function giveFromToDate(type, duration) {
    const toDate = moment().format("YYYY-MM-DD");
    let fromDate
    const filters = JSON.parse(localStorage.getItem('filters')) ? JSON.parse(localStorage.getItem('filters')) : { dateFilter: { toDate: '', fromDate: '', type: '', duration: null } }
    if (type === 'days') {
        fromDate = moment().subtract(duration, 'days').format("YYYY-MM-DD")
    } else if (type === 'months') {
        fromDate = moment().subtract(duration, 'months').format("YYYY-MM-DD")
    }
    filters['dateFilter']['toDate'] = type === 'Default' ? '' : toDate
    filters['dateFilter']['fromDate'] = type === 'Default' ? '' : fromDate
    filters['dateFilter']['type'] = type
    filters['dateFilter']['duration'] = duration

    localStorage.setItem('filters', JSON.stringify(filters))
}

export function getCodeFromWindowURL(url, param) {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get(param);
};

// export async function getAthenaQueryResponse(query, isApplyFilter) {
//     let payload = { sql_template: query }
//     if (isApplyFilter) {
//         const filters = JSON.parse(localStorage.getItem('filters')) ? JSON.parse(localStorage.getItem('filters')) : { dateFilter: { toDate: '', fromDate: '', type: '', duration: null } }
//         if (filters['dateFilter']['toDate']) payload.to_date = filters['dateFilter']['toDate']
//         if (filters['dateFilter']['fromDate']) payload.from_date = filters['dateFilter']['fromDate']
//     }
//     return await request.post('/athena/cur/query/', payload)
// }
export async function getAthenaQueryResponse(query, isApplyFilter, granularity,enabled) {

    let payload = { sql_template: query }
    if (isApplyFilter) {
        const filters = JSON.parse(localStorage.getItem('filters')) ? JSON.parse(localStorage.getItem('filters')) : { dateFilter: { toDate: '', fromDate: '', type: '', duration: null } }
        if (filters['dateFilter']['toDate']) payload.to_date = filters['dateFilter']['toDate']
        if (filters['dateFilter']['fromDate']) payload.from_date = filters['dateFilter']['fromDate']
    }
    if (granularity) {
        payload.granularity = granularity;
    }
    if(granularity=="hourly"&& enabled){
        console.log(enabled,"enabled")
        return await request.post('/athena/cur/query/', payload)

    }
    if(granularity=="monthly"|| granularity=="daily") {
    return await request.post('/athena/cur/query/', payload)
    }
}


export const flattenObject = (obj) => {
    const flattened = {}
    Object.keys(obj).forEach((key) => {
        const value = obj[key]
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            Object.assign(flattened, flattenObject(value))
        } else {
            flattened[key] = value
        }
    })
    return flattened
}

export const currentUser = () => {
    return localStorage.getItem('current_user')
}


export const delay = ms => new Promise(res => setTimeout(res, ms));


const buttons = ['aws_ec2_list', 'aws_ec_memcache_metrics', 'aws_ec2_metrics', 'aws_ec2_metadata', 'aws_ec2_ri_metadata', 'aws_tags',
        'aws_ec2_recommendation', 'aws_ebs_metrics', 'aws_s3_metrics', 'aws_ec_redis_metrics', 'aws_loadbalancer_metrics', 'aws_rds_metrics', 'aws_ec_memcache_metadata',
        'aws_ec_memcache_ri_metadata', 'aws_ec_redis_metadata', 'aws_ec_redis_ri_metadata', 'aws_loadbalancer_metadata', 'aws_rds_metadata', 'aws_rds_ri_metadata', 'aws_s3_metadata'
        , 'aws_ebs_metadata', 'aws_ebs_list', 'aws_s3_list', 'aws_loadbalancer_list', 'aws_rds_list', 'aws_ec_memcache_list', 'aws_ec_redis_list', 'aws_lambda_list', 'aws_lambda_metrics', 'aws_lambda_metadata',
        'aws_eks_list', 'aws_cloudtrail_list', 'aws_elastic_ip_list', 'aws_eks_metadata', 'aws_cloudfront_list', 'aws_ecs_metadata', 'aws_ecs_list', 'aws_cloudfront_metadata', 'aws_waf_metadata', 'aws_cloudtrail_metadata',
        'aws_cloudfront_metrics', 'aws_ecs_metrics', 'aws_eks_metrics'
]

export const aws_regions = [
        {
                name: 'US East (Ohio)',
                region: 'us-east-2'
        }, {
                name: 'US East (N. Virginia)',
                region: 'us-east-1'
        },
        {
                name: 'US West (N. California)',
                region: 'us-west-1'
        },
        {
                name: 'US West (Oregon)	',
                region: 'us-west-2'
        },
        {
                name: 'Africa (Cape Town)',
                region: 'af-south-1'
        },
        {
                name: 'Asia Pacific (Hong Kong)	',
                region: 'ap-east-1'
        },
        {
                name: 'Asia Pacific (Hyderabad)',
                region: 'ap-south-2'
        },
        {
                name: 'Asia Pacific (Jakarta)	',
                region: 'ap-southeast-3'
        },
        {
                name: 'Asia Pacific (Melbourne)	',
                region: 'ap-southeast-4'
        },
        {
                name: 'Asia Pacific (Mumbai)	',
                region: 'ap-south-1'
        }, {
                name: 'Asia Pacific (Osaka)',
                region: 'ap-northeast-3'
        }, {
                name: 'Asia Pacific (Seoul)',
                region: 'ap-northeast-2'
        }, {
                name: 'Asia Pacific (Singapore)',
                region: 'ap-southeast-1'
        }, {
                name: 'Asia Pacific (Sydney)',
                region: 'ap-southeast-2'
        },{
                name: 'Asia Pacific (Tokyo)',
                region: 'ap-northeast-1'
        },{
                name: 'Canada (Central)',
                region: 'ca-central-1'
        },{
                name: 'Europe (Frankfurt)',
                region: 'eu-central-1'
        },{
                name: 'Europe (Ireland)',
                region: 'eu-west-1'
        },{
                name: 'Europe (London)',
                region: 'eu-west-2'
        },{
                name: 'Europe (Milan)',
                region: 'eu-south-1'
        },{
                name: 'Europe (Paris)',
                region: 'eu-west-3'
        },{
                name: 'Europe (Spain)',
                region: 'eu-south-2'
        },{
                name: 'Europe (Stockholm)',
                region: 'eu-north-1'
        },{
                name: 'Europe (Zurich)',
                region: 'eu-central-2'
        },{
                name: 'Israel (Tel Aviv)',
                region: 'il-central-1'
        },{
                name: 'Middle East (Bahrain)',
                region: 'me-south-1'
        },{
                name: 'Middle East (UAE)',
                region: 'me-central-1'
        },{
                name: 'South America (São Paulo)',
                region: 'sa-east-1'
        },{
                name: 'AWS GovCloud (US-East)',
                region: 'us-gov-east-1'
        },{
                name: 'AWS GovCloud (US-West)',
                region: 'us-gov-west-1'
        },
]

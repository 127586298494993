
import React from 'react';
import styles from "./Card.module.scss";

function Card(props) {
 
  return (
    <div className={styles.card}>
      <div className={styles.content}>
        <p className={styles.content}>{props.title}</p>
        <div className={styles.value}>
        <p className={styles.number}>{props.number}</p>
       {props.numbers && <p className={styles.numbers} style={{color:`${props.numbers<0 ? "red": "green"}`}}>{props.numbers}%</p>}

        </div>
      </div>
       <div className={styles.icon}>
        {React.createElement(props.icon)} {/* Render the icon using React.createElement */}
       </div>
    </div>
  );
}

export default Card;

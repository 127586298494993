
// const initialState = {
//     selectedId:"all"
// }

// const selectId = (state = initialState, action)=>{
    
//     switch(action.type){
//         case "select": return {
//             ...state,
//             selectedId: action.payload
//         };
//         default: return state
        
//     }
// }

// export default selectId


const initialState = {
    selectedId: "all",
  };
  
  const selectId = (state = initialState, action) => {
    switch (action.type) {
      case 'SELECT_ALL_ACCOUNTS':
        return {
          ...state,
          selectedId: "all", 
        };
      case 'SELECT_ACCOUNT':
        return {
          ...state,
          selectedId: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default selectId;
  
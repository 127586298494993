import React, { useEffect, useState, useRef } from "react";
import request from "../utils/requests";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../store/actions/loader";
import { toast } from "react-toastify";
import styles from "../pages/login.module.scss";
import NavigationBar from "./NavigationBar";



export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      navigate("/summaryDashboard");
    } else {
      const code = new URL(window.location.href).searchParams.get("code");
      if (code !== null) handlePostMessage(code);
    }
  }, []);


  const handlePostMessage = (code) => {
    dispatch(showLoading());
    request
      .get(`/users/linkedin_login/?code=${code}`)
      .then((res) => {
        dispatch(hideLoading());
        localStorage.setItem("access_token", res.data.access_token);
        console.log(res.data.user_id);
        localStorage.setItem("current_user", res.data.user_id);
        res.data.onboarded ? navigate("/summaryDashboard") : navigate("/onboarding");
      })
      .catch((error) => {
        dispatch(hideLoading());
        toast.error(error.message,{className:"toast-message"});
        navigate("/login");
        console.log("hello");
      });
  };


  const showPopup = () => {
    let loginPageUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKDIN_CLINT_ID}&scope=r_liteprofile%20r_emailaddress&state=12345&redirect_uri=${process.env.REACT_APP_URL}/login`;
    window.location.href = loginPageUrl;
};


  function updateEmail(e) {
    setEmail(e.target.value);
  }

  function validateEmail(email) {
    console.log("Validating email:", email);
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  
  function sentOtp() {
    request
      .post("/users/email_login/", { email: email, otp: "" })
      .then((res) => {
        localStorage.setItem("login_token", res.data.access_token);
        navigate("/verify-otp");
        if(res.status === 202){
          toast.success(res.data.message , {className:"toast-message"});
        }
      })
      .catch((error) => {
        if (error.res && error.res.status === 404) {
          // Unauthorized error (Incorrect OTP)
          // toast.error("Invalid OTP. Please try again.");
        } else {
          // Generic error message
          toast.error("An unexpected error occurred. Please try again later.",{className:"toast-message"});
        }
        // Do not navigate to '/verify-otp' here; navigation for errors is handled outside the catch block
      });
  }

  return (
   <>

    <div className={styles.login}>
    <NavigationBar/>

      <div className={styles.main}>
        <div className={styles.left}>
         
          {/* <img className={styles.image} src="https://d2qtwwg2436q9r.cloudfront.net/login+page+image-01.jpg"/> */}
          <img 
          className={styles.logo_home} 
          src="https://d2qtwwg2436q9r.cloudfront.net/va2pt-logo.png" 
          onClick={() => { navigate("/") }} 
        />
        </div>

        <div className={styles.right}>
          <div className={styles.formOuter}>
          <div className={styles.form}>
           <div className={styles.outerText}>
        <div className={styles.upperText}>
             <h3 className={styles.heading}>Nice to see you!</h3>
             <p className={styles.enter}>Enter your email and password to sign in</p>
             </div>
        </div>
             <div className={styles.labelOuter}>
             <label className={styles.label} htmlFor="email">Email</label>
             </div>
           
             <div>
              <input
              className={styles.input}
                name="email"
                type="email"
                placeholder="Enter your email..."
                value={email}
                onChange={updateEmail}
              />
              </div>
            
              <div className={styles.outerButton}> 
                 <button className={styles.button}
                onClick={sentOtp}
                disabled={!validateEmail(email)}
                style={{background:`${!validateEmail(email)? "rgb(135 138 157)" : "#1672EC" }`}}
              >

                Magic Sign In
              </button>
              </div>
              <div className={styles.continue}>
              <span className={styles.ctext}> Or continue with</span>

              </div>
              <div className={styles.outerButton}>
              <button className={styles.button}  onClick={showPopup}>
                LinkedIN
              </button>
              </div>
              
          </div>
          </div>
      </div>
      </div>
    </div>
    </>
  );
}

import React, { useRef , useState , useEffect } from 'react';
import styles from "./Recommendation.module.scss";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import request from "../utils/requests";
import Penguin from "../assets/penguin.png";
import Aws from "../assets/Aws.jpg"



const RecommendationMemoryDetail = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);
    const[apigeneratekey , setApigenerateKey] = useState("")
    const [list, setList] = useState([]);
    const [accountId, setAccountId] = useState(null);
    const [userId, setUserId] = useState(null);
    const[agent,setAgent]=useState("Ubuntu debian")


    const { id, type } = useParams();


//         const curlCommand = document.getElementById(styles.curlCommand).innerText;
//         navigator.clipboard.writeText(curlCommand).then(() => {
//           alert("Copied to clipboard!");
//         }).catch(() => {
//           alert("Failed to copy to clipboard.");
//         });
//       };

const curlCommand = `sudo curl -o /usr/local/bin/va2pt-agent https://va2pt-devops.s3.ap-south-1.amazonaws.com/va2pt-agent && cd /usr/local/bin/ && sudo chmod +x va2pt-agent && sudo ./va2pt-agent --api_key="${apigeneratekey ? apigeneratekey : 'YOUR_API_KEY_HERE'}" `
const curlcommandAmi ="gscdhswfjerfkr";

     const copyToClipboard = () => {

        if (curlCommand) {
            navigator.clipboard.writeText(curlCommand)
                .then(() => {
                    toast.success("Copied to clipboard!");
                })
                .catch((err) => {
                    console.error("Failed to copy: ", err);
                    toast.error("Failed to copy to clipboard.");
                });
        } else {
            toast.error("No text to copy.");
        }
    };



const generateKey = async (accountId, userId) => {
    try {
        const res = await request.post(
            `va2pt_agent/generate_api_key?account_id=${accountId}&user_id=${userId}`
        );
        const data = res.data;
        
        if (data.api_key) {
            setApigenerateKey(data.api_key); // Set the API key
            toast.success("API key generated successfully.");

        } else if (data.user_id) {
            // Handle case where API key is already generated
            toast.info(data.message); // Show message from response
        }
    } catch (error) {
        toast.error("An internal error occurred");
        console.error("Error occurred while generating API key:", error);
    }
};


 useEffect(() => {
    request.get(`/onboarding/lists/`).then(res => {
        setList(res.data);
        // Assuming you want to use the first account in the list
        if (res.data.length > 0) {
            setAccountId(res.data[0].account_id);
            setUserId(res.data[0].user_id);
        }
    });
}, []);

//  const generateKey =  async (accountId, userId) => {
//     try{        
//     const res = await request.post( `api_key/generate_api_key?account_id=${accountId}&user_id=${userId}`)
//     const data = res.data
//     console.log("API Response:", data); // Debugging line to check the response

//     if (data.api_key) {
//         setApigenerateKey(data.api_key); // Set the API key
//         // localStorage.setItem('api_key', data.api_key); // Save API key to local storage
//         toast.success("API key generated successfully.");
//     } else if (data.message) {
//         setApigenerateKey(data.api_key); // Set the API key
//         toast.info(data.message); // Show message from response
//     }
// }
//     catch (error) {
//         toast.error("An internal error occurred");
//       }
//  }

// useEffect(() => {
//     // Check if there's an API key in local storage
//     const savedApiKey = localStorage.getItem('api_key');
//     if (savedApiKey) {
//         setApigenerateKey(savedApiKey);
//     }

//     request.get(`/onboarding/lists/`).then(res => {
//         setList(res.data);
//         if (res.data.length > 0) {
//             setAccountId(res.data[0].account_id); 
//             setUserId(res.data[0].user_id);  
//         }
//     }).catch(error => {
//         console.error("Error fetching onboarding lists:", error);
//     });
// }, []);


  return (
    <div>
         {/* <div class={styles.breadcrumb}>
        <a className={styles.breadc} href="/recommendations">
          Recommendation
        </a>
        <span className={styles.hyphen}>/</span>
        <span className={styles.id} href="/recommendationmemory">  Memory detail </span>
      </div> */}

    
      {/* <hr/> */}
      <div className={styles.mainsection}>
        <div className={styles.sidebar}>
        <div class={styles.verticalLine}></div>
            <p className={styles.primaryText}     onClick={() => {
                setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown state
              }}>Install agent   {isDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </p>
            {isDropdownOpen && 
            // <ul>
            //     <li>Ubuntu debian</li>
            //     <li>AMI/RHEL</li>
            // </ul>
            <div className={styles.button}> 
                <button className={styles.apiKey} onClick={()=>{setAgent("Ubuntu debian")}}> <img src={Penguin}  className={styles.penguin}/>Ubuntu debian</button>
            <button className={styles.apiKey} onClick={()=>{setAgent("AMI/RHEL")}} > <img src={Aws}  className={styles.penguin}/>AMI/RHEL</button>
</div>
 }
           
        </div>
        <div className={styles.rightbar}>
            {/* {agent== "Ubuntu debian" ?  <img src={Penguin}  className={styles.penguin}/> :     <img src={Aws}  className={styles.penguin}/>} */}

            <p className={styles.primaryText}>Install the infrastructure agent for linux</p>
            <p className={styles.second}>First generate your api key and then copy the curl command</p>

            <button className={styles.apiKey} onClick={() => generateKey(accountId, userId)}> Generate API Key </button>
            <input className={styles.inputkey}
            value={apigeneratekey}
            />
             <p className={styles.secondline}>Run this command on your linux host to enable infrastructure metrics</p>

            {agent== "Ubuntu debian" ?  <div className={styles.commandBox}>
                <div className={styles.curlCommand}>
                    {curlCommand}
                  </div>
                <button className={styles.copyButton} onClick={copyToClipboard}> <CopyAllIcon /> Copy to clipboard</button>
                </div> :  <div className={styles.commandBox}>
                <div className={styles.curlCommand}>
                    {curlcommandAmi}
                  </div>
                <button className={styles.copyButton} onClick={copyToClipboard}> <CopyAllIcon /> Copy to clipboard</button>
                </div>}
           
            </div>
      </div>
    </div>

  )
}

export default RecommendationMemoryDetail
import { combineReducers } from "redux";
import isLoading from "./loader";
import DashboardList from "./Dashboards";
import selectId from "./selectedId";

const rootReducer = combineReducers({
    isLoading,
    DashboardList,
    selectId
})

export default rootReducer
import React from 'react'
import styles from "./card.module.scss"

const Card2 = (props) => {
  return (
    <div className={`${styles.card} ${props.className}`}>
    <div>{props.image}</div>
        <p className={styles.title}>{props.title}</p>
        <p className={styles.content}>{props.description}</p>
    </div>
  )
}

export default Card2
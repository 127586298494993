
import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import CircularProgress from '@mui/material/CircularProgress';
import request from '../utils/requests';
import moment from 'moment';

const PieChartServices = () => {
    const [serviceChartData, setServiceChartData] = useState(null);
    const startOfMonth = moment().startOf('month').format("YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");
    const [granularity, setGranularity] = useState('MONTHLY');
    const [dimension, setDimension] = useState('SERVICE');


    const formatNumberWithCommas = (number) => {
        const [integerPart, decimalPart] = number.split(".");
        const lastThreeDigits = integerPart.slice(-3);
        const otherDigits = integerPart.slice(0, -3);
        const formattedIntegerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + (otherDigits ? "," : "") + lastThreeDigits;
        return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
      };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await request.get(`/ce/cost_breakdown_by_filter?start_date=${startOfMonth}&end_date=${today}&granularity=${granularity}&dimension=${dimension}`);
                const data = response.data;

                // Get the first date in the response object
                const currentDate = Object.keys(data)[0];
                // Extract services and their costs for the current date
                const servicesData = data[currentDate];

                // Filter out "total"
                delete servicesData.total;

                // Extract services and their costs
                const services = Object.keys(servicesData);
                const costs = Object.values(servicesData);

                // Format data for Highcharts
                const series = services.map((service, index) => ({
                    name: service,
                    y: parseFloat(costs[index]) || 0,
                }));

                setServiceChartData(series);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);



    const options = {
        chart: {
            type: 'pie',
            backgroundColor: {
                linearGradient: [0, 0, 0, 400],
                stops: [
                  [0, 'rgba(6, 11, 40, 0.94)'],
                  [1, 'rgba(10, 14, 35, 0.49)']
                ]
              },
              style: {
                borderRadius: '17px',
                color: 'rgba(0, 0, 0, 0.87)',
              },
              marginTop: 70, // Adjust the top margin of the chart to create space between chart and title

        },
        title: {
            text: 'Current Month Service-wise Costs',
            style: {
                color: '#fff',
                fontFamily: "Plus Jakarta Sans",

              },
              x: 0, // Adjust the horizontal position
              y: 30,
        },
        series: [{
            name: 'Cost',
            data: serviceChartData || []
        }],
        tooltip: {
            pointFormatter: function () {
                return `  ${this.series.name}: <b> $ ${formatNumberWithCommas(this.y.toFixed(0).toString())}</b>`;
            }
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return `${this.point.name}: $ ${formatNumberWithCommas(this.y.toFixed(0).toString())}`;
                    },
                },
            },
            series: {
                borderWidth: 0, // Hide the border of the series
            },
        },
    };

    return (
        <div className='two-chart'>

            {serviceChartData ? (
                <HighchartsReact highcharts={Highcharts} options={options} />
            ) : (
                <div className="fixed-loader">
                    <CircularProgress color="inherit" />
                </div>
            )}
        </div>
    );

};

export default PieChartServices;

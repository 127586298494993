import { Box, Button, IconButton } from "@mui/material"
import React, { useEffect, useState, useRef } from "react"
import request from "../utils/requests"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const ComputeRemediation = (props) => {

  const [list, setList] = useState([])
  const [totalPotentialSaving, setTotalPotentialSaving] = useState([])

  useEffect(() => {
    const arr = props.list.map(item => {
      return { ...item, isExpandable: false }
    })

    setList(arr)
  }, [props.list])

  const columns = [
    { field: 'instance_id', headerName: 'Asset Id', width: 200 },
    { field: 'email', headerName: 'Asset Type', width: 200 },
    { field: 'region', headerName: 'Region', width: 200 },
    { field: 'potential_savings', headerName: 'Potential Savings', width: 200 },
    { field: 'status', headerName: 'Cost Violations', width: 200 },
    { field: 'action', headerName: 'Action', width: 200 },
    { field: 'action', headerName: 'Negate', width: 200 }
  ];

  const downloadReport = () => {
    request.get('/compute_remediation/download_csv').then(res => {
      const blob = new Blob([res.data], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Compute_Remediation.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
  }

  const toggleExpander = (index) => {
    let arr = [...list]
    arr[index].isExpandable = !arr[index].isExpandable
    setList(arr)
  }

  return (
    <>
      {props.list && props.list.length > 0 && <Box>
        <Box style={{ maxWidth: '100%', padding: '20px' }}>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <h4 style={{ fontWeight: 'bold' }}>Potential Savings: <span style={{ color: 'red' }}>${totalPotentialSaving} Monthly</span></h4>
            <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
              <IconButton aria-label="search" >
                <SearchIcon />
              </IconButton>
              <IconButton aria-label="download" onClick={downloadReport}>
                <CloudDownloadIcon />
              </IconButton>
              <IconButton aria-label="reload" >
                <ReplayIcon />
              </IconButton>
            </Box>
          </Box>
          <div className="custom-table" style={{ marginTop: '10px' }}>
            <div className="custom-table-header" >
              <div className="expander">&nbsp;</div>
              {columns.map((column, index) =>
                <div key={index} style={{ width: `${column['width']}px` }}>{column.headerName}</div>
              )}
            </div>

            {list.map((item, index) =>
              <div key={item.instance_id} style={{ borderBottom: '1px solid' }}>
                <div className="custom-table-row">
                  <div className='clickable expander' onClick={() => toggleExpander(index)}>
                    {!item.isExpandable && <ExpandMoreIcon />} {item.isExpandable && <ExpandLessIcon />}
                  </div>
                  <div className="text-ellipsis" style={{ width: `200px` }}>{item.instance_id ? item.instance_id : '-'}</div>
                  <div style={{ width: `200px` }}>{item.potential_savings ? item.potential_savings : '-'}</div>
                  <div style={{ width: `200px` }}>{item.region ? item.region : '-'}</div>
                  <div style={{ width: `200px` }}>{item.potential_savings ? item.potential_savings : '-'}</div>
                  <div style={{ width: `200px` }}> {item.cost_voilation ? item.cost_voilation : '-'}</div>
                  <div style={{ width: `200px` }}>
                    <Button variant="contained" size="small"
                      sx={{
                        backgroundColor: "green",
                        color: 'white',
                        textTransform: "none",
                        fontWeight: 'bold',
                        '&:hover': {
                          backgroundColor: "green",
                          color: "white",
                          textTransform: "none"
                        }
                      }}> Fix </Button>
                  </div>
                  <div style={{ width: `200px` }}>
                    <Button variant="contained" size="small"
                      sx={{
                        backgroundColor: "red",
                        color: 'white',
                        textTransform: "none",
                        fontWeight: 'bold',
                        '&:hover': {
                          backgroundColor: "red",
                          color: "white",
                          textTransform: "none"
                        }
                      }}> Negate </Button>
                  </div>
                </div>

                {item.isExpandable &&
                  <div className="expanded-row-items">
                    <div>
                      <p>aws:eks:cluster-name:<span>-</span></p>
                      <p>Owner:<span>-</span></p>
                      <p>BussinessProduct:<span>-</span></p>
                    </div>
                    <div>
                      <p>aws:eks:cluster-name:<span>-</span></p>
                      <p>Owner:<span>-</span></p>
                      <p>BussinessProduct:<span>-</span></p>
                    </div>
                    <div>
                      <p>aws:eks:cluster-name:<span>-</span></p>
                      <p>Owner:<span>-</span></p>
                      <p>BussinessProduct:<span>-</span></p>
                    </div>
                  </div>
                }
              </div>
            )}
          </div>

        </Box>
      </Box>}
    </>
  )
}

export default ComputeRemediation

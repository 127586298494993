import React, {useEffect,useState}from 'react';
import request from "../utils/requests";
import styles from "./Recommendation.module.scss";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";



const RecommendationTable = () => {
  const {  type } = useParams();
  const navigate = useNavigate();
  const navigateToRecommendations = (type) => {
      navigate(`/recommendations/${type}`)
      console.log(type, "type")

  }
    const [recommendation, setrecommendation] = useState([]);


    const fetchList = async () => {
        try {
            const response = await request.get(`aws_recommendation/cost_savings/`);
            setrecommendation(response.data);
        
        } catch (error) {
          toast.error("An internal error occurred");
        }
    
      };
      
      useEffect(() => {
        fetchList();
      }, []);


  return (
    <div className={styles.mainTable}>
     <table className={styles.table}>
     <thead className={styles.heading}>
     <td>
              Account Name
            </td>
            <td>
            Recommendation 
            </td>
           
            <td>
               Monthly Saving Cost
            </td>

            <td>
                Annual Saving Cost
            </td>
            <td>
              Action
              </td>
          
        </thead>
        <tbody className={styles.tbody}>
        {recommendation?.length && recommendation?.map((data, index) => (

            <tr>
              <td>
                  {data.account_name}
                </td>
                <td>
                {data.name}
                </td>
                
                <td>
                   $ {data.total_cost}
                </td>
             
                <td>
                   $ {(data.total_cost * 12 ).toFixed(2)} 
                </td>
              
               <td>
                <button className={styles.actionbutton}
                // onClick={navigateToRecommendations}
                onClick={()=>{navigateToRecommendations(data.type)}}
                > View details</button>
               </td>
                </tr>
        ))}
         <tr className={styles.totalRow}>
          <td>.</td>
        <td   style={{color:"#fff"}}>Total Monthly Cost</td>
        <td  style={{color:"#fff"}}>
            $ {recommendation.reduce((acc, curr) => acc + curr.total_cost, 0).toFixed(2)}
        </td>
        <td  style={{color:"#fff"}}>
            $ {recommendation.reduce((acc, curr) => acc + curr.total_cost * 12 , 0).toFixed(2)}
        </td>
        <td>.</td>
      </tr>
        </tbody>
     
     </table>
    </div>
  )
}

export default RecommendationTable
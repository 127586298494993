import React from 'react'
import style from "./About.module.scss"

const AboutCard = ({ image, index, heading, description }) => {
  return (
    <div className={style.aboutCard}>
        <div>
            <img className={style.icon}  src={image}/>
            {/* <Icon className={style.icon} /> */}
        </div>
        <p className={`${style.heading} ${style[`heading${index}`]}`}>{heading}</p>
        <p className={style.description}>{description}</p>
    </div>
  )
}

export default AboutCard
import { Box, Button, IconButton } from "@mui/material"
import React, { useState } from "react"
import request from "../utils/requests"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';

const IdealLoadBalancers = ({ list, updateList }) => {

    const [open, setOpen] = useState(false);
    const [lbName, setLbName] = useState('');

    const handleClickOpen = (lb_name) => {
        setLbName(lb_name)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteVolumeById = () => {
        request.del(`/load_balancer/delete/${lbName}/`).then(res => {
            toast.info(res.data.message);
            updateList('Ideal Load Bancers Report');
        })
        setOpen(false);
    }

    const columns = [
        { field: 'Region', headerName: 'Region', width: 150, sortable: false, },
        { field: 'lb_type', headerName: 'LB Type', width: 150, sortable: false, },
        { field: 'lb_name', headerName: 'LB Name', width: 150, sortable: false, },
        { field: 'public', headerName: 'public', width: 150, sortable: false, },
        { field: 'total_request_count', headerName: 'Total Request Count', width: 150, sortable: false, },
        { field: 'monthly_possible_saving', headerName: 'Monthly Possible Savings', width: 150, sortable: false, },
        { field: 'action', headerName: 'Action', width: 150, sortable: false, },

    ];

    const downloadReport = () => {
        request.get('/load_balancer/download_csv/').then(res => {
            const blob = new Blob([res.data], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Ideal_LoadBalancers.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }

    return (
        <>
            {list && list.length > 0 &&
                <Box>
                    <Box >
                        <h4 style={{ marginTop: 10, fontWeight: 'bold' }}>Ideal Load Bancers Report(Last Updated on {'date'})</h4>
                        <h6 style={{ marginTop: 30, fontWeight: 'bold' }}>Summary</h6>
                        <h6 style={{ marginTop: 10 }}>This report checks your Elastic Load Balancing configuration for load balancerthat are not actively used in the account 867467468687.<br />
                            10 of 40 load balancer appears to be ideal.<br />
                            Monthly Savings of upto <span style={{ fontWeight: 'bold' }}>USD 176.4</span> are avilable by minimizing unused load balancers.
                        </h6>
                        <h6 style={{ marginTop: 10, fontWeight: 'bold' }}>Report Criteria</h6>
                        <h6 style={{ marginTop: 10 }}>
                            A load balancer had 0 requests per day for the last 7 days ().
                        </h6>
                        <h6 style={{ marginTop: 10, fontWeight: 'bold' }}>Recommended Actions</h6>
                        <h6 style={{ marginTop: 10 }}>
                            If your load balancer has had a low request count, consider deleting your load balancer.
                            <br />
                            <br />
                            <br />
                            Total Savings of USD 0 is achived in last 12 months.
                        </h6>
                        <Box sx={{ display: 'flex' }}>
                            <Button variant="contained" size="small"
                                sx={{
                                    backgroundColor: "#1672EC",
                                    color: 'white',
                                    textTransform: "none",
                                    marginLeft: 'auto',
                                    marginRight: '20px',
                                    '&:hover': {
                                        backgroundColor: "#1672EC",
                                        color: "white",
                                        textTransform: "none"

                                    }
                                }}>
                                Generate Report
                            </Button>
                            <IconButton aria-label="download" onClick={downloadReport}>
                                <CloudDownloadIcon />
                            </IconButton>
                        </Box>
                    </Box>


                    <Box style={{ width: 'fit-content', maxWidth: '100%' }}>
                        <div className="custom-table" style={{ marginTop: '10px' }}>
                            <div className="custom-table-header" >
                                {columns.map((column, index) =>
                                    <div key={index} style={{ width: `${column['width']}px`}}>{column.headerName}</div>
                                )}
                            </div>

                            {list.map((item, index) =>
                                <div key={item.lb_name} style={{ borderBottom: '1px solid' }}>
                                    <div className="custom-table-row">
                                        {columns.map((column, ind) =>
                                            <div key={ind} style={{ width: `${column['width']}px`}}>
                                                {
                                                    column['field'] != 'action' ? item[column['field']] ? item[column['field']] : '-' :
                                                        item['total_request_count'] == 0 ?
                                                            <Button variant="contained" size="small" onClick={() => handleClickOpen(item['lb_name'])}
                                                                sx={{
                                                                    backgroundColor: "red",
                                                                    color: 'white',
                                                                    textTransform: "none",
                                                                    fontWeight: 'bold',
                                                                    '&:hover': {
                                                                        backgroundColor: "red",
                                                                        color: "white",
                                                                        textTransform: "none"
                                                                    }
                                                                }}> Delete </Button> : <Button variant="contained" size="small"
                                                                    sx={{
                                                                        backgroundColor: "green",
                                                                        color: 'white',
                                                                        textTransform: "none",
                                                                        fontWeight: 'bold',
                                                                        '&:hover': {
                                                                            backgroundColor: "green",
                                                                            color: "white",
                                                                            textTransform: "none"
                                                                        }
                                                                    }}> In-use </Button>
                                                }
                                            </div>

                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Box >
                </Box>
            }
            { list && list.length === 0 &&
                <Box >
                    <h4 style={{ marginTop: 10, fontWeight: 'bold', color: 'red' }}>No Load Balancer is here .</h4>
                </Box>
            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete this Volume?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        "lorium ipsum"
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={deleteVolumeById} autoFocus sx={{
                        backgroundColor: "red",
                        color: 'white',
                        textTransform: "none",
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: "red",
                            color: "white",
                            textTransform: "none"
                        }
                    }}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default IdealLoadBalancers
import React, { useState } from "react";
import {  useNavigate   } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import request from '../utils/requests';
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { showLoading, hideLoading } from '../store/actions/loader';


const Onboardingstep5 = ({onboardingDetails,setOnboardingDetails}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [activeStep, setStep] = useState(1)


  const stepChange = (val) => {
    if (val === 5) {
      dispatch(showLoading())
      console.log(onboardingDetails,"onboarding")
      request.post(`/onboarding/`, onboardingDetails).then((response) => {

        request.get('/users/user_dashboard_create/').then(res => {
          dispatch(hideLoading())
          navigate('/summaryDashboard')
        }).catch((error) => {
          dispatch(hideLoading())
          toast.error(error.message)
        });
      }).catch((error) => {
        dispatch(hideLoading())
        toast.error(error.message)
      });
    } else {
      setStep(activeStep + 1)
    }
  }
  
  
  return (
    <div>
     {/* step6 */}
     {/* {activeStep === 5 ? */}
              <div className="row">
                <div className="col-lg-12">
                  {/* congrats-field-content */}
                  <div className="main-step1 align">
                  <div className="congrats-field-content mb-5">
                    <div className="sec-heading-title">
                      <h2 className="welcome">Yay! Congrats you are done!</h2>
                      <span className="ptext">
                        Your first report will arive via email within 24 hours.
                      </span>
                    </div>
                    <p className="ptext">
                      {/* Feel free to react out to us at <Link to="mailto:abhishek@va2pt.com">abhishek@va2pt.com</Link>{" "} */}
                      Feel free to reach out to us at <a href="mailto:abhishek@va2pt.com">abhishek@va2pt.com</a>


                    </p>
                    {/* <Form className="form-sec-content">
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Table Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Table Name"
                          name="table_name"
                          value={onboardingDetails.table_name || ''}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Form> */}
                    <div className="btn-wrap">
                      <Button className="btn-primary onboardingbutton" onClick={() => stepChange(5)}>
                        
                        Get Started
                      </Button>
                    </div>
                  </div>
                </div>
                </div>
              </div>
    </div>
  );
};

export default Onboardingstep5;




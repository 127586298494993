import React, { useEffect, useState, useRef, lazy } from 'react';
import { Box, useTheme, IconButton, Button, Popover, Drawer, Modal, TextField, Card, Select, MenuItem, Menu, FormControl, Grid, Paper, InputLabel, Radio } from "@mui/material";
import { useDispatch } from 'react-redux';
import { showLoading, hideLoading } from '../store/actions/loader';
import LineChart from '../components/LineChart';
import DataTable from '../components/DataTable';
import request from '../utils/requests';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import AddchartIcon from '@mui/icons-material/Addchart';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material/';

import Timeago from 'react-timeago';
import { MoreVertSharp } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

import { giveFromToDate } from '../utils/functions'
import moment from 'moment';
import ApexBarChart from '../components/ApexBarChart';
import { toast } from "react-toastify";


export default function Dashboard({ isAdmin , setIsAdmin }) {
    const { id } = useParams()
    const [panelList, setPanelList] = useState([])
    const dispatch = useDispatch();
    const [isDragEdit, setIsDragEdit] = useState(false)
    const [isSinglePanelEdit, setIsSinglePanelEdit] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [dateFilterType, setDateFilterType] = useState({ type: '' });
    const [drawer, setDrawer] = useState(false);
    const [modal, setModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [query, setQuery] = useState('');
    const [curQuery, setCurQuery] = useState('');
    const [isAddToDashboard, setIsAddToDashboard] = useState(false)
    const [dashboardList, setDashboardList] = useState([])
    const [panelObj, setPanelObj] = useState({
        panel_name: "",
        graph_type: "bar",
        panel_row_no: 2,
        panel_column_no: 3,
        dashboard_id: Number(id),
        query: ''
    })
    const [dateFilters, setDateFilters] = useState({
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD')
    })
    const [granularity, setGranularity] = useState('monthly');
    const [enabled, setEnabled] = useState(false); 


    const [selectedPanelId, setSelectedPanelId] = useState(null)

    const [dashboardName, setDashboardName] = useState('')
    // const EmailsId = ["nancy@va2pt.com", "gyanurai@va2pt.com", "abhishek@va2pt.com", "siddharth@va2pt.com", "abhishekchakraborty@va2pt.com"]
    // const loggedInUserEmail = globalemail


    
    useEffect(() => {
        dispatch(showLoading())
        const filters = JSON.parse(localStorage.getItem('filters'))
        if (filters) {
            if (filters['dateFilter']['type'] === 'Custom') {
                setDateFilters(({ ...dateFilters, toDate: filters['dateFilter']['toDate'], fromDate: filters['dateFilter']['fromDate'] }))
                setDateFilterType({ type: 'Custom', toDate: filters['dateFilter']['toDate'], fromDate: filters['dateFilter']['fromDate'] })
            } else {
                applyFilter(filters['dateFilter']['type'], filters['dateFilter']['duration'], true)
            }
        } else {
            setDateFilterType({ type: `Default` })
            giveFromToDate('Default')
        }
        request.get(`/panel/list/dashboard/${id}/`).then((response) => {
            setPanelList(response.data)
            request.get(`/dashboard/list/`).then((res) => {
                setDashboardName(res.data.find(val => val.dashboard_id == id).dashboard_name)
                setDashboardList(res.data)
                dispatch(hideLoading())
            }).catch((error) => {
                dispatch(hideLoading())
            });
        }).catch((error) => {
            dispatch(hideLoading())
        });
    }, [id])

    const style_modal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column'
    };

    const timeFilterStyle = {
        choiceIsMade: {
            padding: '5px 10px',
            color: 'white',
            backgroundColor: '#1976d2'
        },
        none: {
            padding: '5px'
        }
    }

    const date_input_style = {
        width: '150px',
        padding: '5px',
        margin: 'auto',
        borderRadius: '3px',
        border: '1px solid'
    }

    const openPopUp = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closePopUp = () => {
        setAnchorEl(null);
    };

    const handlePanelObjChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'dashboard_id') {
            setPanelObj(values => ({ ...values, [name]: Number(value) }))
        } else {
            setPanelObj(values => ({ ...values, [name]: value }))
        }
    };

    async function addPanelToDashboard() {
        dispatch(showLoading())
        try {
            if (isSinglePanelEdit) {
                if (panelObj.dashboard_id == id) {
                    await request.put(`/panel/update/${selectedPanelId}/`, panelObj)
                } else {
                    await request.post(`/panel/create/`, panelObj)
                    await request.del(`/panel/delete/${selectedPanelId}/`)
                }
            } else {
                await request.post(`/panel/create/`, panelObj)
            }
            let res = await request.get(`/panel/list/dashboard/${id}/`)
            setPanelList(res.data)
            setDrawer(false)
            setIsAddToDashboard(false)
            setPanelObj({
                panel_name: "",
                graph_type: "bar",
                panel_row_no: 2,
                panel_column_no: 3,
                dashboard_id: Number(id),
                query: ''
            })
            setQuery('')
            setCurQuery('')
            dispatch(hideLoading())

        } catch (error) {
            dispatch(hideLoading())
            handleErrorResponse(error.response);

        }
    }
    function handleErrorResponse(response) {
        if (response && response.status === 403) {
            toast.error(response.data.detail);
        }
    }

    function updatePanel() {
        setIsDragEdit(!isDragEdit)
        // update panel api call
    }

    function closeDrawer() {
        setDrawer(false)
    };

    function openModal() {
        closeDrawer();
        setModal(true);
    };

    function handleClear() {
        setQuery('');
        setCurQuery('');
    }

    function addToDashboard() {
        setPanelObj(values => ({ ...values, query: curQuery }))
        setModal(false)
        setIsAddToDashboard(true)
        setDrawer(true)
        dispatch(hideLoading())
    }

    function resetToDefault() {
        setDrawer(false)
        setModal(false)
        setShowModal(false)
        setIsSinglePanelEdit(false)
        setIsAddToDashboard(false)
        setPanelObj({
            panel_name: "",
            graph_type: "bar",
            panel_row_no: 2,
            panel_column_no: 3,
            dashboard_id: Number(id),
            query: ''
        })
        setQuery('')
        setCurQuery('')
    }
   
    const handleGranularityChange = (event) => {
        const selectedGranularity= event.target.value
        setGranularity(selectedGranularity);
        
        // Check if the selected granularity is "hourly"
        if (selectedGranularity === "hourly") {
            setShowModal(true);
        } 
    };
    const handleEnableButtonClick = () => {
        setGranularity("hourly")
        setEnabled(true); 
        setShowModal(true);
        setShowModal(false);
    };

    function applyFilter(type, duration, reload) {
        if (type === 'days') {
            setDateFilterType({ ...dateFilterType, type: `${duration} Days` })
            giveFromToDate(type, duration)
        } else if (type === 'months') {
            setDateFilterType({ ...dateFilterType, type: `${duration} Months` })
            giveFromToDate(type, duration)
        } else if (type === 'Default') {
            setDateFilterType({ ...dateFilterType, type: `Default` })
            giveFromToDate(type)
        } else {
            const filters = JSON.parse(localStorage.getItem('filters')) ? JSON.parse(localStorage.getItem('filters')) : { dateFilters: { toDate: '', fromDate: '', type: '', duration: null, type: granularity } }
            filters['dateFilter'] = { ...dateFilters, type: granularity, }
            filters['dateFilter']['type'] = 'Custom'
            filters['dateFilter']['duration'] = null
            localStorage.setItem('filters', JSON.stringify(filters))
        }
        closePopUp()
        if (!reload) {
            window.location.reload()
        }
    }


    function customFilter() {
        setDateFilterType({ ...dateFilterType, type: `Custom` })
        setDateFilters({
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD')
        })
    }

    // function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    //     const nextState = swap(panelList, sourceIndex, targetIndex);
    //     setPanelList(nextState);
    // }

    function runQuery() {
        setQuery(query)
        setCurQuery(query)
    }

    function deletePanelFromDashboard(panel) {
        dispatch(showLoading())
        request.del(`/panel/delete/${panel.panel_id}/`).then((response) => {
            request.get(`/panel/list/dashboard/${panel.dashboard_id}/`).then((response) => {
                dispatch(hideLoading())
                setPanelList(response.data)
            }).catch((error) => {
                dispatch(hideLoading())
                handleErrorResponse(error.response);

            });
        })
    }

    async function editPanelButton(panel) {
        setPanelObj(val => ({ ...val, ...panel }))
        let query = (await request.get(`/athena/cur/query/${panel.athena_sql_id}`)).data.sql_template
        setQuery(query)
        setCurQuery(query)
        setIsSinglePanelEdit(true)
        setSelectedPanelId(panel.panel_id)
        openModal()

    }

    const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
            setModal(false)
            setShowModal(false)
        }
    };
    const handlepopup = (event) => {
        if (event.key === 'Escape') {
            closePopUp()
        }
    };


    return (
        <>
            <Box className='custom-height' p="20px" >
                <Box style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                    <h5 style={{ textTransform: 'capitalize', margin: 0 }}>{dashboardName}</h5>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Box>
                            <FormControl fullWidth size='small'
                                sx={{
                                    backgroundColor: "white",
                                    width: "100px",
                                    height: "34px",
                                    boxShadow: "250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                    borderRadius: '4px',
                                    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                                    '&:hover': {
                                        backgroundColor: "white",
                                        textTransform: "none"

                                    }
                                }}>
                                <InputLabel sx={{
                                    color: "black",
                                    fontSize: "0.75rem"
                                }}>Granularity</InputLabel>
                                <Select
                                    label="Granularity"
                                    name='text'
                                    value={granularity}
                                    onChange={handleGranularityChange}
                                >
                                    <MenuItem value={"monthly"} >Monthly</MenuItem>
                                    <MenuItem value={"daily"} >Daily</MenuItem>
                                    <MenuItem value={"hourly"} >Hourly</MenuItem>
                                </Select>
                                < Modal
                                    open={showModal}
                                    onClose={() => setShowModal(false)} 
                                    // onClose={handleModalClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style_modal} onKeyDown={handleKeyPress} tabIndex={0} >
                                        <div>
                                            <IconButton onClick={resetToDefault} sx={{ position: 'absolute', top: 25, right: 30 }} >
                                                < CloseIcon />
                                            </IconButton>
                                            <h4>Adjust dates for hourly granularity</h4>
                                        </div>

                                        <h6>Hourly granularitry will reduce your dataset to your past 14 days of usage</h6>
                                        <Box display={'flex'} marginTop={2}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    textTransform: "none",
                                                    marginLeft: 'auto',
                                                    '&:hover': {
                                                        backgroundColor: "white",
                                                        color: "black",
                                                        textTransform: "none"
                                                    }
                                                }}
                                                onClick={() => setShowModal(false)}
                                            >
                                                Cancel
                                            </Button>

                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: '#0047AB',
                                                    color: 'white',
                                                    textTransform: "none",
                                                    marginLeft: '10px',
                                                    '&:hover': {
                                                        backgroundColor: "white",
                                                        color: "black",
                                                        textTransform: "none"

                                                    }
                                                }}
                                                onClick={handleEnableButtonClick}

                                            >
                                                Enable
                                            </Button>
                                        </Box>

                                    </Box>
                                </Modal >

                            </FormControl>
                        </Box>

                        <Button variant="contained" startIcon={<AccessTimeIcon />} onClick={openPopUp}
                            onKeyDown={handlepopup} tabIndex={0}
                            sx={{
                                justifyContent: 'flex-start',
                                width: '280px',
                                backgroundColor: "white",
                                color: "black",
                                textTransform: "none",
                                marginLeft: 'auto',
                                '&:hover': {
                                    backgroundColor: "white",
                                    color: "black",
                                    textTransform: "none"

                                }
                            }}>

                            {dateFilterType.type == 'Custom' ? `${dateFilterType.type} ${dateFilterType.fromDate} - ${dateFilterType.toDate}` : dateFilterType.type}
                        </Button>

                        {/* {isDragEdit ?
                        <Button variant="contained" size="small" startIcon={<DoneIcon />}
                            onClick={updatePanel}
                            sx={{
                                backgroundColor: "white",
                                color: "black",
                                textTransform: "none",
                                marginLeft: '10px',
                                '&:hover': {
                                    backgroundColor: "white",
                                    color: "black",
                                    textTransform: "none"

                                }
                            }}>
                            Done Editing
                        </Button>
                        :
                        <IconButton aria-label="Setup panel" sx={{ marginLeft: '10px', borderRadius: '3px', background: 'rgba(255, 255, 255, 0.08)' }} size="small" onClick={() => setIsDragEdit(!isDragEdit)} >
                            <EditIcon />
                        </IconButton>} */}

                        <Button variant="contained" startIcon={<AddIcon />} onClick={() => openModal()}
                            sx={{
                                backgroundColor: "white",
                                color: "black",
                                textTransform: "none",
                                '&:hover': {
                                    backgroundColor: "white",
                                    color: "black",
                                    textTransform: "none"

                                }
                            }}>
                            Add a panel
                        </Button>

                    </div>
                </Box>

                {/* {
                    isDragEdit && <GridContextProvider onChange={onChange}>
                        <GridDropZone
                            id="items"
                            boxesPerRow={2}
                            rowHeight={100}
                            style={{ height: "calc(100% - 50px)", marginTop: 20, backgroundColor: `rgba(0,0,0,0.1)`, overflowY: 'auto', display: 'flex', flexWrap: 'wrap', }}
                        >
                            {panelList.map(panel => (
                                <GridItem key={panel.panel_id} style={{ padding: '10px', width: '50%', }}>
                                    <Card
                                        sx={{ p: 2, cursor: 'grab' }}
                                    >
                                        <Box sx={{ display: 'flex' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <b className='text-capitalize'>{panel.panel_name}</b>
                                                <small><Timeago date={panel.created_at} /></small>
                                            </Box>
                                        </Box>
                                        <Box sx={{ height: 350, width: 'auto', position: 'relative', overflowX: 'scroll' }} >
                                            {panel.graph_type === 'bar' && <BarChart query={panel.athena_sql_id} filter={true} />}
                                        {panel.graph_type === 'bar' && <ApexBarChart query={panel.athena_sql_id} filter={true} />}
                                            
                                            {panel.graph_type === 'line' && <LineChart query={panel.athena_sql_id} />}
                                            {panel.graph_type === 'table' && <DataTable query={panel.athena_sql_id} />}
                                        </Box>
                                    </Card>
                                </GridItem>
                            ))}
                        </GridDropZone>
                    </GridContextProvider>
                } */}
                {
                    !isDragEdit &&
                    <Box
                        id="items"
                        style={{ height: "calc(100% - 50px)", marginTop: '20px', display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', overflowY: 'auto' }}
                    >
                        {panelList.map(panel => {
                            return <Box key={panel.panel_id} style={{ padding: '10px', width: '100%', }}>
                                <Card
                                    sx={{ p: 2 }}
                                >
                                    <Box sx={{ display: 'flex' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <b className='text-capitalize'>{panel.panel_name}</b>
                                            <b>Panel id: {panel.athena_sql_id}</b>
                                            <small><Timeago date={panel.created_at} /></small>
                                        </Box>
                                        {isAdmin && panel.owner &&

                                            <Box sx={{ marginLeft: 'auto' }}>

                                                <IconButton aria-label="edit" size="medium" onClick={() => { editPanelButton(panel) }}>
                                                    <EditIcon fontSize="inherit" />
                                                </IconButton>
                                                <IconButton aria-label="delete" size="medium" onClick={() => { deletePanelFromDashboard(panel) }}>
                                                    <DeleteIcon fontSize="inherit" sx={{ color: '#eb0f0f' }} />
                                                </IconButton>
                                            </Box>
                                        }

                                    </Box>
                                    <Box sx={{ height: 350, width: '100% ', position: 'relative' }}>
                                        {/* {panel.graph_type === 'bar' && <BarChart query={panel.athena_sql_id} filter={true} />} */}
                                        {panel.graph_type === 'bar' && <ApexBarChart query={panel.athena_sql_id} filter={true} granularity={granularity} enabled={enabled} 
                                        setEnabled={setEnabled} />}
                                        {panel.graph_type === 'line' && <LineChart query={panel.athena_sql_id} />}
                                        {panel.graph_type === 'table' && <DataTable query={panel.athena_sql_id} />}
                                    </Box>
                                </Card>
                            </Box>
                        })}
                    </Box>
                }
            </Box >
            {/* Drawer */}
            < Drawer
                anchor={'right'}
                open={drawer}
            >
                <Box sx={{ width: 400, height: '100vh', display: 'flex', flexDirection: 'column' }} >
                    <IconButton onClick={resetToDefault} sx={{ marginLeft: 'auto' }} >
                        < CloseIcon />
                    </IconButton>
                    {
                        !isAddToDashboard && <Box m={'auto'}>
                            <h5>Add a panel to your Dashboard</h5>
                            <Box onClick={openModal} border={1} borderRadius={1} p={2}>
                                <AddchartIcon /> Add a chart
                                <p>Use the query builder to see whats happening with your data.</p>

                            </Box>
                        </Box>
                    }
                    {
                        isAddToDashboard && <Box m={2}>
                            <h5>Copy to a Dashboard</h5>
                            <p>Select a dashboard where you would like to add the panel.</p>
                            <TextField label='Panel title' name='panel_name' variant="outlined" value={panelObj.panel_name} onChange={handlePanelObjChange} size='small'></TextField>
                            <h5>Select an existing dashboard</h5>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Dashboard Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ minHeight: '300px' }}>
                                    {dashboardList.map((row) => (
                                        <TableRow
                                            key={row.dashboard_id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>
                                                <Radio
                                                    checked={panelObj.dashboard_id === row.dashboard_id}
                                                    onChange={handlePanelObjChange}
                                                    value={row.dashboard_id}
                                                    name="dashboard_id"
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.dashboard_name}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Button variant="contained" size="small" onClick={() => {
                                setDrawer(false);
                                setIsAddToDashboard(false)
                            }}
                                sx={{
                                    backgroundColor: "white",
                                    color: "black",
                                    textTransform: "none",
                                    marginLeft: 'auto',
                                    '&:hover': {
                                        backgroundColor: "white",
                                        color: "black",
                                        textTransform: "none"

                                    }
                                }}>
                                Cancel
                            </Button>
                            <Button variant="contained" size="small" onClick={addPanelToDashboard}
                                sx={{
                                    backgroundColor: "white",
                                    color: "black",
                                    textTransform: "none",
                                    marginLeft: '10px',
                                    '&:hover': {
                                        backgroundColor: "white",
                                        color: "black",
                                        textTransform: "none"
                                    }
                                }}>
                                {isSinglePanelEdit ? 'Update dashboard' : 'Add dashboard'}
                            </Button>
                        </Box>
                    }
                </Box>

            </Drawer >

            {/* model */}
            < Modal
                open={modal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style_modal} onKeyDown={handleKeyPress} tabIndex={0}>
                    <IconButton onClick={resetToDefault} sx={{ position: 'absolute', top: 10, right: 10 }} >
                        < CloseIcon />
                    </IconButton>
                    <h6>Add a panel</h6>
                    <TextField fullWidth placeholder='Enter query here' value={query}
                        onChange={(e) => setQuery(e.target.value)} multiline
                        rows={4} />

                    <Box display={'flex'} marginTop={2}>
                        <Button
                            variant="contained"
                            onClick={handleClear}
                            sx={{
                                backgroundColor: "white",
                                color: "black",
                                textTransform: "none",
                                marginLeft: 'auto',
                                '&:hover': {
                                    backgroundColor: "white",
                                    color: "black",
                                    textTransform: "none"
                                }
                            }}
                        >
                            Clear
                        </Button>

                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: query != curQuery ? '#33B864' : '#efefef',
                                color: query != curQuery ? 'white' : 'black',
                                textTransform: "none",
                                marginLeft: '10px',
                                '&:hover': {
                                    backgroundColor: "white",
                                    color: "black",
                                    textTransform: "none"

                                }
                            }}
                            onClick={runQuery}
                            disabled={!(query) || query == curQuery}

                        >
                            Run
                        </Button>
                    </Box>
                    {
                        curQuery && <Box className="d-flex">
                            <Card sx={{ height: 350, width: '100% ', marginTop: 2, position: 'relative' }} >
                                {panelObj.graph_type === 'bar' && <ApexBarChart query={curQuery} filter={true} />}
                                {panelObj.graph_type === 'line' && <LineChart query={curQuery} />}
                                {panelObj.graph_type === 'table' && <DataTable query={curQuery} />}
                            </Card>
                            < Card sx={{ padding: 2, marginTop: 2, marginLeft: 'auto', display: 'flex', flexDirection: 'column' }}>
                                <TextField label='name' name='panel_name' variant="outlined" value={panelObj.panel_name} onChange={handlePanelObjChange} size='small'></TextField>
                                <FormControl fullWidth size='small' sx={{ marginTop: 2 }}>

                                    <Select
                                        labelId="graph-type-select-label"
                                        value={panelObj.graph_type}
                                        name='graph_type'
                                        onChange={handlePanelObjChange}


                                    >
                                        <MenuItem value={'bar'}>Bar</MenuItem>
                                        <MenuItem value={'line'}>Line</MenuItem>
                                        <MenuItem value={'table'}>Table</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button variant="contained" size="large" onClick={() => addToDashboard()} disabled={!panelObj.panel_name}
                                    sx={{
                                        backgroundColor: "white",
                                        color: "black",
                                        textTransform: "none",
                                        marginLeft: 'auto',
                                        marginTop: 'auto',
                                        '&:hover': {
                                            backgroundColor: "white",
                                            color: "black",
                                            textTransform: "none"

                                        }
                                    }}>
                                    {isSinglePanelEdit ? 'Save' : 'Add'}
                                </Button>
                            </Card>
                        </Box>
                    }
                </Box>
            </Modal >

            {/* dateFilterType */}
            < Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={closePopUp}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }
                }
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

            >
                <Box sx={{ width: '600px', display: 'flex' }} >
                    <Box sx={{ borderRight: '1px solid', width: '200px' }} >
                        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', borderBottom: '1px solid' }}>
                            <span onClick={() => applyFilter('Default')} style={dateFilterType.type === 'Default' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>Default </span>
                            {/*<span onClick={() => applyFilter('mins', 5)} style={ dateFilterType.type === '5 Minutes' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>5 minutes</span>
                            <span onClick={() => applyFilter('mins', 30)} style={ dateFilterType.type === '30 Minutes' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>30 minutes</span>
                            <span onClick={() => applyFilter('mins', 60)} style={ dateFilterType.type === '60 Minutes' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>60 minutes</span>
                        </Box>
                        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', borderBottom: '1px solid', }}>
                            <span onClick={() => applyFilter('hours', 3)} style={ dateFilterType.type === '3 Hours' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>3 hours</span>
                            <span onClick={() => applyFilter('hours', 6)} style={ dateFilterType.type === '6 Hours' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>6 hours</span>
                            <span onClick={() => applyFilter('hours', 12)} style={ dateFilterType.type === '12 Hours' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>12 hours</span>
                            <span onClick={() => applyFilter('hours', 24)} style={ dateFilterType.type === '24 Hours' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>24 hours</span>
                             */}
                        </Box>
                        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', borderBottom: '1px solid', }}
                            onKeyDown={handlepopup} tabIndex={0}
                        >
                            <span onClick={() => applyFilter('days', 1)} style={dateFilterType.type === '1 Days' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>1 days</span>
                            <span onClick={() => applyFilter('days', 3)} style={dateFilterType.type === '3 Days' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>3 days</span>
                            <span onClick={() => applyFilter('days', 7)} style={dateFilterType.type === '7 Days' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>7 days</span>
                            <span onClick={() => applyFilter('days', 15)} style={dateFilterType.type === '15 Days' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>15 days</span>
                        </Box>
                        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', borderBottom: '1px solid', }}>
                            <span onClick={() => applyFilter('months', 1)} style={dateFilterType.type === '1 Months' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>1 months</span>
                            <span onClick={() => applyFilter('months', 3)} style={dateFilterType.type === '3 Months' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>3 months</span>
                            <span onClick={() => applyFilter('months', 6)} style={dateFilterType.type === '6 Months' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>6 months</span>
                        </Box>
                        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', }}>
                            <span onClick={() => customFilter()} style={dateFilterType.type === 'Custom' ? timeFilterStyle.choiceIsMade : timeFilterStyle.none}>Set Custom  <ArrowRightIcon /> </span>
                        </Box>

                    </Box>
                    {
                        dateFilterType.type === 'Custom' && <Box sx={{ p: 2, width: '440px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <h5 style={{ marginBottom: 0 }}>Custom</h5>
                                {/* <span style={{ color: 'blue', marginLeft: 'auto' }}>Reset to now</span> */}
                            </Box>
                            <Box sx={{ marginTop: '10px' }}>
                                <Box sx={{ display: 'flex' }}>
                                    <input type='date' max={new Date().toISOString().split('T')[0]} style={date_input_style} name='fromDate' value={dateFilters.fromDate} onChange={e => { setDateFilters(values => ({ ...values, fromDate: e.target.value })) }} />
                                    <input type='date' max={new Date().toISOString().split('T')[0]} style={date_input_style} name='toDate' value={dateFilters.toDate} onChange={e => setDateFilters(values => ({ ...values, toDate: e.target.value }))} />
                                </Box>
                                <Box sx={{ display: 'flex', mt: 2 }}>
                                    <Button variant="contained" size="small" onClick={() => { applyFilter('Default'); setDateFilters({ fromDate: '', toDate: '' }); closePopUp() }}
                                        sx={{
                                            backgroundColor: "white",
                                            color: "black",
                                            textTransform: "none",
                                            marginLeft: 'auto',
                                            '&:hover': {
                                                backgroundColor: "white",
                                                color: "black",
                                                textTransform: "none"

                                            }
                                        }}>
                                        Cancel
                                    </Button>
                                    <Button variant="contained" size="small" onClick={applyFilter}
                                        sx={{
                                            backgroundColor: "white",
                                            color: "black",
                                            textTransform: "none",
                                            marginLeft: '10px',
                                            '&:hover': {
                                                backgroundColor: "white",
                                                color: "black",
                                                textTransform: "none"
                                            }
                                        }}>
                                        Apply
                                    </Button>
                                </Box>

                                <Box>
                                    {/* <p style={{ textAlign: 'center' }}>Recent</p> */}
                                </Box>
                            </Box>

                        </Box>






                    }
                </Box>
            </Popover >
        </>
    );
};


import React, {useEffect,useState}from 'react';
import request from "../../utils/requests";
import styles from "./Actionlogs.module.scss";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";



const Auditlogs = () => {
  const service_name=['ec2', 'ebs', 'loadbalancer', 'rds' , 'elastic cache redis', 'elastic cache memcache', 'elastic ip', 'msk']
   // const service_name=   ['ec2']


    const [auditlogs, setauditlogs] = useState([]);
    const [selectedService, setSelectedService] = useState(service_name[0]); 


    const fetchList = async () => {
        try {
            const response = await request.get(`aws_recommendation/audit_logs/?service_name=${selectedService}`);
            setauditlogs(response.data);
        
        } catch (error) {
          toast.error("An internal error occurred");
        }
    
      };
      
      useEffect(() => {
        fetchList(selectedService); // Fetch logs for the initially selected service
      }, [selectedService]);

      const handleServiceChange = (event) => {
        setSelectedService(event.target.value); // Update the selected service
      };


return(
    <>
<div className={styles.uppersection}>
<select value={selectedService} onChange={handleServiceChange} className={styles.dropdown}>
  {service_name.map((service, index) => (
    <option className={styles.option} key={index} value={service}>
      {service}
    </option>
  ))}
</select>
<h3 className={styles.heading}>Select a services</h3>
</div>

   
    <div className={styles.mainTable}>
     <table className={styles.table}>
     <thead className={styles.heading}>
     <td>
               Instance Id
            </td>
            <td>
            Instance Type
 
            </td>
           
            <td>
               Right Size
            </td>

            <td>
            Region
            </td>
            <td>
              Account alias
              </td>
              <td>
              Saving cost
              </td>
              <td>
              Current cost
              </td>
              <td>Action</td>
          
        </thead>
        <tbody className={styles.tbody}>
        {auditlogs?.length && auditlogs?.map((data, index) => (

            <tr>
              <td>
                  {data.instance_id}
                </td>
                <td>
                {data.current_instance_type}
                </td>
                
                <td>
                    {data.right_size}
                </td>
             
                <td>
                    {data.region} 
                </td>
                 
                <td>
                    {data.account_alias} 
                </td>
                <td>
                    {data.saving_cost} 
                </td>
                <td>
                    {data.current_cost} 
                </td>
                <td>
             
   
   <i className={`${data.action === 'Take action' ? styles.connected : data.action === 'Resource does not exist' ? styles.failed : styles.notConnected}`}></i>
{data.action}

           </td>   
               
                </tr>
        ))}
       
        </tbody>
     
     </table>
    </div>
    </>
   )
}
 export default Auditlogs


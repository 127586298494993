import Table from 'react-bootstrap/Table';
import { useEffect, useState } from 'react';
import { makeRationalData, getAthenaQueryResponse } from '../utils/functions'
import request from '../utils/requests';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';

const DataTable = (props) => {

    const [uniqDates, setUniqDates] = useState([])
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        async function fetchData() {
            let query = ''
            if (typeof (props.query) === 'number') {
                query = (await request.get(`/athena/cur/query/${props.query}`)).data.sql_template
            } else {
                query = props.query
            }
            const res = await getAthenaQueryResponse(query)
            if (res.data.message) {
                toast.error(res.data.message);
            } else {
                const data = makeRationalData(res.data.ResultSet.Rows)
                const date = data.map(element => element.date);
                setUniqDates([...new Set(date)]);
                const output = data.reduce((acc, { name, cost, date }) => {
                    if (!acc.some(obj => obj.name === name)) {
                        acc.push({ name });
                    }
                    acc.find(obj => obj.name === name)[date] = cost;
                    return acc;
                }, []);
                setTableData(output)
            }
        }
        fetchData()
    }, [props.query])
    return (
        <>
            {
                tableData.length < 1 &&
                <div className="fixed-loader">
                    <CircularProgress color="inherit" />
                </div>
            }
            {
                tableData.length > 0 &&
                <Table striped bordered hover variant="light" style={{ 'height': '100%', 'overflow': 'auto', 'display': 'block', 'padding': '20px 0' }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Services</th>
                            {
                                uniqDates.map((val, index) => {
                                    return (
                                        <th key={index}>{val}</th>

                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableData.map((val, index) => {
                                return (
                                    < tr key={index}>
                                        <td >{index + 1}</td>
                                        <td >{val.name}</td>

                                        {
                                            uniqDates.map((key, i) => {
                                                return (
                                                    <td key={i}>{val[key] ? val[key] : '-'}</td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </Table>
            }
        </>
    )
}

export default DataTable;

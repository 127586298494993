// import Devops from "../../assets/devops1.avif"
// // import Finops from "../../assets/finops3.png"
// import Observability from "../../assets/Observability1.png"
// import Services from "../../assets/service.webp"

import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const cardData= [
    {
        image:AllInclusiveIcon,
        title:"DevOps Service Partner",
        description:"Our cloud-agnostic DevOps experts reduce your MTTR (Mean time to resolve) to under 20 minutes, enhancing efficiency.",
    },
    {
        image:AccountBalanceWalletIcon,
        title:"Cloud FinOps Services",
        description:"We provide visibility into cloud spend, analyze gaps, and implement solutions to reduce costs and improve your margins.",
    },
    {
        image:RemoveRedEyeIcon,
        title:"24x7 Observability Services",
        description:"We enhance observability, declutter alerts, reduce fatigue, and minimize downtime with RCA to boost sales and operations.",
    },
    {
        image:MiscellaneousServicesIcon,
        title:"VAPT Services",
        description:"We conduct VAPT on your APIs and cloud infrastructure, identify data leaks, and provide actionable reports and remediation.",
    }
]

export default cardData

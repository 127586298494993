import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import request from "../utils/requests";
import { useParams } from "react-router-dom";

const ApexHeatMapChart = () => {
  const { id, type } = useParams();
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    request
      .get(`ec2_cron_jobs/details/?id=${id}`)
      .then((res) => {
        const transformedData = {};

        res?.data?.forEach((entry) => {
          const day = entry.Day;
          const hour = entry.Hour;
          const percentage = parseFloat(entry.Sum_of_Percentages);

          if (!transformedData[day]) {
            transformedData[day] = {
              name: day,
              data: []
            };
          }

          transformedData[day].data.push({
            x: hour,
            y: percentage
          });
        });

        setGraphData(Object.values(transformedData));
      });
  }, [id]);
 

  const options = {
    chart: {
      type: 'heatmap',
      toolbar: {
        show: true
      }
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        distributed: true,
        colorScale: {
          ranges: [
            {
              
              from: 0,
              to: 15,
              color: '#e1e5fd',
              name: 'Low'
            },
            {
              from: 15,
              to: 25,
              color: '#b9c2fb',
              name: 'Medium'
            },
            {
              from: 25,
              to: 40,
              color: '#a5b0f9',
              name: 'High'
            },
            {
              from: 40,
              to: 100,
              color: '#081882',
              name: 'Very High'
            }
          ]
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 4,
      colors: ['#fff']
    },
    title: {
      text: 'Utilization usage',
      align: 'center',
      style: {
        color: '#fff',
        fontFamily: 'Arial, sans-serif'
      }
    },
    xaxis: {
      type: 'category',
      labels: {
        style: {
          colors: '#fff',
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#fff',
        },
        // formatter: function (value) {
        //   return value + "%";
        // }
      }
    },
    tooltip: {
        y: {
          formatter: function (val) {
            return val + "%"; // Show percentage in the tooltip
          }
        }
      },
    
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      labels: {
        colors: '#fff',
      }
    }
  };

  

  return (
    <div className="pie-chart line-chart heatmap-chart">
      <ReactApexChart options={options} series={graphData} type="heatmap" height={400} width={1150} />
    </div>
  );
};

export default ApexHeatMapChart;

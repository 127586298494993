import React, { useState, useEffect } from "react";
import styles from "./Summarytable.module.scss";
import request from "../utils/requests";
import moment from "moment";

const SummaryDashboardTable = () => {
  const [tableData, setTableData] = useState(null);
  const today = moment().format("YYYY-MM-DD");
  const threeMonthsAgo = moment().subtract(2, "months").format("YYYY-MM-DD");
  const [granularity, setGranularity] = useState("MONTHLY");
  const [dimension, setDimension] = useState("SERVICE");

  const formatNumberWithCommas = (number) => {
    const [integerPart, decimalPart] = number.split(".");
    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);
    const formattedIntegerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + (otherDigits ? "," : "") + lastThreeDigits;
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await request.get(
          `/ce/cost_breakdown_by_filter?start_date=${threeMonthsAgo}&end_date=${today}&granularity=${granularity}&dimension=${dimension}`
        );
        const data = (response.data);
        setTableData(data);
        console.log(data, "data");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  // const getTopServices = (monthData) => {
  //   // Remove 'total' property and sort services by cost in descending order
  //   const services = Object.entries(monthData)
  //     .filter(([key]) => key !== "total")
  //     .sort(([, costA], [, costB]) => parseFloat(costB) - parseFloat(costA));

  //   // Return top 1 services with formatted cost (including commas)
  //   return services.slice(0, 1).map(([service, cost]) => {
  //     const formattedCost = formatNumberWithCommas(parseFloat(cost).toFixed(0));
  //     return `${service}: $${formattedCost}`;
  //   });
  // };

  const getTopServices = (monthData) => {
    // Remove 'total' property and sort services by cost in descending order
    const services = Object.entries(monthData)
      .filter(([key]) => key !== "total")
      .sort(([, costA], [, costB]) => parseFloat(costB) - parseFloat(costA));

    // Return the top 1 service and its cost
    const [service, cost] = services[0] || [];
    return {
      service: service,
      cost: formatNumberWithCommas(parseFloat(cost).toFixed(0))
    };
  };
  const getMonthName = (monthNumber) => {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[monthNumber - 1] || "";
  };
  return (
    <div className={styles.top}>
      <p className={styles.content}>Last 3 months spend</p>
      <table className={styles.table}>
        <thead className={styles.heading}>
          <tr>
            <th>Months</th>
            <th> Monthly Cost</th>
            {/* <th>Top Services : Cost </th> */}
            <th>Top Service</th>
            <th>Top Service Cost</th>
          </tr>
        </thead>
{/* 
           <tbody className={styles.body}>
      {tableData && Object.entries(tableData).map(([date, monthData]) => {
        // Extracting month from the date
        const month = getMonthName(new Date(date).getMonth() + 1);
        return (
          <tr key={date}>
            <td><p>{month}</p></td>
            <td><p>$ {formatNumberWithCommas((monthData.total).toFixed(0))}</p></td>
            <td>
             
                {getTopServices(monthData).map((service) => (
                  <p key={service}>{service}</p>
                ))}
       
            </td>
        
          </tr>
        );
      })}
    </tbody> */}

<tbody className={styles.body}>
          {tableData && Object.entries(tableData).map(([date, monthData]) => {
            const month = getMonthName(new Date(date).getMonth() + 1);
            const { service, cost } = getTopServices(monthData);
            return (
              <tr key={date}>
                <td><p>{month}</p></td>
                <td><p>$ {formatNumberWithCommas((monthData.total).toFixed(0))}</p></td>
                <td><p className={styles.services}>{service}</p></td>
                <td><p>$ {cost}</p></td>
              </tr>
            );
          })}
        </tbody>

      </table>
    </div>
  );
};

export default SummaryDashboardTable;


import React, {useEffect,useState}from 'react';
import { toast } from 'react-toastify';
import request from "../../utils/requests";
import style from "../Security/Security.module.scss"



const RegionTable = () => {
   const [details, setDetails] = useState([]);
   const[region,setRegion]=useState([]);
   const[selectedRegion,setSelectedRegion]=useState("")
   const fetchData = async () => {
     try {
        const res = await request.get(`/prowler/findings_by_region/`);
         setDetails(res.data);
         setRegion(res.data);
   
     } catch (error) {
         console.error('Error fetching accountid:', error);
         toast.error("Error fetching data");

     }
   
   };
   
    const handleRegion= (e) =>{  
     setSelectedRegion(e.target.value)
    }

    const filteredDetails = details.filter(data => selectedRegion === '' || data?.region === selectedRegion);


   useEffect(() => {
   
     fetchData();
   }, []);
  return (
    <div className={style.SeverityTable}>
           <p className={style.tableHeading}>Finding By Region</p>

    <table cellPadding="10px" className={style.table}>
      <thead className={style.heading}>

       {/* <th>Region</th> */}
       <select 
       value={region}
       onChange={handleRegion}
       className={style.Input}
       >
       <option>Select a region </option>
       {region.map((regions,index) => (
        <option className={style.option} key={index} value={regions?.region}>
            {regions?.region}
          </option> ))}
       </select>
         <th>Critical</th>
            <th>High</th>
            <th>Medium</th>
            <th>Low</th>
         <th> Fail Count</th>
        
      </thead>
         
           <tbody className={style.tbody}>
           {filteredDetails.map((data, index) => (
          <tr
            key={index}>
            <td>{data.region}</td>
            <td className={style.critical}>{data.severity.critical}</td>
              <td className={style.high}>{data.severity.high}</td>
              <td className={style.mediums}>{data.severity.medium}</td>
              <td className={style.low}>{data.severity.low}</td>
            <td >{data.fail_count}</td>
         </tr>
        ))}
      </tbody>
    </table>
 
  </div>
  )
}

export default RegionTable;
import React ,{useEffect, useState} from 'react'
import { Modal, Box, Button, TextField, FormControl, Select, MenuItem, RadioGroup, FormControlLabel, Radio, Checkbox } from '@mui/material';
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import utc from 'dayjs/plugin/utc';
import DeleteIcon from '@mui/icons-material/Delete';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { showLoading, hideLoading } from "../store/actions/loader";
import { useDispatch } from "react-redux";
import request from "../utils/requests";
import { toast } from "react-toastify";
import styles from "../pages/Recommendation.module.scss"
import { DatePicker } from '@mui/lab';


const Scheduleinstancedrawer = () => {
    const { id, type } = useParams();
    const [showFirstHandler,setShowFirstHandler] =useState(true);
    const [currentInstanceId, setCurrentInstanceId]=useState("")
    const [openModal, setOpenModal] = useState(false);
    const [recurrence, setRecurrence] = useState({
      repeatEvery: 1,
      timeUnit: 'week',
      days: ['M'],
      endOption: 'never',
      endDate: '',
      occurrences: 13,
    });

    const [frequency, setFrequency] = useState(1);
    const [unit, setUnit] = useState('week');
    const [repeatOn, setRepeatOn] = useState(['M']);
    const [endOption, setEndOption] = useState('never');
    const [endDate, setEndDate] = useState(null);
    const [occurrences, setOccurrences] = useState(1);
  
    const handleDayToggle = (day) => {
      if (repeatOn.includes(day)) {
        setRepeatOn(repeatOn.filter((d) => d !== day));
      } else {
        setRepeatOn([...repeatOn, day]);
      }
    };

    const handleCustomSelect = () => {
        setOpenModal(true);
      };
    
      const handleModalClose = () => {
        setOpenModal(false);
      };
    
      const handleRecurrenceChange = (e) => {
        const { name, value } = e.target;
        setRecurrence((prev) => ({ ...prev, [name]: value }));
      };

    const [open, setOpen] = useState(false);
    const [list, setList] = useState([]);
    const dispatch = useDispatch();


    const handleopenCustom = () => {
        setOpenModal(true);
    };

    const [cronDetails, setCronDetails] = useState({
        ec2_instance_id: "",
        region: "",
        start_time: '',
        action: "",
        frequency: "",
        ec2_cron_job_id:""
      });
    
      // const [cronDetails, setCronDetails] = useState([]);


      const addMoreAction = () => {
        setCronDetails([...cronDetails, {
          ec2_instance_id: cronDetails.ec2_instance_id,
          region: cronDetails.region,
          start_time:'',
          frequency: '',
          action: '',
          ec2_cron_job_id:""
    
        }]);
      };
      const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setCronDetails((values) => ({ ...values, [name]: value }));
      };

     
      
          useEffect(() => {
        
            fetchData();
          }, []);
          const fetchData = async () => {
            try {
             
              const res = await request.get(`/ec2_cron_jobs/list/?instance_id=${id}`);
             const data = Array.isArray(res?.data) && Array.isArray(res?.data[0]) ? res.data[0] : [];
              setCronDetails(data); // Update state with the first array from the response

              console.log('API Data:', data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
          // const fetchData = async () => {
          //   try {
          //     const res = await request.get(`/ec2_cron_jobs/list/?instance_id=${id}`);
          //     const data = Array.isArray(res?.data) && res?.data.length ? res.data : [];
              
          //     if (data.length) {
          //       setCronDetails(data); // Update state with the fetched array
          //     } else {
          //       // If no data is found, ensure at least the ec2_instance_id and region are set
          //       setCronDetails([{
          //         ec2_instance_id: id, // Prefill with the instance_id from params
          //         region: 'us-east-1',  // Use a default region or modify based on actual data
          //         start_time: '',
          //         action: '',
          //         frequency: '',
          //         ec2_cron_job_id: ''
          //       }]);
          //     }
          
          //     console.log('API Data:', data);
          //   } catch (error) {
          //     console.error('Error fetching data:', error);
          //   }
          // };
     

    
    const handleDeleteAction = (index,show=false) => {
        if(show){
         setShowFirstHandler(false);
        } else {
          setCronDetails(prev => prev.filter((_, i) => i !== index));
        }
      };
  
      const handleRemoveAction = (id,show=false) => {
        if(show){
          setShowFirstHandler(false);
         
        }
        if(!id){
          return
        }
          dispatch(showLoading()); 
          request
            .del(`ec2_cron_jobs/delete/${id}/`)
            .then(() => {
              dispatch(hideLoading());
              toast.success("Action removed successfully");
        
              // Update the cronDetailsArray state after deletion
              setCronDetails(prev => prev.filter(cron => cron.ec2_cron_job_id !== id));
                       fetchData(currentInstanceId);

              
            })
            .catch((error) => {
              dispatch(hideLoading());
              toast.error(error.message);
            });
      };
    
    
      const handleActionChange = (index, field, value) => {
        console.log("handleActionChange:", value, dayjs.isDayjs(value)); // Log the value and check if it's a Dayjs object
    
        const updatedArray = [...cronDetails];
        updatedArray[index] = { ...updatedArray[index], [field]: value };
        setCronDetails(updatedArray);
      };
      

      // const handleTimeChange = (newValue, index) => {
      //   const formattedValue = newValue ? newValue.format("HH:mm:ss") : "";
      //   handleActionChange(index, "cron_job", formattedValue);
      // };
    
      // const handleTimePickerChange = (newValue, index) => {
      //   handleTimeChange(newValue, index);
      // };
      const handleTimePickerChange = (newValue, index, field) => {
        if (newValue) {
          handleActionChange(index, field, newValue.format("HH:mm:ss"));  // Format the value as needed
        } else {
          handleActionChange(index, field, '');  // Handle null value
        }
      };

  
      const handleactionChange = (e) => {
        setCronDetails((values) => ({ ...values, ["action"]: e.target.value }));
      };
    
      // const handleFrequencyChange = (e) => {
      //   setCronDetails((values) => ({ ...values, ["frequency"]: e.target.value }));
      // };
      const handleFrequencyChange = (e, index) => {
        const value = e.target.value;
        if (value === "custom") {
          handleopenCustom();
        } else {
          handleActionChange(index, 'frequency', value);
        }
      };
     
      // const handleFrequencyChange = (event, index) => {
      //   const newCronDetails = [...cronDetails];
      //   newCronDetails[index].frequency = event.target.value;
      //   setCronDetails(newCronDetails);
      // };
      
      
      const setAutomation = () => {
      // const filteredArray = cronDetailsArray.filter(cron => cron.action && cron.cron_job && cron.frequency);
    
        dispatch(showLoading());
        request
      // .post("ec2_cron_jobs/create/", [...filteredArray, cronDetails])
    
          .post("ec2_cron_jobs/create/", [...cronDetails])
          .then((response) => {
            dispatch(hideLoading());
            toast.success("Automation created");
            closeDrawer();
    
            setList((prevList) =>
              prevList.map((item) =>
                item.instance_id === cronDetails.ec2_instance_id
                  ?  { ...item, action: cronDetails.action }
                  : item
              )
            );
    
            // setRecommendations((prevRecommendations) =>
            //   prevRecommendations.map((item) =>
            //     item.instance_id === cronDetails.ec2_instance_id
            //       ? { ...item, action: cronDetails.action }
            //       : item
            //   )
            // );
          })
          
          .catch((error) => {
            dispatch(hideLoading());
            toast.error(error.message);
          });
      };
    
    

    function closeDrawer() {
        setCronDetails({
          ec2_instance_id: "",
          region: "",
          start_time:"",
          action: "",
          frequency:"",
          ec2_cron_job_id:""
        });
        setOpen(false);
        setShowFirstHandler(true)
        // onPageChange();
      }

    
  return (
    <div>
 

{/* <ApexHeatMapChart/> */}

<div className={`${styles.scheduletable} ${styles.mainTable}`}>
          <table cellPadding="10px"  className={styles.table}>
            <thead className={styles.heading}>
    
                <td>Instance Id</td>
                <td>Region</td>
                <th>Time</th>
                <td>Frequency</td>
                <td>Action</td>
                <td>Take actions</td>
            </thead>
           
              
               
                 <tbody className={styles.tbody}>
              {cronDetails?.length && cronDetails?.map((data, index) => (
                <tr
                  key={index}>
                  {/* <td>{data.ec2_instance_id}</td>
                  <td>{data.region}</td> */}
                  <td>{data.ec2_instance_id || id}</td> {/* Prefilled instance ID */}
                  <td>{data.region || 'us-east-1'}</td>  {/* Prefilled region, change default if needed */}
  
  <td>
  <div className="timePicker">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        name="start"
        value={data.start_time ? dayjs(data.start_time, "HH:mm:ss") : null}  // Ensure value is a dayjs object or null
        onChange={(newValue) => handleTimePickerChange(newValue, index, 'start')}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </LocalizationProvider>
  </div>
</td>
                  <td><select
    label="Please Select Frequency"
    style={{ color: "#fff", marginBottom: "10px" }}
    className="inputaction"

    // value={data.frequency} 
    // onChange={(e) => handleActionChange(index, 'frequency', e.target.value)}
    // inputProps={{
    //   name: `frequency-${index}`,
    //   id: `frequency-${index}`
    // }}
    value={data.frequency || ''} // Pre-select the value from API
            onChange={(e) => handleFrequencyChange(e, index)}

  >
    <option className="option" value="">
      Select Frequency
    </option>
    <option className="option" value="daily">
      Daily
    </option>
    <option className="option" value="weekends">
      Weekends
    </option>
    <option className="option" value="weekdays">
      Weekdays
    </option>
    <option className="option" value="Monday">
      Monday
    </option>
    <option className="option" value="custom" onClick={()=>{handleopenCustom()}}>
      Custom
    </option> 
    
  </select>
</td>

<td>
<select
    label="Please select the action"
    value={data.action}

    onChange={(e) => handleActionChange(index, 'action', e.target.value)}
    inputProps={{
      name: `action-${index}`,
      id: `action-${index}`
    }}                style={{ color: "#fff" }}
    className="inputaction"
  >
    <option className="option" value="">
      Select Action
    </option>
    <option className="option" value="start">
      Start
    </option>
    <option className="option" value="stop">
      Stop
    </option>
  </select>
</td>
<td> 
     <Button
            variant="contained"
            sx={{
              display: 'flex',
              background: 'transparent',
              color: 'white !important',
              textTransform: 'none',
              marginTop: '10px',
              marginLeft: 'auto',
              borderRadius: '15px',
              border: '1px solid rgba(255, 255, 255, 0.75) !important',
              width: 'auto',

              '&:hover': {
                background: '#007bff',
                color: 'white !important',
                textTransform: 'none',
              },
            }}
            onClick={setAutomation}
            disabled={!data.action || !data.start_time || !data.frequency}

            style={{
              background: data.action && data.start_time && data.frequency ? '#1672EC' : 'rgb(135, 138, 157)',
                color: 'white',
            }}
          >
            Schedule Instance
            {/* {fetchData(id)? 'Save changes' : 'Schedule Instance'} */}

                 </Button>
                </td>
                
                <DeleteIcon
     onClick={() => handleRemoveAction(data.ec2_cron_job_id, true )}
     style={{marginTop:"25px"  , fontSize:"30px"}}
   />
                </tr>
              ))}
            </tbody>
          </table>
          <Button
              variant="contained"
              onClick={addMoreAction}
              sx={{
                display: 'flex',
                background: 'transparent',
                color: 'white !important',
                textTransform: 'none',
                marginTop: '10px',
                marginLeft: 'auto',
                borderRadius: '15px',
                width: '125px',
                border: '1px solid rgba(255, 255, 255, 0.75) !important',

                '&:hover': {
                  background: '#007bff',
                  color: 'white !important',
                  textTransform: 'none',
                },
              }}
              disabled={cronDetails.length >= 3}
            >
              Add more action
            </Button>

        </div>  


    <Modal open={openModal} onClose={handleModalClose}>
        <div className="modal-content">
          <h5>Custom Recurrence</h5>

          {/* Repeat every */}
        <FormControl fullWidth>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
            <TextField
              type="number"
              label="Repeat every"
              value={frequency}
              onChange={(e) => setFrequency(e.target.value)}
              inputProps={{ min: 1 }}
              style={{ width: '40%' }}
            />
            <Select value={unit} onChange={(e) => setUnit(e.target.value)} style={{ width: '55%' }}>
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="year">Year</MenuItem>
            </Select>
          </div>
        </FormControl>
       

          <div>
            {unit === 'week' && (
          <div style={{ marginBottom: '16px' }}>
            <label>Repeat on</label>
            <div style={{ display: 'flex', justifyContent: 'space-between' , color:'blue'}}>
              {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day) => (
                <Checkbox
                  key={day}
                  checked={repeatOn.includes(day)}
                  onChange={() => handleDayToggle(day)}
                  label={day}
                />
              ))}
            </div>
          </div>
        )}          </div>

          <div>
            <div style={{ marginBottom: '16px' }}>
          <label>Ends</label>
          <RadioGroup value={endOption} onChange={(e) => setEndOption(e.target.value)}>
            <FormControlLabel value="never" control={<Radio />} label="Never" />
            <FormControlLabel value="on" control={<Radio />} label="On" />
            {endOption === 'on' && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            )}
            <FormControlLabel value="after" control={<Radio />} label="After" />
            {endOption === 'after' && (
              <TextField
                label="Occurrences"
                type="number"
                value={occurrences}
                onChange={(e) => setOccurrences(e.target.value)}
                inputProps={{ min: 1 }}
                fullWidth
              />
            )}
          </RadioGroup>
        </div>          </div>

          <Button onClick={handleModalClose}>Done</Button>
        </div>
      </Modal>
    </div>
  )

}
export default Scheduleinstancedrawer
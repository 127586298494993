
import React, {useEffect,useState}from 'react';
import { toast } from 'react-toastify';
import request from "../../utils/requests";
import style from "../Security/Security.module.scss"



const Account = () => {
   const [details, setDetails] = useState([]);
   const [selectedAccount, setSelectedAccount] = useState('');
   const [accounts, setAccounts] = useState([]);
 
   const fetchData = async () => {
     try {
         const response = await request.get(`/prowler/failed_findings_by_account/`);
         setDetails(response.data);
         setAccounts(response.data)
   
     } catch (error) {
         console.error('Error fetching accountid:', error);
         toast.error("Error fetching data");

     }
   
   };
   
   useEffect(() => {
   
     fetchData();
   }, []);

   const handleAccount = (event) => {
    setSelectedAccount(event.target.value);
  };

  // Filter details based on selected account ID
 
  const filteredDetails = details.filter(data => selectedAccount === '' || data.account_id === selectedAccount);


  return (
    <div className={style.SeverityTable}>
           <p className={style.tableHeading}>Finding By Account</p>

    <table cellPadding="10px" className={style.table}>
      <thead className={style.heading}>

      {/* <th className={style.serviceName}> Account Id */}
       <select
        className={style.Input}
        value={selectedAccount}
        onChange={handleAccount}
      >
        <option value="">Select account id</option>
        {accounts.map((account, index) => (
          <option className={style.option} key={index} value={account?.account_id}>
            {account?.account_id}
          </option>
        ))}
      </select>
       {/* </th> */}
         <th>Critical</th>
            <th>High</th>
            <th>Medium</th>
            <th>Low</th>
         <th> Fail Count</th>
        
      </thead>
         
           <tbody className={style.tbody}>
          {filteredDetails?.map((data, index) => (
          <tr
            key={index}>
            <td>{data.account_id}</td>
            <td className={style.critical}>{data.severity.critical}</td>
              <td className={style.high}>{data.severity.high}</td>
              <td className={style.mediums}>{data.severity.medium}</td>
              <td className={style.low}>{data.severity.low}</td>
            <td>{data.fail_count}</td>
      </tr>
        ))}
      </tbody>
    </table>
 
  </div>
  )
}

export default Account
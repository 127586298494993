const cardData2= [
    {
        image:"",
        title:"We Deliver",
        description:"We focus on the quality of our delivery, not just making promises. Unlike others, we take proactive actions to ensure results.",
    },
    {
        image:"",
        title:"Ownership",
        description:"Our engineers bring transparency to your DevOps and business processes, ensuring you are always informed and involved.",
    },
    {
        image:"",
        title:"AI Backed Follow up",
        description:"In today's noisy world, we ensure regular follow-ups without creating unnecessary noise, delivering results efficiently.",
    },
]

export default cardData2

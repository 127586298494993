import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import CircularProgress from '@mui/material/CircularProgress';
import request from '../utils/requests';
import moment from 'moment';
import styles from "./Integration/Card.module.scss"


const RegionStackChart = () => {
    const [regionChartData, setRegionChartData] = useState(null);
    const today = moment().format("YYYY-MM-DD");
    const sixMonthsAgo = moment().subtract(6, 'months').format("YYYY-MM-DD");
    const [granularity, setGranularity] = useState('MONTHLY');
    const [dimension, setDimension] = useState('REGION');
    const fetchData = async () => {
        try {
            const response = await request.get(`/ce/cost_breakdown_by_filter?start_date=${sixMonthsAgo}&end_date=${today}&granularity=${granularity}&dimension=${dimension}`);
            const data = response.data;
    
            const categories = Object.keys(data);
            const services = extractServices(data, categories);
            const top5Services = extractTop5Services(data, categories, services);
            const otherServices = calculateOtherServices(data, categories, services, top5Services);
            const series = generateSeries(data, categories, top5Services, otherServices);
    
            setRegionChartData({
                categories: categories.map(date => moment(date).format('MMM')), // Format dates to months
                series,
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    const extractServices = (data, categories) => {
        let services = [];
        categories.forEach(date => {
            services = services.concat(Object.keys(data[date]));
        });
        return [...new Set(services)].filter(service => service !== 'total');
    };
    
    const extractTop5Services = (data, categories, services) => {
        return services
            .map(service => ({
                name: service,
                totalCost: categories.reduce((acc, date) => acc + parseFloat(data[date][service] || 0), 0)
            }))
            .sort((a, b) => b.totalCost - a.totalCost)
            .slice(0, 5);
    };
    
    const calculateOtherServices = (data, categories, services, top5Services) => {
        return services
            .filter(service => !top5Services.some(topService => topService.name === service))
            .map(service => ({
                name: service,
                totalCost: categories.reduce((acc, date) => acc + parseFloat(data[date][service] || 0), 0)
            }));
    };
    
    const generateSeries = (data, categories, top5Services, otherServices) => {
        return [
            ...top5Services.map(service => ({
                name: service.name,
                data: categories.map(date => parseFloat(data[date][service.name] || 0).toFixed(0))
            })),
            {
                name: 'Others',
                data: categories.map(date =>
                    otherServices.reduce((acc, service) => acc + parseFloat(data[date][service.name] || 0), 0).toFixed(0)
                )
            }
        ];
    };
    
    useEffect(() => {
    
        fetchData();
    }, []);

    const options = {
        chart: {
            type: 'bar',
            stacked: false,
        },
        grid: {
            borderColor: '#535A6C', // Set grid border color
            strokeDashArray: 4, // Set stroke dash array for dotted lines
        },
        dataLabels: {
            enabled: false, // This is the option to disable data labels
          },
        xaxis: {
            categories: regionChartData ? regionChartData.categories : [],
            labels: {
                style: {
                    colors: '#fff', // Set X-axis label color to white
                },
            },
        },
        yaxis: {
            title: {
                text: 'Region Cost',
                style: {
                    color: '#fff', // Set title color to white
                },
            },
            labels: {
                style: {
                    colors: '#fff', 
                },
                // formatter: function (val) {
                //     return regionChartData ? val?.toFixed(0) : val; 
                // }
                formatter: function (val) {
                    return val >= 1000 ? (val / 1000)?.toFixed(0) + 'k' : val?.toFixed(0); // Format values to display as '2k', '3k', etc.
                }
            }
        
        },
        legend: {
            position: 'bottom',
            labels: {
                colors: '#fff', // Set legend label color to white
            },
        },
    };

    return (
        <div className='pie-chart'
         >
            <p className={styles.graphHeading}>Last 6 Months Region-wise Costs</p>

            {regionChartData ? (
                <ApexCharts options={options} series={regionChartData.series} type="bar" height={350} />
            ) : (
                <div className="fixed-loader">
                    <CircularProgress color="inherit" />
                </div>
            )}
        </div>
    );
};

export default RegionStackChart;
